export const ROUTE_STATE = "ROUTE_STATE"

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const UPDATE_FIELD = "UPDATE_FIELD"
export const UPDATE_TEMPLATE_TITLE = "UPDATE_TEMPLATE_TITLE"
export const UPDATE_TEMPLATE_FONT = "UPDATE_TEMPLATE_FONT"
export const UPDATE_DISPLAY_RULES = "UPDATE_DISPLAY_RULES"
export const UPDATE_SPECIFIC_FORM = "UPDATE_SPECIFIC_FORM"
export const UPDATE_SAVE_ERRORS = "UPDATE_SAVE_ERRORS"
export const INITIAL_STATE = "INITIAL_STATE"
export const IS_FORM_CHANGED = "IS_FORM_CHANGED"

export const TEMPLATE_TYPE = "TEMPLATE_TYPE";
export const TEMPLATE_TYPE_NUMBER = "TEMPLATE_TYPE_NUMBER";

export const UPDATE_CAMPAIGN_ID = "UPDATE_CAMPAIGN_ID";

// ---- API CONSTANTS -----

//get campaign list
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";
export const GET_CAMPAIGN_LIST_SUCCESS = "GET_CAMPAIGN_LIST_SUCCESS";
export const GET_CAMPAIGN_LIST_FAILURE = "GET_CAMPAIGN_LIST_FAILURE";

//create campaign
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

//get campaign by id
export const GET_CAMPAIGN_VIA_ID = "GET_CAMPAIGN_VIA_ID";
export const GET_CAMPAIGN_VIA_ID_SUCCESS = "GET_CAMPAIGN_VIA_ID_SUCCESS";
export const GET_CAMPAIGN_VIA_ID_FAILURE = "GET_CAMPAIGN_VIA_ID_FAILURE";

//update campaign
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

//delete campaign
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

//get countries
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

//get discount coupon
export const GET_DISCOUNT_COUPONS = "GET_DISCOUNT_COUPONS";
export const GET_DISCOUNT_COUPONS_SUCCESS = "GET_DISCOUNT_COUPONS_SUCCESS";
export const GET_DISCOUNT_COUPONS_FAILURE = "GET_DISCOUNT_COUPONS_FAILURE";

//campaignViaId TO INITIAL STATE
export const CAMPAIGN_STATE_TO_INITIAL = "CAMPAIGN_STATE_TO_INITIAL";

//get Leads list
export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const GET_LEADS_LIST_SUCCESS = "GET_LEADS_LIST_SUCCESS";
export const GET_LEADS_LIST_FAILURE = "GET_LEADS_LIST_FAILURE";

//delete campaign
export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILURE = "DELETE_LEAD_FAILURE";

// Dashboard filter
export const DASHBOARD_FILTER = "DASHBOARD_FILTER";
export const DASHBOARD_FILTER_SUCCESS = "DASHBOARD_FILTER_SUCCESS";
export const DASHBOARD_FILTER_FAILURE = "DASHBOARD_FILTER_FAILURE";


// Date Filtered Data
export const DATE_FILTER_DATA = "DATE_FILTER_DATA";
export const DATE_FILTER_DATA_SUCCESS = "DATE_FILTER_DATA_SUCCESS";
export const DATE_FILTER_DATA_FAILURE = "DATE_FILTER_DATA_FAILURE";

// Date Filtered Data champain details
export const CD_DATE_FILTER_DATA = "CD_DATE_FILTER_DATA";
export const CD_DATE_FILTER_DATA_SUCCESS = "CD_DATE_FILTER_DATA_SUCCESS";
export const CD_DATE_FILTER_DATA_FAILURE = "CD_DATE_FILTER_DATA_FAILURE";