import React, { useState, useEffect } from "react";
import { ElementContainer } from "../../styledComponent/editor";

const Image = ({ element, editorElement }) => {

  return (
    <React.Fragment key={element.id}>
        <ElementContainer
          style={
            element.justifyContent &&
            element.justifyContent !== "" && {
              justifyContent: element.justifyContent,
            }
          }
        >
          <img
            style={Object.assign(
              {},
              element.defaultStyle,
              element.style
            )}
            alt="popup"
            src={element.src}
            width="100%"
          />
        </ElementContainer>
    </React.Fragment>
  );
};

export default Image;
