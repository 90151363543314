import React, { useEffect, useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { ColorPalllateContainer, CrossIcon } from "../styledComponent/step1";
import crossBlack from "../assets/icons/cross_black.svg";
import "../css/colorPicker.css";

const ColorPallete = ({
  updateElementColor,
  keyProp,
  initialColor,
  closeColorView,
}) => {
  const [color, setColor] = useColor("hex", initialColor);

  const updateColor = (color) => {
    if (
      keyProp === "button_hover_color" ||
      keyProp === "promote_page_button_hover_color"
    ) {
      console.log(keyProp);
      updateElementColor(keyProp, { backgroundColor: color.hex });
    } else {
      updateElementColor(keyProp, color.hex);
    }
    setColor(color);
  };

  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  return (
    <ColorPalllateContainer onClick={(e) => onPopupClick(e)}>
      <ColorPicker
        width={200}
        height={200}
        color={color}
        onChange={updateColor}
      />
      <CrossIcon onClick={() => closeColorView(null)}>
        <img src={crossBlack} alt="cross-icon" width="100%" />
      </CrossIcon>
    </ColorPalllateContainer>
  );
};

export default ColorPallete;
