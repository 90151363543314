import styled from "styled-components";

export const TemplateContainer = styled.div`
  display: grid;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `center`};
  align-content: start;
`;

export const EditorTemplate = styled.div`
  display: grid;
  position: relative;
`;
export const StyledCancel = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
`;
export const StyledInput = styled.input`
  border-radius: 4px;
  border: 1px solid #e4e7eb;
  color: #2f394e;
  font-size: 13px;
  padding: 5px 10px;
  min-height: 38px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
`;

export const StyledText = styled.p`
  margin: 0;
`;

export const ElementContainer = styled.div`
  display: grid;
`;

// ----- SECTION -----
export const UpperContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 30px;
  justify-content: space-between;
  position: absolute;
  top: -30px;
  left: 0px;
  color: white;
`;
export const UpperElement = styled.div`
  background-color: #ed5a29;
  padding: 4px 10px;
  border-radius: 4px 4px 0 0;
`;
export const EditorContainer = styled.div`
  position: relative;
  border: 1px solid #ed5a29;
`;

// ----- SUBMIT -----
export const StyledButton = styled.div`
  &:hover {
    background-color: ${({ hover }) =>
      hover && `${hover.backgroundColor} !important`};
  }
`;
export const LinkButton = styled.a`
  &:hover {
    background-color: ${({ hover }) =>
      hover && `${hover.backgroundColor} !important`};
  }
`;
