import React, { useState, useEffect } from "react";
import { StyledInput } from "../../styledComponent/editor";

const Input = ({ element }) => {

  return (
    <React.Fragment key={element.id}>
        <StyledInput
          type={element.inputType}
          placeholder={element.placeholder}
          style={Object.assign({}, element.defaultStyle, element.style)}
        />
    </React.Fragment>
  );
};

export default Input;
