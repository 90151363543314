import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateDisplayRules } from "../../actions/actionTemplate";
import { CommonTitle, CommonText } from "../../styledComponent/common";
import {
  FormContainer,
  FormLeftColumn,
  StaticFieldContainer,
  RoundSelectText,
  RoundSelect,
  ChangableCompContainer,
  Maindiv,
  LabelP,
} from "../../styledComponent/step2";
import Location from "./Location";
import ShowPopupAfter from "./ShowPopupAfter";
import UserBehavior from "./UserBehavior";
import Platform from "./Platform";
import InterestedInCategory from "./InterestedInCategory";
import ShowOnPage from "./ShowOnPage";
import ReferrerUrl from "./ReferrerUrl";

const Step2 = (props) => {
  // const [selectedComponent, setSelectedComponent] = useState("Location");
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const components = {
  //   Location: Location,
  //   ShowPopupAfter: ShowPopupAfter,
  //   UserBehavior: UserBehavior,
  //   Platform: Platform,
  //   InterestedInCategory: InterestedInCategory,
  //   ShowOnPage: ShowOnPage,
  //   ReferrerUrl: ReferrerUrl,
  // };

  // let RenderedFormComponent = components[selectedComponent];

  const onChangeShowUpOption = (value) => {
    props.updateDisplayRules({ data: { show_up_option: value } });
  };

  const showExitIntentPopup = (value) => {
    props.updateDisplayRules({ data: { exit_intent_popup: value } });
  };

  // useEffect(() => {
  //   console.log("displayRules", props.displayRules);
  // }, [props.displayRules]);

  return (
    <Maindiv colum={"100%"}>
      <Maindiv colum={"100%"}>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"40% 58%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          responsive={"100%"}
          responsiveValue={"670px"}
        >
          <LabelP>Show Up Option</LabelP>
          <Maindiv
            colum={"49% 49%"}
            gap={"2%"}
            responsive={"100%"}
            responsiveValue={"670px"}
          >
            <RoundSelectText
              onClick={() => onChangeShowUpOption("EVERY_VISIT")}
            >
              <RoundSelect
                selected={props.displayRules.show_up_option === "EVERY_VISIT"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Every Visit</CommonText>
            </RoundSelectText>
            <RoundSelectText onClick={() => onChangeShowUpOption("EVERY_PAGE")}>
              <RoundSelect
                selected={props.displayRules.show_up_option === "EVERY_PAGE"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Every Page</CommonText>
            </RoundSelectText>
          </Maindiv>
        </Maindiv>
        {/* <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"40% 58%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
          responsive={"100%"}
          responsiveValue={"670px"}
        >
          <LabelP>Exit intent popup</LabelP>
          <Maindiv
            colum={"49% 49%"}
            gap={"2%"}
            responsive={"100%"}
            responsiveValue={"670px"}
          >
            <RoundSelectText onClick={() => showExitIntentPopup("ENABLE")}>
              <RoundSelect
                selected={props.displayRules.exit_intent_popup === "ENABLE"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Enable</CommonText>
            </RoundSelectText>

            <RoundSelectText onClick={() => showExitIntentPopup("DISABLE")}>
              <RoundSelect
                selected={props.displayRules.exit_intent_popup === "DISABLE"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Disable</CommonText>
            </RoundSelectText>
          </Maindiv>
        </Maindiv> */}
        {/* <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Location selectedCounrtry={props.displayRules.selectedCountries} />
        </Maindiv> */}
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Show Popup After</LabelP>
          </Maindiv>
          <ShowPopupAfter />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>User Behavior</LabelP>
          </Maindiv>
          <UserBehavior />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Display On Devices</LabelP>
          </Maindiv>
          <Platform />
        </Maindiv>
      </Maindiv>
    </Maindiv>
  );
};

const mapStateToProps = (state) => ({
  template_type: state.route.template_type,
  template_type_number: state.route.template_type_number,
  campaign_id: state.route.campaign_id,
  displayRules: state.template.displayRules,
  campaignViaId: state.campaign.campaignViaId,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
