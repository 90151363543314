import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import { CommonText } from "../../styledComponent/common";
import {
  ChangableCompContainer,
  Maindiv,
  CardPlatform,
} from "../../styledComponent/step2";

const Platform = (props) => {
  const [patform, setPlayform] = useState("");
  useEffect(() => {
    // console.log("Platform ", props.displayRules.campaign_platforms);
    setPlayform(props.displayRules.campaign_platforms);
  }, []);

  const onChangePlatform = (value) => {
    props.updateDisplayRules({ data: { campaign_platforms: value } });
    setPlayform(value);
    let errors = { ...props.saveErrors };
    delete errors.campaign_platforms;
    props.updateSaveErrors({ data: errors });
  };

  return (
    <ChangableCompContainer>
      {/* <ToggleButton onClick={() => onChangeToggle()} active={toggleActive}>
        <ToggleButtonRound active={toggleActive} />
      </ToggleButton> */}
      <CommonText>
        Show the campaign only to users who use Desktop, Mobile or Both Devices
      </CommonText>
      <Maindiv
        colum={"30% 30% 30%"}
        gap={"5%"}
        style={{}}
        margin={"10px 0 0 0"}
        responsive={"100%"}
        responsiveValue={"670px"}>
      
        <CardPlatform
          selected={patform === "web"}
          onClick={() => onChangePlatform("web")}
        >
          <label>Desktop</label>
        </CardPlatform>
        <CardPlatform
          selected={patform === "mobile"}
          onClick={() => onChangePlatform("mobile")}
        >
          <label>Mobile</label>
        </CardPlatform>
        <CardPlatform
          selected={patform === "both"}
          onClick={() => onChangePlatform("both")}
        >
          <label>Both Device</label>
        </CardPlatform>
      </Maindiv>
    </ChangableCompContainer>
  );
};
const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Platform);
