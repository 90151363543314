import { useState, useEffect } from "react";
import {
  CommonText,
  CommonColorButton,
  CommonWhButton,
} from "../styledComponent/common";
import {
  ModalButtonSection,
  PreviewPopup,
} from "../styledComponent/campaignList";
import { PreviewContainer, CrossIcon } from "../styledComponent/baseListVIew";
import ButtonTextLoader from "../utils/Loader/ButtonTextLoader";
import crossBlack from "../assets/icons/cross_black.svg";

const WarningModal = ({ text, description, buttonText, toggle, onAccept }) => {
  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  return (
    <PreviewContainer onClick={toggle}>
      <PreviewPopup maxWidth="550px" >
        <div
          style={{ padding: `35px 30px 15px` }}
          onClick={(e) => onPopupClick(e)}
        >
          <div>
            <CommonText fontSize="16px" name="true">
              {text}
            </CommonText>
            <CommonText margin="30px 0 0">
              {description}
            </CommonText>
          </div>
          <ModalButtonSection>
            <CommonWhButton padding="11px 35px" onClick={toggle}>
              Stay
            </CommonWhButton>
            <CommonColorButton
              padding="11px 40px"
              bgColor="#4D7CFF"
              onClick={onAccept}
            >
              {buttonText}
            </CommonColorButton>
          </ModalButtonSection>
        </div>
        <CrossIcon>
          <img src={crossBlack} alt="cross-icon" width="100%" />
        </CrossIcon>
      </PreviewPopup>
    </PreviewContainer>
  );
};

export default WarningModal;
