export const getDaysFunc = (secs) => {
    const days = Math.floor(secs / (3600*24) );
    return days;
}

export const getHoursFunc = (secs) => {
    const hours = `${Math.floor(secs / 3600)}`;
    const getHours = `0${hours % 24}`.slice(-2);
    return getHours;
}

export const getMinutesFunc = (secs) => {
    const minutes = `${Math.floor(secs / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    return getMinutes; 
}

export const getSecondsFunc = (secs) => {
    const getSeconds = `0${secs % 60}`.slice(-2);
    return getSeconds; 
}