import {
  UPDATE_TEMPLATE,
  UPDATE_FIELD,
  UPDATE_TEMPLATE_TITLE,
  UPDATE_TEMPLATE_FONT,
  UPDATE_DISPLAY_RULES,
  UPDATE_SPECIFIC_FORM,
  UPDATE_SAVE_ERRORS,
  INITIAL_STATE,
  IS_FORM_CHANGED,
} from "../constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleUpdateTemplate = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    selectedTemplate: data,
  };
};

export const handleUpdateField = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    selectedFields: data,
  };
};

export const handleUpdateTemplateTitle = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    templateTitle: data,
  };
};

export const handleUpdateTemplateFont = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    templateFont: data,
  };
};

export const handleUpdateDisplayRules = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    displayRules: {
      ...state.displayRules,
      ...data,
    },
  };
};

export const handleUpdateSpecificForm = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    specificForm: data,
  };
};

export const handleIsFormChange = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isFormChanged: data,
  };
};

export const handleUpdateSaveErrors = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    saveErrors: data,
  };
};

export const handleUpdateToInitialState = () => {
  return { ...initialState };
};

// Action Handlers
const ACTION_HANDLERS = {
  [UPDATE_TEMPLATE]: handleUpdateTemplate,
  [UPDATE_FIELD]: handleUpdateField,
  [UPDATE_TEMPLATE_TITLE]: handleUpdateTemplateTitle,
  [UPDATE_TEMPLATE_FONT]: handleUpdateTemplateFont,
  [UPDATE_DISPLAY_RULES]: handleUpdateDisplayRules,
  [UPDATE_SPECIFIC_FORM]: handleUpdateSpecificForm,
  [IS_FORM_CHANGED]: handleIsFormChange,
  [UPDATE_SAVE_ERRORS]: handleUpdateSaveErrors,
  [INITIAL_STATE]: handleUpdateToInitialState,
};

// default initial state
const initialState = {
  selectedTemplate: null,
  selectedFields: null,
  templateTitle: "",
  templateFont: "",
  displayRules: {
    campaign_platforms: "both",
    show_up_option: "EVERY_VISIT",
    exit_intent_popup: "ENABLE",
    show_location: false,
    country_ids: [],
    show_popup_after: false,
    popup_after_seconds: null,
    show_use_behaviour: false,
    show_to_returning_visitor: "NEW_VISITOR",
    show_to_logged_in_user: "LOGGED_IN",
    show_when_user_adds_prod_to_cart: false,
    show_popup_when_scroll: false,
    // show_campaign_platform: false,
    // interested_in_category: false,
    // interested_category_url: null,
    // show_on_page: false,
    // on_page_url: null,
    // exclude_page: false,
    // exclude_url: null,
    // show_referrer_url: true,
    // referrer_url: null,
  },
  isFormChanged: false,
  specificForm: null,
  saveErrors: {},
};

export default function templateReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
