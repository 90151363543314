import React, { useState } from "react";
import {
  CardContainer,
  PricingCard,
  CommonAnchor,
} from "../src/styledComponent/pricingPlan";
import {
  CommonTitle,
  CommonText,
  CommonColorButton,
} from "./styledComponent/common";
import ComponentCircleLoader from "./utils/Loader/ComponentCircleLoader";
import axios from "axios";

const PricingPlan = ({ shop }) => {
  const [loading, setLoading] = useState(false);
  const pricingPlanCall = async (type) => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/${type}/?shop=${shop}`.toString();
      setLoading(true);
      const response = await axios.get(URL);
      if (response && response.status && response.status === 200) {
        window.open(response.data.url, "_top");
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  return (
    <div>
      {loading ? (
        <ComponentCircleLoader />
      ) : (
        <CardContainer>
          <CommonTitle style={{ gridColumn: `1 / span 2` }}>
            Select Plan to Proceed
          </CommonTitle>
          <PricingCard>
            <CommonTitle>Monthly</CommonTitle>
            <div>
              <CommonTitle color="#4D7CFF">$3.95</CommonTitle>
              <CommonText>per month</CommonText>
            </div>
            <CommonColorButton
              onClick={() => pricingPlanCall("monthly_starter")}
            >
              Choose Plan
            </CommonColorButton>
          </PricingCard>

          <PricingCard>
            <CommonTitle>Yearly</CommonTitle>
            <div>
              <CommonTitle color="#4D7CFF">$30.00</CommonTitle>
              <CommonText>per year</CommonText>
            </div>
            <CommonColorButton
              onClick={() => pricingPlanCall("yearly_starter")}
            >
              Choose Plan
            </CommonColorButton>
          </PricingCard>
        </CardContainer>
      )}
    </div>
  );
};

export default PricingPlan;
