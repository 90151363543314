import { ROUTE_STATE, TEMPLATE_TYPE, TEMPLATE_TYPE_NUMBER, UPDATE_CAMPAIGN_ID } from "../constants";

export function updateRouteState(element) {
  return {
    type: ROUTE_STATE,
    payload: element,
  };
}

export function updateTemplateType(element) {
  return {
    type: TEMPLATE_TYPE,
    payload: element,
  };
}

export function updateTemplateTypeNumber(element) {
  return {
    type: TEMPLATE_TYPE_NUMBER,
    payload: element,
  };
}

export function updateCampaignId(value) {
  return {
    type: UPDATE_CAMPAIGN_ID,
    payload: value,
  };
}  