import { FirstWordRound } from "../../styledComponent/common";

const FirstRoundLetter = ({ text, backColor, size, fontSize, gap }) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#F9D059" },
    { color: "#F9828B" },
    { color: "#73C0FF" },
  ];
  let selectedIndex = 0;
  if (backColor) {
    if (backColor < 4) {
      selectedIndex = backColor;
    } else {
      selectedIndex = backColor % 4;
    }
  }
  return (
    <FirstWordRound
      backColor={colorList[selectedIndex].color}
      size={size}
      fontSize={fontSize}
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

export default FirstRoundLetter;

// const url =
//   "https://87dc-113-11-40-248.in.ngrok.io/?hmac=b7e9c6171c542b9a62ff99c97a9d89a36bb9bb92e16ca64749bb8d0c6935cd03&host=YW9ucy1nYWxsZXJ5Lm15c2hvcGlmeS5jb20vYWRtaW4&shop=aons-gallery.myshopify.com&timestamp=1665466943";
// const urlAdminapps = "https://aons-gallery.myshopify.com/admin/apps/9f32e968de15d520df611c3fbc30e19c/?hmac=b7e9c6171c542b9a62ff99c97a9d89a36bb9bb92e16ca64749bb8d0c6935cd03&host=YW9ucy1nYWxsZXJ5Lm15c2hvcGlmeS5jb20vYWRtaW4&shop=aons-gallery.myshopify.com&timestamp=1665466943"