import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateDisplayRules } from "../../actions/actionTemplate";
import { getCountriesList } from "../../actions/actionCountries";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { CountryCard, Maindiv, LabelP,CrossIcon,Wrapper } from "../../styledComponent/step2";
import crossBlack from "../../assets/icons/cross_black.svg";
const Location = (props) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const onChangeLocation = () => {
  //   props.updateDisplayRules({
  //     data: { show_location: !props.displayRules.show_location },
  //   });
  // };

  // const onChangeLocationCountry = (e) => {
  //   props.updateDisplayRules({ data: { country_ids: e.value } });
  // };

  useEffect(() => {
    props.getCountriesList();
  }, []);
  useEffect(() => {
    let updateArray = [];
    selectedOptions.forEach((country) => {
      if (country.value !== "*") {
        updateArray.push(country.value);
      }
    });

    props.updateDisplayRules({
      data: { country_ids: updateArray },
    });
  }, [selectedOptions]);

  useEffect(() => {
    if (props.countriesList && props.countriesList.length > 0) {
      let optionsArray = [];
      props.countriesList.forEach((country) => {
        optionsArray.push({ value: country.id, label: country.name });
      });
      setCountryOptions(optionsArray);
      let selectedArray = [];
      if (props.displayRules.location_country_id) {
        if (
          props.displayRules.location_country_id.length >= optionsArray.length
        ) {
          setSelectedOptions([{ label: "All", value: "*" }, ...optionsArray]);
        } else {
          props.displayRules.location_country_id.forEach((country) => {
            selectedArray.push({ value: country.id, label: country.name });
          });
          setSelectedOptions(selectedArray);
        }
      }
    }
  }, [props.countriesList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All selected`;
    } else {
      if (value.length > 0) {
        return `${value.length} Countries`;
      }
      return `${placeholderButtonLabel}`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      //alert("1");
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: `1px solid #4D7CFF`,
      borderTop: `none`,
      borderRadius: `6px`,
      width: `200px`,
      // padding: 20,
      borderTopRightRadius: `0`,
      borderTopLeftRadius: `0`,
    }),
    option: (provided, state) => ({
      ...provided,
      width: `195px`,
      //   padding: 20,
      minWidth: `100px`,
      overflow: `hidden`,
      fontSize: `10px`,
    }),
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: `200px`,
      minWidth: `100px`,
      margin: `0 0 0 0`,
      border: `1px solid #4D7CFF`,
      borderBottom: `none`,
      borderRadius: `6px`,
      borderBottomRightRadius: `0`,
      borderBottomLeftRadius: `0`,
      boxShadow: `none`,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      border: `none`,
    }),
    dropdownButton: (provided, state) => ({
      ...provided,
      width: `200px`,
      border: state.isOpen && "1px solid #4D7CFF",
      borderRadius: `6px`,
    }),
  };

  const crossClisk = (val) =>{
   let tempArray = selectedOptions.filter(op => op.value !== val && op.value !== "*");
   setSelectedOptions(tempArray);
  }
  return (
    <>
      <Maindiv colum={"59% 39%"} gap={"2%"}           responsive={"100%"}
          responsiveValue={"670px"}>
        <Maindiv>
          <LabelP>Location</LabelP>
          <LabelP style={{marginTop:"20px"}}>Show campaign to visitors only from</LabelP>
          {selectedOptions && selectedOptions.length >0 && (
              <LabelP style={{marginTop:"20px"}}>Selected country list</LabelP>
          )}
        </Maindiv>

        <div style={{ minWidth: `200px` ,marginTop:"35px"}}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...countryOptions]}
            placeholderButtonLabel="Select Country"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions}
            onChange={onChange}
            setState={setSelectedOptions}
            styles={customStyles}
          />
        </div>
      </Maindiv>
      {selectedOptions && countryOptions.length >  selectedOptions.length && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "auto",
            flexWrap: "wrap",
            maxWidth:"600px"
          }}
        >
          {selectedOptions.map((country) => (
            <>
              {country.value !== "*" && (
                <Wrapper>
                <CountryCard>{country.label} 
                </CountryCard>            
                <CrossIcon onClick={() => crossClisk(country.value)}>
                <img src={crossBlack} alt="cross-icon" width="100%" />
              </CrossIcon>
              </Wrapper>
              )}
            </>
          ))}
        </div>
      )}
      {selectedOptions && countryOptions.length <=  selectedOptions.length &&(
      <LabelP style={{color:"#4D7CFF" ,marginTop:"20px"}}>All countries are selected.</LabelP>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.countries.countriesList,
  displayRules: state.template.displayRules,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  getCountriesList: (params) => dispatch(getCountriesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
