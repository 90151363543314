import styled from "styled-components";
import { keyframes } from "styled-components";

export const CampaignListContainer = styled.div`
  display: grid;
  padding: 20px 30px;
  grid-gap: 30px;
  @media only screen and (max-width: 1040px) {
    padding: 20px;
  }
`;

export const CampaignRow = styled.div`
  display: grid;
  grid-template-columns: 20px 150px minmax(200px, 330px) 55px 150px 100px 100px 130px 100px;
  grid-gap: 20px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  @media only screen and (max-width: 1229px) {
    grid-template-columns: 20px 130px minmax(180px, 330px) 55px 150px 90px 90px 120px 80px;
    grid-gap: 12px;
  }
  @media only screen and (max-width: 1040px) {
    grid-template-columns: 20px 130px minmax(180px, 330px) 45px 140px 80px 90px 120px 80px;
    grid-gap: 12px;
  }
`;
export const UpperSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  justify-content: space-between;
  padding: 40px 30px 20px;
  align-items: center;
  @media (max-width: ${(props) =>
      props.responsiveValue ? `760px` : `540px`}) {
    grid-template-columns: ${(props) => props.responsive && props.responsive};
    grid-gap: 20px;
    // padding: 10px 20px;
  }
`;
export const UpperButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.showDelete ? `repeat(3, auto)` : `auto auto`};
  grid-gap: ${props => props.gap ? props.gap : `15px`};
  @media (max-width: ${(props) =>
      props.responsiveValue && `760px`}) {
    //grid-template-columns: ${(props) => props.responsive && props.responsive};
    //grid-gap: 20px;
    // padding: 10px 20px;
    max-width: 300px;
  }
  @media (max-width: ${(props) =>
      props.device && `760px`}) {
     grid-template-columns: 100%;
    //grid-gap: 20px;

  }
`;
export const DeviceCard = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  padding: 15px;
  border-radius: 6px;
  background-color: #f0f4ff;
  color: rgba(31, 41, 57, 0.7);
  font-size: 13px;
`;
export const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px 20px;
  padding: 15px 0px 0;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : `end`)};
  margin-top: ${(props) => (!props.details ? `25px` : `35px`)};
`;
export const ContainerAnim = keyframes`
  from {
    opacity: 0.7;
    transform: translate(0px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -20px);
  }
`;
export const PreviewPopup = styled.div`
  position: relative;
  display: grid;
  background-color: #FFF;
  border: 1px solid #C2CCE1;
  border-radius: 10px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : `450px`)};
  max-width: ${ props => props.maxWidth && props.maxWidth};
  margin-top: 20px;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  animation: ${ContainerAnim} 0.8s ease forwards;
`;
export const CommonTextList = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `auto 0`)};
  color: ${(props) => (props.name ? `#1F2939` : `rgba(31, 41, 57, 0.7)`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `14px`)};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.cursor && props.cursor};
  @media (max-width:915px) {
    //grid-template-columns: ${(props) => props.responsive && props.responsive};
    //grid-gap: 20px;
    // padding: 10px 20px;
    font-size: 10px;
  }
  @media (max-width:590px) {
    //grid-template-columns: ${(props) => props.responsive && props.responsive};
    //grid-gap: 20px;
    // padding: 10px 20px;
    font-size: 8px;
  }
`;
export const DraftSpan = styled.span`
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #BFC9D4;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -5px;
  left: 5px;
`
export const DashboardCampaignRow = styled.div`
  display: grid;
  grid-template-columns: 100px minmax(200px, 330px) 55px 150px 100px 100px 130px;
  grid-gap: 20px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  @media only screen and (max-width: 1229px) {
    grid-template-columns: 100px minmax(180px, 330px) 55px 150px 90px 90px 120px;
    grid-gap: 12px;
  }
  @media only screen and (max-width: 1040px) {
    grid-template-columns: 100px minmax(180px, 330px) 45px 140px 80px 90px 120px;
    grid-gap: 12px;
  }
`;
export const DraftEditContainer = styled.div`
  grid-column: 9 / span 1;
`
