import styled from "styled-components";

export const NavbarMainContainer = styled.div`
    display: grid;
    grid-template-columns: 180px auto;
    justify-content: space-evenly;
    background-color: #4D7CFF;
    color: white;
    padding: 10px 20px;
    align-items: center;
    grid-gap: 17%;
    z-index: 100;
    @media (max-width: 600px) {
    grid-template-columns: 100%;
    padding: 10px 20px;
    padding-bottom: 30px;
  }
`

export const NavbarInnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    grid-gap: 20px;
`
export const NavbarItem = styled.div`
    padding: 5px 10px 7px;
    border: ${props => props.isActive ? `1px solid white` : `1px solid #4D7CFF`};
    border-radius: 6px;
    color: ${props => props.isActive ? `white` : `rgba(255, 255, 255, 0.8)`};
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    &:hover {
    color: white;
  }
`
export const NavbarTitle = styled.div`
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
`
export const UpgradePlanBar = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 3em;
  padding: 10px 30px;
  border-bottom: 1px solid #4D7CFF;
`