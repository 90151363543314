import React from "react";

import {
  CommonText,
  ArrowBox,
  BackLinkSection,
} from "../../styledComponent/common";

import Back from "../../assets/icons/back.svg";

const BackButtonComponent = (props) => (
  <BackLinkSection onClick={props.onClick}>
    <ArrowBox>
      <img src={Back} alt="back" width="100%" />
    </ArrowBox>
    <CommonText
      name
      fontSize={props.fontSize ? props.fontSize : `24px`}
      fontWeight={props.fontWeight ? props.fontWeight : `500`}
    >
      {props.subTitle}
    </CommonText>
  </BackLinkSection>
);

export default BackButtonComponent;
