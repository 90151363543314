import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { getLeadsList, deleteLead } from "../actions/actionCampaign";
import {
  CommonTitle,
  CommonText,
  InputField,
  CommonColorButton,
  CommonWhButton,
  IconTextButton,
  CommonCheckBox,
  CommonItemGrid,
  PaginationContainer,
  CountBox,
  EmptyBox,
  PaginationBlock,
  NoDataContainer,
} from "../styledComponent/common";
import {
  CampaignListContainer,
  UpperSection,
  UpperButtonContainer,
  DeviceCard,
} from "../styledComponent/campaignList";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import FirstRoundLetter from "./commonComponents/FirstRoundLetter";
import DeletePopup from "./DeletePopup";
import { ToggleButton, ToggleButtonRound } from "../styledComponent/step2";
import { CampaignRow } from "../styledComponent/leads";
import noDataArtWork from "../assets/img/artwork.svg";
import Filter from "../assets/icons/filter.svg";
import tick from "../assets/icons/tick_2.svg";
import moment from "moment";

const CSVLinkMod = styled(CSVLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Leads = (props) => {
  const [selectItems, setSelectedItems] = useState([]);
  const [allSelect, setAllSelect] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pagesArray, setPagesArray] = useState([]);
  const numberPerPage = 50;

  useEffect(() => {
    props.getLeadsList();
  }, []);

  useEffect(() => {
    // console.log(props.leadsList);
    if (
      props.leadsList &&
      props.leadsList.results &&
      props.leadsList.results.length > 0
    ) {
      let tempArray = [];
      props.leadsList.results.forEach((element) => {
        tempArray.push({ id: "" });
      });
      setSelectedItems(tempArray);
    }
    if (props.leadsList && props.leadsList.count) {
      let pages = Math.ceil(props.leadsList.count / numberPerPage);
      let tempArr = [];
      for (let i = 1; i <= pages; i++) {
        tempArr.push(i);
      }
      setPagesArray(tempArr);
    }
  }, [props.leadsList]);

  useEffect(() => {
    if (
      props.leadsList &&
      props.leadsList.results &&
      selectItems.length > 0 &&
      selectItems.length === props.leadsList.results.length
    ) {
      let haveAnyId = false;
      selectItems.forEach((element) => {
        if (element.id !== "") {
          haveAnyId = true;
        }
      });
      setShowDelete(haveAnyId);
    }
  }, [selectItems]);

  useEffect(() => {
    if (props.deletedLeadCallback && isDeleted) {
      popupToggle();
      if (selectedPage === 1) {
        props.getLeadsList();
      } else {
        props.getLeadsList(selectedPage);
      }
      setAllSelect(false);
    }
  }, [props.deletedLeadCallback]);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const ToogleSelectAllCampaign = () => {
    setAllSelect(!allSelect);
    let tempArray = [...selectItems];
    if (allSelect) {
      tempArray.forEach((item) => {
        item.id = "";
      });
      setSelectedItems(tempArray);
    } else {
      props.leadsList.results.forEach((item, index) => {
        tempArray[index].id = item.id;
      });
      setSelectedItems(tempArray);
    }
  };

  const toggleSelectCampaign = (id, index) => {
    let tempArray = [...selectItems];
    tempArray[index].id === ""
      ? (tempArray[index].id = id)
      : (tempArray[index].id = "");
    setSelectedItems(tempArray);
  };

  const onDeleteLead = () => {
    selectItems.forEach((item, index) => {
      if (item && item.id !== "") {
        props.deleteLead({
          lead_id: item.id,
        });
        setIsDeleted(true);
        setShowDelete(false);
      }
    });
  };

  const getCSVData = () => {
    let csvData = [[`Name`, `Email`, `Campaign`, `Date & Time`, `Coupon Code`]];
    if (
      props.leadsList &&
      props.leadsList.results &&
      props.leadsList.results.length > 0
    ) {
      props.leadsList.results.forEach((lead, index) => {
        const newArr = [
          lead.name,
          lead.email,
          lead.campaign_name,
          moment(lead.created_at).format("MMMM D, YYYY h:m a"),
          lead.coupon_code,
        ];
        csvData.push(newArr);
      });
    }

    return csvData;
  };

  const onChangePage = (value) => {
    setSelectedPage(value);
    if (value === 1) {
      props.getLeadsList();
    } else {
      props.getLeadsList(value);
    }
  };

  return (
    <div>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <UpperSection>
            {selectItems &&
              props.leadsList &&
              props.leadsList.results &&
              selectItems.length === props.leadsList.results.length &&
              popupOpen && (
                <DeletePopup
                  type="leads"
                  isOpen={popupOpen}
                  toggle={popupToggle}
                  isDeleteLoading={props.isDeleteLoading}
                  onDelete={onDeleteLead}
                />
              )}
            <CommonTitle>All Your Leads</CommonTitle>
            {props.leadsList &&
              props.leadsList.results &&
              props.leadsList.results.length > 0 && (
                <UpperButtonContainer showDelete={showDelete}>
                  {showDelete && (
                    <CommonColorButton
                      bgColor="#FE5969"
                      onClick={() => popupToggle()}
                    >
                      Delete
                    </CommonColorButton>
                  )}
                  {/* <IconTextButton>
                <img src={Filter} alt="filter icon" /> <div>Filter</div>
              </IconTextButton> */}
                  <CSVLinkMod data={getCSVData()} filename={`leads.csv`}>
                    <CommonColorButton>Export</CommonColorButton>
                  </CSVLinkMod>
                </UpperButtonContainer>
              )}
          </UpperSection>
          <CampaignListContainer>
            {selectItems &&
              selectItems.length > 0 &&
              props.leadsList &&
              props.leadsList.results &&
              props.leadsList.results.length > 0 && (
                <CampaignRow>
                  <CommonCheckBox
                    selected={allSelect}
                    onClick={() => ToogleSelectAllCampaign()}
                  >
                    <img src={tick} alt="tick" />
                  </CommonCheckBox>
                  <CommonText fontWeight="500">Email</CommonText>
                  <div />
                  <CommonText fontWeight="500">Campaign</CommonText>
                  <CommonText fontWeight="500">Date & Time</CommonText>
                  <CommonText fontWeight="500">Coupon Code</CommonText>
                </CampaignRow>
              )}
            {props.leadsList &&
            props.leadsList.results &&
            props.leadsList.results.length > 0 &&
            selectItems.length === props.leadsList.results.length ? (
              props.leadsList.results.map((item, index) => (
                <CampaignRow key={index}>
                  <CommonCheckBox
                    selected={selectItems[index].id === item.id}
                    onClick={() => toggleSelectCampaign(item.id, index)}
                  >
                    <img src={tick} alt="tick" />
                  </CommonCheckBox>
                  {item.email && (
                    <FirstRoundLetter
                      text={item.email}
                      backColor={index}
                      size="42px"
                      fontSize="20px"
                    />
                  )}
                  <CommonItemGrid style={{ gridGap: `2px` }}>
                    <CommonText fontSize="13px" style={{ color: `#4D7CFF` }}>
                      {item.email}
                    </CommonText>
                  </CommonItemGrid>
                  <CommonText breakWord> {item.campaign_name} </CommonText>
                  <CommonText>
                    {moment(item.created_at).format("MMMM D, YYYY h:m a")}
                  </CommonText>
                  <CommonText> {item.coupon_code} </CommonText>
                </CampaignRow>
              ))
            ) : (
              <NoDataContainer>
                <img
                  src={noDataArtWork}
                  alt="no campaigns"
                  width="100%"
                  style={{ maxWidth: `300px` }}
                />
                <CommonText>
                  No leads so far! You will get your leads list here once we get
                  any for you.
                </CommonText>
              </NoDataContainer>
            )}
            {pagesArray.length > 1 && (
              <PaginationContainer>
                <CommonText fontSize="13px" fontWeight="500">
                  View leads per page
                </CommonText>
                <CountBox perPage={true}> {numberPerPage} </CountBox>
                <PaginationBlock>
                  {props.leadsList && props.leadsList.previous === null ? (
                    <EmptyBox />
                  ) : (
                    <CountBox
                      arrow={true}
                      onClick={() => onChangePage(selectedPage - 1)}
                    >
                      {"<"}
                    </CountBox>
                  )}
                  {pagesArray.map((element) => (
                    <CountBox
                      selected={selectedPage === element}
                      key={element}
                      onClick={() => onChangePage(element)}
                    >
                      {element}
                    </CountBox>
                  ))}
                  {props.leadsList && props.leadsList.next === null ? (
                    <EmptyBox />
                  ) : (
                    <CountBox
                      arrow={true}
                      onClick={() => onChangePage(selectedPage + 1)}
                    >
                      {">"}
                    </CountBox>
                  )}
                </PaginationBlock>
              </PaginationContainer>
            )}
          </CampaignListContainer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaign.isLoading,
  isDeleteLoading: state.campaign.isDeleteLoading,
  leadsList: state.campaign.leadsList,
  deletedLeadCallback: state.campaign.deletedLeadCallback,
});

const mapDispatchToProps = (dispatch) => ({
  getLeadsList: (params) => dispatch(getLeadsList(params)),
  deleteLead: (params) => dispatch(deleteLead(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
