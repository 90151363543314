import subscription2 from "../assets/template_images/template-1.svg";
import subscription4 from "../assets/template_images/subscribe_4.png";
import subscription1 from "../assets/template_images/subscribe_1.png";
import subscription3 from "../assets/template_images/subscribe_3.png";
import subscription5 from "../assets/template_images/subscribe_5.png";

import welcomemessages3 from "../assets/template_images/template-2.svg";
import welcomemessages1 from "../assets/template_images/wm_smart_topbar_1.png";
import welcomemessages2 from "../assets/template_images/wm_smart_topbar_2.png";
import welcomemessages4 from "../assets/template_images/wm_smart_topbar_4.png";
import welcomemessages5 from "../assets/template_images/wm_smart_topbar_5.png";

import promocodes4 from "../assets/template_images/template-3.svg";
import promocodes5 from "../assets/template_images/promo_codes_5.png";
import promocodes1 from "../assets/template_images/promo_codes_1.png";
import promocodes2 from "../assets/template_images/promo_codes_2.png";
import promocodes3 from "../assets/template_images/promo_codes_3.png";

import socialboosts1 from "../assets/template_images/social_boost_1.png";
import socialdiscounts5 from "../assets/template_images/template-5.svg"

import * as constant from "../constants/templateConstants";

const allImages = [
    {
        image: subscription1,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_1,
        isPremium: false,
    },
    {
        image: subscription2,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_2,
        isPremium: false,
    },
    {
        image: subscription3,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_3,
        isPremium: false,
    },
    {
        image: subscription4,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_4,
        isPremium: false,
    },
    {
        image: subscription5,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_5,
        isPremium: false,
    },
    {
        image: welcomemessages1,
        template_type: constant.WELCOME_MESSAGES,
        template_type_number: constant.WELCOME_MESSAGES_1,
        isPremium: false,
    },
    {
        image: welcomemessages2,
        template_type: constant.WELCOME_MESSAGES,
        template_type_number: constant.WELCOME_MESSAGES_2,
        isPremium: false,
    },
    {
        image: welcomemessages3,
        template_type: constant.WELCOME_MESSAGES,
        template_type_number: constant.WELCOME_MESSAGES_3,
        isPremium: false,
    },
    {
        image: welcomemessages4,
        template_type: constant.WELCOME_MESSAGES,
        template_type_number: constant.WELCOME_MESSAGES_4,
        isPremium: false,
    },
    {
        image: welcomemessages5,
        template_type: constant.WELCOME_MESSAGES,
        template_type_number: constant.WELCOME_MESSAGES_5,
        isPremium: false,
    },
    {
        image: promocodes1,
        template_type: constant.PROMO_CODES,
        template_type_number: constant.PROMO_CODES_1,
        isPremium: false,
    },
    {
        image: promocodes2,
        template_type: constant.PROMO_CODES,
        template_type_number: constant.PROMO_CODES_2,
        isPremium: false,
    },
    {
        image: promocodes3,
        template_type: constant.PROMO_CODES,
        template_type_number: constant.PROMO_CODES_3,
        isPremium: false,
    },
    {
        image: promocodes4,
        template_type: constant.PROMO_CODES,
        template_type_number: constant.PROMO_CODES_4,
        isPremium: false,
    },
    {
        image: promocodes5,
        template_type: constant.PROMO_CODES,
        template_type_number: constant.PROMO_CODES_5,
        isPremium: false,
    },
    // {
    //     image: socialdiscounts5,
    //     template_type: constant.SOCIAL_DISCOUNTS,
    //     template_type_number: constant.SOCIAL_DISCOUNTS_5,
    //     isPremium: false,
    // },
    // {
    //     image: socialboosts1,
    //     template_type: constant.SOCIAL_BOOSTS,
    //     template_type_number: constant.SOCIAL_BOOSTS_1,
    //     isPremium: false,
    // },
]

export default allImages;