//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../utils/axiosApi";

//Constants
import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_DISCOUNT_COUPONS,
  GET_DISCOUNT_COUPONS_SUCCESS,
  GET_DISCOUNT_COUPONS_FAILURE,
} from "../constants";

export function* getCountriesList() {
  try {
    const url = `country/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_COUNTRIES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_COUNTRIES_FAILURE,
      payload: err,
    });
  }
}

export function* getDiscountCoupons() {
  try {
    const url = `discount_data/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_DISCOUNT_COUPONS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_DISCOUNT_COUPONS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //Get campaign list
    takeLatest(GET_COUNTRIES, getCountriesList),
    takeLatest(GET_DISCOUNT_COUPONS, getDiscountCoupons)
  ]);
}
