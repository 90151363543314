import {
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_LIST_FAILURE,
  GET_LEADS_LIST,
  GET_LEADS_LIST_SUCCESS,
  GET_LEADS_LIST_FAILURE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_VIA_ID,
  GET_CAMPAIGN_VIA_ID_SUCCESS,
  GET_CAMPAIGN_VIA_ID_FAILURE,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  CAMPAIGN_STATE_TO_INITIAL,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILURE,
  DASHBOARD_FILTER,
  DASHBOARD_FILTER_SUCCESS,
  DASHBOARD_FILTER_FAILURE,
  DATE_FILTER_DATA,
  DATE_FILTER_DATA_SUCCESS,
  DATE_FILTER_DATA_FAILURE,
  CD_DATE_FILTER_DATA,
  CD_DATE_FILTER_DATA_SUCCESS,
  CD_DATE_FILTER_DATA_FAILURE,
} from "../constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleGetCampaignListRequest = (state, action) => {
  
  return {
    ...state,
    isLoading: true,
    campaignList: [],
  };
};

export const handleGetCampaignListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    campaignList: data,
  };
};

export const handleGetCampaignListRequestFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};

export const handleGetLeadsList = (state, action) => {
  return {
    ...state,
    isLoading: true,
    leadsList: [],
  };
};

export const handleGetLeadsListSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    leadsList: data,
  };
};

export const handleGetLeadsListFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};


//CREATE Campaign
export const handleCreateCampaign = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleCreateCampaignSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    // createNotification("success", "campaign created successfully", 2000);
    return {
      ...state,
      isLoading: false,
      createdCampaign: { ...data.data },
    };
  }
  return {
    ...state,
    isLoading: false,
    createdCampaign: null,
  };
};

export const handleCreateCampaignFailure = (state, action) => {
  if (action.payload.data === undefined) {
    // createNotification("error", "Something went wrong", 2000);
    return {
      ...state,
      isLoading: false,
      createdCampaign: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isLoading: false,
    createdCampaign: null,
  };
};

//GET campaign via id
export const handleGetCampaignViaId = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleGetCampaignViaIdSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    campaignViaId: data,
  };
};

export const handleGetCampaignViaIdFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};

//UPDATE Campaign
export const handleUpdateCampaign = (state, action) => {
  return {
    ...state,
    isLoading: true,
    callback: false,
  };
};
export const handleUpdateCampaignSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    // createNotification("success", "Invoice updated successfully", 2000);
    return {
      ...state,
      isLoading: false,
      callback: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const handleUpdateCampaignFailure = (state, action) => {
  if (action.payload.data === undefined) {
    // createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//DELETE CAMPAIGN
export const handleDeleteCampaign = (state, action) => {
  return {
    ...state,
    isDeleteLoading: true,
    deletedCampaignCallback: false,
  };
};
export const handleDeleteCampaignSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    // createNotification("success", "Invoice deleted successfully", 2000);
    return {
      ...state,
      isDeleteLoading: false,
      deletedCampaignCallback: true,
    };
  }
  return {
    ...state,
    isDeleteLoading: false,
    deletedCampaignCallback: false,
  };
};
export const handleDeleteCampaignFailure = (state, action) => {
  // createNotification("error", "Something went wrong!", 2000);
  return {
    ...state,
    isDeleteLoading: false,
    deletedCampaignCallback: false,
  };
};

//DELETE LEAD
export const handleDeleteLead = (state, action) => {
  return {
    ...state,
    isDeleteLoading: true,
    deletedLeadCallback: false,
  };
};
export const handleDeleteLeadSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    // createNotification("success", "Invoice deleted successfully", 2000);
    return {
      ...state,
      isDeleteLoading: false,
      deletedLeadCallback: true,
    };
  }
  return {
    ...state,
    isDeleteLoading: false,
    deletedLeadCallback: false,
  };
};
export const handleDeleteLeadFailure = (state, action) => {
  // createNotification("error", "Something went wrong!", 2000);
  return {
    ...state,
    isDeleteLoading: false,
    deletedLeadCallback: false,
  };
};

//GET dashboard filter
export const handleDashboardFilter = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleDashboardFilterSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    dashboardFilterInfo: data,
  };
};

export const handleDashboardFilterFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};

// campaignViaId TO INITIAL STATE
export const handleCampaignStateToInitial = (state, action) => {
  return {
    ...state,
    campaignViaId: null,
  };
};

export const handleGetDateFilterData = (state, action) => {
  
  return {
    ...state,
    isLoading: true,
    filteredData: null,
  };
};

export const handleGetDateFilterDataSuccess = (state, action) => {
  
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    filteredData: data,
  };
};

export const handleGetDateFilterDataFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};

export const handleCDGetDateFilterData = (state, action) => {
  
  return {
    ...state,
    isLoading: true,
    filteredData: null,
  };
};

export const handleCDGetDateFilterDataSuccess = (state, action) => {
  
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    filteredData: data,
  };
};

export const handleCDGetDateFilterDataFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};
// Action Handlers
const ACTION_HANDLERS = {
  
  [GET_CAMPAIGN_LIST]: handleGetCampaignListRequest,
  [GET_CAMPAIGN_LIST_SUCCESS]: handleGetCampaignListRequestSuccess,
  [GET_CAMPAIGN_LIST_FAILURE]: handleGetCampaignListRequestFailure,
  [GET_LEADS_LIST]: handleGetLeadsList,
  [GET_LEADS_LIST_SUCCESS]: handleGetLeadsListSuccess,
  [GET_LEADS_LIST_FAILURE]: handleGetLeadsListFailure,
  [CREATE_CAMPAIGN]: handleCreateCampaign,
  [CREATE_CAMPAIGN_SUCCESS]: handleCreateCampaignSuccess,
  [CREATE_CAMPAIGN_FAILURE]: handleCreateCampaignFailure,
  [GET_CAMPAIGN_VIA_ID]: handleGetCampaignViaId,
  [GET_CAMPAIGN_VIA_ID_SUCCESS]: handleGetCampaignViaIdSuccess,
  [GET_CAMPAIGN_VIA_ID_FAILURE]: handleGetCampaignViaIdFailure,
  [UPDATE_CAMPAIGN]: handleUpdateCampaign,
  [UPDATE_CAMPAIGN_SUCCESS]: handleUpdateCampaignSuccess,
  [UPDATE_CAMPAIGN_FAILURE]: handleUpdateCampaignFailure,
  [DELETE_CAMPAIGN]: handleDeleteCampaign,
  [DELETE_CAMPAIGN_SUCCESS]: handleDeleteCampaignSuccess,
  [DELETE_CAMPAIGN_FAILURE]: handleDeleteCampaignFailure,

  [DELETE_LEAD]: handleDeleteLead,
  [DELETE_LEAD_SUCCESS]: handleDeleteLeadSuccess,
  [DELETE_LEAD_FAILURE]: handleDeleteLeadFailure,

  [DASHBOARD_FILTER]: handleDashboardFilter,
  [DASHBOARD_FILTER_SUCCESS]: handleDashboardFilterSuccess,
  [DASHBOARD_FILTER_FAILURE]: handleDashboardFilterFailure, 

  [DATE_FILTER_DATA]: handleGetDateFilterData,
  [DATE_FILTER_DATA_SUCCESS]: handleGetDateFilterDataSuccess,
  [DATE_FILTER_DATA_FAILURE]: handleGetDateFilterDataFailure,
  
  [CD_DATE_FILTER_DATA]: handleCDGetDateFilterData,
  [CD_DATE_FILTER_DATA_SUCCESS]: handleCDGetDateFilterDataSuccess,
  [CD_DATE_FILTER_DATA_FAILURE]: handleCDGetDateFilterDataFailure,

  [CAMPAIGN_STATE_TO_INITIAL]: handleCampaignStateToInitial,


};

// default initial state
const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  campaignList: [],
  leadsList: [],
  createdCampaign: null,
  campaignViaId: null,
  callback: false,
  deletedCampaignCallback: false,
  deletedLeadCallback: false,
  dashboardFilterInfo: null,
  filteredData: null,     
};

export default function subscriptionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
