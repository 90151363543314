import styled from "styled-components";

export const CommonTitle = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `0`)};
  color: ${(props) => (props.color ? props.color : `#1F2939`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `21px`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `500`)};
`;
export const CommonText = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `auto 0`)};
  color: ${(props) => (props.name ? `#1F2939` : `rgba(31, 41, 57, 0.7)`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `14px`)};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  word-wrap: ${props => props.breakWord && `break-word`};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.cursor && props.cursor};
`;
export const InputField = styled.input`
  border-radius: 6px;
  min-width: 32px;
  border: ${(props) =>
    props.error ? `1px solid #fc5768` : `1px solid #BFCFE0`};
  background-color: ${(props) => props.error ? `rgba(252, 87, 104, 0.05)`: `#FCFDFE`};
  color: #1F2939;
  font-size: 13px;
  padding: 5px 10px;
  min-height: 43px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
`;
export const ArrowBox = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 7px;
  background-color: #f3f4f4;
  width: ${(props) => (props.size ? props.size : `50px`)};
  height: ${(props) => (props.size ? props.size : `50px`)};
  border: 1px solid #f3f4f4;
  padding: 6px;
`;

export const BackLinkSection = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-column-gap: 20px;
  justify-content: start;
  align-content: center;
  cursor: pointer;
  margin-right: auto;
`;

export const CommonColorButton = styled.div`
  color: white;
  font-size: ${(props) => (props.size ? props.size : `14px`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  border-radius: ${(props) => (props.radius ? props.radius : `6px`)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : `#4D7CFF`)};
  padding: ${(props) => (props.padding ? props.padding : `10px 25px`)};
  cursor: ${(props) => (props.cursor ? props.cursor : `pointer`)};
  align-self: center;
  white-space: nowrap;
  text-align: center;
  &:hover {
    background: ${({ bgColor }) => (bgColor ? bgColor : `#3C6DF7`)};
  }
  max-width:${(props) => (props.maxwidth && props.maxwidth)};
  @media (max-width:590px) {
    
    font-size:${(props) => (props.listEditPadding && `8px`)};
    padding:${(props) => (props.listEditPadding && `10px`)};
  }
`;

export const CommonWhButton = styled.div`
  color: ${(props) => (props.color ? props.color : `#2F394E`)};
  font-size: ${(props) => (props.size ? props.size : `14px`)};
  border-radius: ${(props) => (props.radius ? props.radius : `6px`)};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : `1px solid #C2CCE1`};
  background-color: white;
  padding: ${(props) => (props.padding ? props.padding : `10px 25px`)};
  cursor: ${(props) => (props.cursorAuto ? `auto` : `pointer`)};
  align-self: center;
  white-space: nowrap;
  text-align: center;
  /* &:hover {
    border-color: #c2cce1;
  } */
  max-width:${(props) => (props.maxwidth && props.maxwidth)};
`;
export const FormAlert = styled.div`
  font-size: 13px;
  color: #f15762;
  margin-top: ${props => props.noGap ? `0` : `10px`};
`;
export const IconTextButton = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-content: center;
  align-items: center;
  padding: 0 30px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  color: #1f2939;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  border: ${(props) => (props.bgColor ? props.bgColor : `1px solid #DCE0E9`)};
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 12px 25px;
  }
`;
export const IconTextButtonGradient = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 5px 17px;
  background: ${(props) =>
    props.gradient
      ? props.gradient
      : "linear-gradient(223deg, #FFC41A 0%, #FF9113 100%)"};
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 12px 25px;
  }
`;
export const CommonCheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 2px;
  border: ${(props) =>
    props.selected ? `1px solid #6667FF` : `1px solid #BFCFE0`};
  background-color: ${(props) => (props.selected ? `#6667FF` : `#FFF`)};
`;
export const CommonItemGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.columns ? props.columns : `auto`)};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `start`};
  gap: ${(props) => (props.gap ? props.gap : `10px`)};
  padding: ${(props) => props.padding && props.padding};
  align-content: ${(props) => props.alignContent && props.alignContent};
  @media (max-width: 1000px) {
    grid-template-columns: ${(props) =>
    props.justifyContent && `100%`};
    
  }
`;
export const FirstWordRound = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  width: ${(props) => (props.size ? props.size : `34px`)};
  height: ${(props) => (props.size ? props.size : `34px`)};
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.backColor};
  font-size: ${(props) => props.fontSize && props.fontSize};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
`;
export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-top: 30px;
`;
export const CountBox = styled.div`
  width: 45px;
  height: 45px;
  border: ${(props) =>
    props.perPage
      ? `1px solid #BFCFE0`
      : props.selected
      ? `1px solid #4D7CFF`
      : `1px solid #FFF`};
  border-radius: 6px;
  line-height: 45px;
  text-align: center;
  color: ${(props) =>
    props.selected
      ? `#FFF`
      : props.arrow
      ? `#BFCFE0`
      : `rgba(31, 41, 57, 0.7)`};
  background-color: ${(props) => (props.selected ? `#4D7CFF` : `#FFF`)};
  font-size: ${(props) => (props.arrow ? `18px` : `14px`)};
  cursor: ${(props) => !props.perPage && `pointer`};
  &:hover {
    border-color: ${(props) => !props.perPage && !props.selected && `#4D7CFF`};
    color: ${(props) => !props.perPage && !props.selected && `#4D7CFF`};
  }
`;
export const PaginationBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 0 30px;
`;
export const EmptyBox = styled.div`
  width: 45px;
  height: 45px;
`;
export const InputFieldNum = styled.input`
  border-radius: 6px;
  width: 70px;
  border: ${(props) =>
    props.error ? `1px solid #fc5768` : `1px solid #BFCFE0`};
  background-color: ${(props) => props.error ? `rgba(252, 87, 104, 0.05)`: `#FCFDFE`};
  color: #1F2939;
  font-size: 13px;
  padding: 15px 15px;
  margin:0px 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
`;
export const NoDataContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  justify-items: center;
  gap: 20px;
  padding: 30px;
`