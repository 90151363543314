import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import {
  CommonTitle,
  CommonText,
  InputFieldNum,
  FormAlert,
} from "../../styledComponent/common";
import {
  RoundSelectText,
  RoundSelect,
  CheckBox,
  ToggleButton,
  ToggleButtonRound,
  ChangableCompContainer,
  InputTextContainer,
  Maindiv,
} from "../../styledComponent/step2";
import tick from "../../assets/icons/tick_2.svg";

const UserBehavior = (props) => {
  const onChangeShowUserBehavior = () => {
    props.updateDisplayRules({
      data: { show_use_behaviour: !props.displayRules.show_use_behaviour },
    });
  };

  const onChangeVisitor = (value) => {
    props.updateDisplayRules({ data: { show_to_returning_visitor: value } });
  };

  const onChangeUser = (value) => {
    props.updateDisplayRules({ data: { show_to_logged_in_user: value } });
  };

  const onChangeWhenUserAddsProduct = () => {
    props.updateDisplayRules({
      data: {
        show_when_user_adds_prod_to_cart:
          !props.displayRules.show_when_user_adds_prod_to_cart,
      },
    });
  };

  const onChangePopupWhenScroll = () => {
    props.updateDisplayRules({
      data: {
        show_popup_when_scroll: !props.displayRules.show_popup_when_scroll,
      },
    });
  };

  const onChangeScrollPercentage = (value) => {
    props.updateDisplayRules({ data: { scroll_percentage: value } });

    let errors = { ...props.saveErrors };
    delete errors.scroll_percentage;
    props.updateSaveErrors({ data: errors });
  };

  return (
    <ChangableCompContainer>
      {/* <ToggleButton
        onClick={() => onChangeShowUserBehavior()}
        active={props.displayRules.show_use_behaviour}
      >
        <ToggleButtonRound active={props.displayRules.show_use_behaviour} />
      </ToggleButton>  */}
      {/* <CommonText margin={"0px 0 5px 0"}>
        Show only to the new or returning visitors
      </CommonText>
      <Maindiv
        colum={"49% 49%"}
        gap={"2%"}
        responsive={"100%"}
        responsiveValue={"670px"}
      >
        <RoundSelectText onClick={() => onChangeVisitor("NEW_VISITOR")}>
          <RoundSelect
            selected={
              props.displayRules.show_to_returning_visitor === "NEW_VISITOR"
            }
          >
            <div></div>
          </RoundSelect>
          <CommonText>New visitor</CommonText>
        </RoundSelectText>

        <RoundSelectText onClick={() => onChangeVisitor("RETURNING_VISITOR")}>
          <RoundSelect
            selected={
              props.displayRules.show_to_returning_visitor ===
              "RETURNING_VISITOR"
            }
          >
            <div></div>
          </RoundSelect>
          <CommonText>Returning visitor</CommonText>
        </RoundSelectText>
      </Maindiv> */}
      {/* <CommonText margin={"20px 0 5px 0"}>
        Show only to the logged in OR not logged in users
      </CommonText>
      <Maindiv
        colum={"49% 49%"}
        gap={"2%"}
        responsive={"100%"}
        responsiveValue={"670px"}
      >
        <RoundSelectText onClick={() => onChangeUser("LOGGED_IN")}>
          <RoundSelect
            selected={props.displayRules.show_to_logged_in_user === "LOGGED_IN"}
          >
            <div></div>
          </RoundSelect>
          <CommonText>Logged in user</CommonText>
        </RoundSelectText>

        <RoundSelectText onClick={() => onChangeUser("NOT_LOGGED_IN")}>
          <RoundSelect
            selected={
              props.displayRules.show_to_logged_in_user === "NOT_LOGGED_IN"
            }
          >
            <div></div>
          </RoundSelect>
          <CommonText>Not logged in user</CommonText>
        </RoundSelectText>
      </Maindiv> */}
      {/* <RoundSelectText
        margin={"20px 0 0 0"}
        onClick={() => onChangeWhenUserAddsProduct()}
      >
        <CheckBox
          selected={props.displayRules.show_when_user_adds_prod_to_cart}
        >
          <img src={tick} alt="tick" />
        </CheckBox>
        <CommonText> Show when users adds product to cart</CommonText>
      </RoundSelectText> */}
      <RoundSelectText margin={"20px 0 0 0"} onClick={() => onChangePopupWhenScroll()}>
        <CheckBox selected={props.displayRules.show_popup_when_scroll}>
          <img src={tick} alt="tick" />
        </CheckBox>
        <CommonText>Show popup when user scroll the page</CommonText>
      </RoundSelectText>

      {props.displayRules.show_popup_when_scroll && (
        <>
          <CommonText fontSize="12px" margin="15px 0 0 0">
            Define how many % of a page the user needs to scroll before popup
            appears
          </CommonText>
          <InputTextContainer>
            <CommonText>
              <InputFieldNum
                type="number"
                min="0"
                value={props.displayRules.scroll_percentage}
                error={props.saveErrors && props.saveErrors.scroll_percentage}
                onChange={(e) => onChangeScrollPercentage(e.target.value)}
              />
              %
            </CommonText>
            {props.saveErrors && props.saveErrors.scroll_percentage && (
              <FormAlert>{props.saveErrors.scroll_percentage}</FormAlert>
            )}
          </InputTextContainer>
        </>
      )}
    </ChangableCompContainer>
  );
};

const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBehavior);
