import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateRouteState } from "../../../actions/actionRoute";
import { BASE_LIST_VIEW } from "../../../constants/componentName";
import Step2 from "../../step2/Step2";

const Template1 = (props) => {
  const changeRoute = (route) => {
    props.handleUpdateRouteState({
      link: route,
    });
  };

  return (
    <div>
      <p>this is template 1</p>
      <div style={{ display: `flex`, flexFLow: `row wrap` }}>
        <div
          onClick={() => changeRoute(BASE_LIST_VIEW)}
          style={{ padding: `10px 30px`, border: `1px solid #eee` }}
        >
          go to base list
        </div>
      </div>
      <Step2 />
    </div>
  );
};

const mapStateToProps = (state) => ({
  route: state.route,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Template1);