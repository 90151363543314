import React, { useState, useEffect } from "react";
import { StyledCancel } from "../../styledComponent/editor";
import crossBlack from "../../assets/icons/cross_black.svg"

const Cancel = ({ element }) => {

  return (
    <React.Fragment key={element.id}>
            <StyledCancel>
              <img src={crossBlack} alt="cross-black" />
            </StyledCancel>
    </React.Fragment>
  );
};

export default Cancel;
