import styled from "styled-components";

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 200px) 3fr;
  grid-gap: 25px;
  justify-content: start;
  padding: 20px 0;
`;

export const FormLeftColumn = styled.div`
  display: grid;
  grid-gap: 25px;
  align-content: start;
`;
export const StaticFieldContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 25px 30px;
  justify-content: start;
`;

export const RoundSelectText = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
  cursor: pointer;
  margin-right: auto;
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  margin: ${(props) => (props.margin ? props.margin : `0`)};
`;
export const RoundSelect = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${(props) =>
    props.selected ? `1px solid #6667FF` : `1px solid #686868`};

  padding: 2px;

  & div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => (props.selected ? `#6667FF` : `#FFF`)};
  }
`;
export const ToggleButton = styled.div`
  display: grid;
  align-items: center;
  width: 30px;
  height: 18px;
  background-color: ${(props) => (props.active ? `#2FC662` : `#C2CCE1`)};
  padding: 0 4px;
  border-radius: 10px;
  cursor: pointer;
`;
export const ToggleButtonRound = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-left: ${(props) => props.active && `auto`};
`;
export const ChangableCompContainer = styled.div`
  padding: 15px 0;
  display: grid;
  grid-template-columns: 100%;
  justify-content: start;
  gap: 10px;
`;
export const CheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 3px;
  border: ${(props) =>
    props.selected ? `1px solid #6667FF` : `1px solid #BFCFE0`};
  background-color: ${(props) => (props.selected ? `#6667FF` : `#FFF`)};
`;
export const CardPlatform = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  min-height: 50px;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
  border: ${(props) =>
    props.selected ? `1px solid #6667FF` : `1px solid #BFCFE0`};
  background-color: ${(props) => (props.selected ? `#6667FF` : `#FFF`)};
  & label {
    color: ${(props) => props.selected && `#FFFF`};
    opacity: ${(props) => !props.selected && `0.5`};
    cursor: inherit;
  }
`;

export const InputTextContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-gap: 20px;
`;

export const Maindiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.colum && props.colum};
  grid-gap: ${(props) => props.gap && props.gap};
  min-height: ${(props) => props.minH && props.minH};
  max-height: ${(props) => props.maxH && props.maxH};
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
  border: ${(props) => props.border && props.border};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  @media (max-width: ${(props) =>
      props.responsiveValue ? `700px` : `540px`}) {
    grid-template-columns: ${(props) => props.responsive && props.responsive};
    grid-gap: 20px;
    // padding: 10px 20px;
  }
`;
export const LabelP = styled.label`
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #1f2939;
  opacity: 0.7;
`;

export const CountryCard = styled.div`
  background-color: #4d7cff;
  text-align: center;
  margin-top: 10px;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    //padding: 10px 10px;
  }
`;
export const CrossIcon = styled.div`
  position: relative;
  /* top: ${(props) =>
    props.position === "start"
      ? `initial`
      : props.position === "end"
      ? "-50px"
      : `-37px`};
  right: ${(props) => (props.position ? `20px` : `-45px`)};
  bottom: ${(props) => props.position === "start" && `-50px`};   */
  right: 10px;
  width: 20px;
  height: 20px;
  top: 3px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #878787;
  cursor: pointer;
  display: none;
`;
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100% 10%;
  margin-right: 20px;
  /* & ${CountryCard}:hover + ${CrossIcon} {
        display: block;
    } */
  &:hover ${CrossIcon} {
    display: block;
  }
  &:hover ${CountryCard} {
    padding: 10px 10px;
  }
`;
