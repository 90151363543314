import React, { useState, useEffect } from "react";
import { section, cancel } from "../../constants/elementConstant";
import Section from "../elements/section";
import Cancel from "../elements/cancel";
import {
  TemplateContainer,
  EditorTemplate,
} from "../../styledComponent/editor";

const ViewTemplate = (props) => {

  return (
    <TemplateContainer
      justifyContent={props.justifyContent && props.justifyContent}
    >
      {props.template !== null && (
        <EditorTemplate
          style={Object.assign(
            {},
            { ...props.template.defaultStyle },
            { ...props.template.style }
          )}
        >
          {props.template.blocks.map((element, index) => {
            if (element.type === section) {
              return <Section element={element} />;
            } else if (element.type === cancel) {
              return <Cancel element={element} />;
            }
          })}
        </EditorTemplate>
      )}
    </TemplateContainer>
  );
};

export default ViewTemplate;
