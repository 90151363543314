import { combineReducers } from "redux";

import template from "./templateReducer";
import route from "./routeReducer";
import campaign from "./campaignReducer";
import countries from "./countriesReducer";
 
export default combineReducers({
  template,
  route,
  campaign,
  countries,
});
