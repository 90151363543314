

import {
  CommonText,
  ArrowBox,
  BackLinkSection,
} from "../common";

import Back from "../../assets/icons/back.svg";

const BackButtonComponent = (props) => (
  <BackLinkSection onClick={props.onClick}>
    <ArrowBox>
      <img src={Back} alt="back" width="100%" />
    </ArrowBox>
    <CommonText name fontSize={props.font ? props.font :"13px"}>
      {props.subTitle}
    </CommonText>
  </BackLinkSection>
);

export default BackButtonComponent;
