import styled from "styled-components";

export const CampaignListContainer = styled.div`
  display: grid;
  padding: 20px;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Chartdiv = styled.div`
  display: grid;
  grid-template-columns: 100%;
  padding: 40px;

  justify-items: center;
  & div {
    width: 800px;
  }
  @media (max-width: 860px) {
    padding: 0px;
    justify-items: start;
    & div {
      width: auto;
    }
  }
`;

export const Card = styled.div`
  display: grid;
  //padding: 20px;
  grid-gap: 1px;
  min-width: 220px;
  max-width: 345px;
  min-height: 110px;
  max-height: 129px;
  grid-template-columns: 70% 30%;
  background-color: ${(p) => p.color && p.color};
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  & div {
    display: grid;
    grid-template-columns: 100%;
  }
`;
export const LabelNumber = styled.label`
  text-align: left;
  font: normal normal medium 36px/55px Poppins;
  letter-spacing: 0px;
  color: #1f2939;
  opacity: 1;
  font-size: ${(p) => (p.size ? p.size : "30px")};
`;
export const LabelNameCard = styled.label`
  margin: ${(props) => (props.margin ? props.margin : `auto 0`)};
  color: ${(props) => (props.name ? `#1F2939` : `rgba(31, 41, 57, 0.7)`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `14px`)};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.cursor && props.cursor};
  @media (max-width: 915px) {
    //grid-template-columns: ${(props) => props.responsive && props.responsive};
    //grid-gap: 20px;
    // padding: 10px 20px;
    font-size: 10px;
  }
  @media (max-width: 590px) {
    //grid-template-columns: ${(props) => props.responsive && props.responsive};
    //grid-gap: 20px;
    // padding: 10px 20px;
    font-size: 8px;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.background && props.background};
  border-radius: 6px;
  opacity: 1;
  padding: 12px 25px;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 400px;
  border: 1px solid #fff;
  & label {
    font: ${(props) => props.font && props.font};
    color: ${(props) => props.fColor && props.fColor};
    cursor: inherit;
    font-size: 13px;
    margin: 0;
  }
  &:hover {
    border: 1px solid ${(props) => props.hColor && props.hColor};
  }
`;
export const MainDiv = styled.div`
  display: grid;
  padding: ${(props) => (props.marginB ? props.marginB : "10px 20px ")};
  grid-gap: ${(props) => props.gap && props.gap};
  grid-template-columns: ${(props) => props.colum && props.colum};
  justify-items: ${(props) => props.justify && props.justify};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  align-items: center;
  margin-top: ${(props) => props.mar && props.mar};
  @media (max-width: ${(props) =>
      props.responsiveValue && props.responsiveValue}) {
    grid-template-columns: ${(props) => props.responsive && props.responsive};
    grid-gap: 20px;
    justify-items: ${(props) =>
      props.responsivePosition && props.responsivePosition};
    // padding: 10px 20px;
  }
`;
export const Labeldate = styled.label`
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0.28px;
  color: #1f2939;
  opacity: 1;
  font-size: 13px;
`;

export const WhiteDiv = styled.div`
  height: ${(props) => props.hei && props.hei};
  width: ${(props) => props.wi && props.wi};
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 6px;
  margin-top: ${(props) => props.margin && props.margin};
`;
