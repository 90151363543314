import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Skeleton from "./Skeleton";
import PricingPlan from "./PricingPlan";
import { apiKey, redirectUri } from "./constants/shopifyConstants";
import { Provider } from "@shopify/app-bridge-react";
import { getSessionToken, authenticatedFetch } from "@shopify/app-bridge-utils";
import axios from "axios";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import "./index.css";

const MyApp = () => {
  const [finalized, setFinalized] = useState("uninstalled");
  const [shop, setShop] = useState("");
  const [plan, setPlan] = useState("");
  const [redirect, setRedirect] = useState(false);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const setOrigin = () => {
    return new Promise((resolve, reject) => {
      let shop = "";
      if (typeof window !== "undefined") {
        shop = params.shop;
        resolve(shop);
      }
    });
  };

  const setHost = () => {
    return new Promise((resolve, reject) => {
      let host = "";
      if (typeof window !== "undefined") {
        host = params.host;
        resolve(host);
      }
    });
  };

  const setChargeId = () => {
    return new Promise((resolve, reject) => {
      let chargeId = "";
      if (typeof window !== "undefined") {
        chargeId = params.charge_id;
        resolve(chargeId);
      }
    });
  };

  const setPermaURL = async (shopOrigin, state) => {
    return new Promise((resolve, reject) => {
      resolve(
        `https://${shopOrigin}/admin/oauth/authorize?client_id=${apiKey}&scope=read_price_rules&redirect_uri=${redirectUri}&state=${state}`
      );
    });
  };

  const checkPlanUpdate = async (shopOrigin, charge_id) => {
    try {
      const URL = `${
        process.env.REACT_APP_API_URL
      }/api/plan_update/?shop=${shopOrigin}&charge_id=${
        charge_id === undefined ? "" : charge_id
      }`.toString();
      const response = await axios.get(URL);
      return response;
    } catch (errors) {
      console.error(errors);
    }
  };

  const checkIsInstalled = async (shopOrigin) => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/install_check/?shop=${shopOrigin}`.toString();
      const response = await axios.get(URL);
      console.log(response);
      return response;
    } catch (errors) {
      console.error(errors);
    }
  };

  const callInstallApi = async () => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/install/${window.location.search}`.toString();
      const response = await axios.get(URL);
      console.log(response);
      return response;
    } catch (errors) {
      console.error(errors);
    }
  };

  console.log("front end loaded");

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log(typeof window);
      async function sequence() {
        try {
          const shopOrigin = await setOrigin();
          setShop(shopOrigin);
          const host = await setHost();
          console.log(`shop origin : ${shopOrigin}`);
          console.log(`host : ${host}`);
          const response = await checkIsInstalled(shopOrigin);
          if (window.top === window.self) {
            console.log(`!embedded : ${window.top === window.self}`);
            if (response.status === 200 && response.data.installed === false) {
              setRedirect(false);
              const callInstall = await callInstallApi();
              const permissionUrl = await setPermaURL(
                shopOrigin,
                callInstall.data
              );
              console.log(`call install data : ${callInstall.data}`);
              console.log(`permissionUrl : ${permissionUrl}`);
              window.location.assign(permissionUrl);
            } else if (
              response.status === 200 &&
              response.data.installed === true
            ) {
              console.log(`installed`);
              setFinalized("installed");
              setRedirect(true);
            }
          } else {
            console.log(`is embedded : ${window.top === window.self}`);
            const chargeId = await setChargeId();
            const planResponse = await checkPlanUpdate(shopOrigin, chargeId);
            setPlan(planResponse.data.plan_name);
            if (
              response.status === 200 &&
              response.data.installed === true &&
              (planResponse.data.plan_status === "active" ||
                planResponse.data.plan_status === "accepted")
            ) {
              setFinalized("installed");
            } else if (
              response.status === 200 &&
              response.data.installed === true &&
              planResponse.data.plan_status !== "active" &&
              planResponse.data.plan_status !== "accepted"
            ) {
              setFinalized("unpaid");
            }

            const app = createApp({
              apiKey: apiKey,
              host: host,
            });

            const sessionToken = await getSessionToken(app);
          }
        } catch (error) {
          console.error(error);
        }
      }
      sequence();
    }
  }, []);

  console.log(window.location.href);

  return (
    <Provider
      config={{
        apiKey: apiKey,
        host: params.host,
        forceRedirect: redirect,
      }}
    >
      {finalized === "installed" ? (
        <App shop={shop} plan={plan} />
      ) : finalized === "unpaid" ? (
        <PricingPlan shop={shop} />
      ) : (
        <Skeleton />
      )}
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>,
  document.getElementById("root")
);
