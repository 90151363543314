import {
    GET_COUNTRIES,
    GET_DISCOUNT_COUPONS,
  } from "../constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getCountriesList(value) {
    return {
      type: GET_COUNTRIES,
      payload: value,
    };
  }

export function getDiscountCoupons(value) {
    return {
      type: GET_DISCOUNT_COUPONS,
      payload: value,
    };
  }