import { all, fork } from "redux-saga/effects";
import campaignSagas from "./campaignSagas"
import countriesSagas from "./countriesSagas"


/**
 * rootSaga
 */
export default function* root() {
  yield all([
    // fork(loginSagas),
    //fork(registerSagas),
    fork(campaignSagas),
    fork(countriesSagas)
  ]);
}
