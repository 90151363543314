import React, { useState, useEffect } from "react";
import {
  section,
  text,
  input,
  link,
  image,
  submit,
} from "../../constants/elementConstant";
import Text from "./text";
import Input from "./input";
import LinkComp from "./link";
import Image from "./image";
import Submit from "./submit";

const Section = ({ element }) => {
  // const setEditor = (element) => {

  //   let array = element.id
  //     .slice(2)
  //     .split("-")
  //     .map((item) => parseInt(item));
  //   console.log(array);
  // };

  return (
    <React.Fragment key={element.id}>
      { element.visible === false ? <></> : 
      <div
        style={Object.assign(
          {},
          { ...element.defaultStyle },
          { ...element.style }
        )}
      >
        {element.blocks &&
          element.blocks.length > 0 &&
          element.blocks.map((sectionElement, index) => {
            if(sectionElement.type){
              switch (sectionElement.type) {
                case section:
                  return <Section element={sectionElement} />;
                case text:
                  return <Text element={sectionElement} />;
                case input:
                  return <Input element={sectionElement} />;
                case link:
                  return <LinkComp element={sectionElement} />;
                case submit:
                  return <Submit element={sectionElement} />;
                case image:
                  return <Image element={sectionElement} />;
                default:
                  console.error(`type not supported`);
              }
            }
          })}
      </div>
      }
    </React.Fragment>
  );
};

export default Section;
