import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ImageUploading from "react-images-uploading";
import DropDownStyle from "../../utils/DropDownStyle";
import Select from "react-select";
import ColorPallete from "../ColorPallate";
import moment from "moment";
import DatePicker from "../../utils/SingleDatePicker";
import TimePicker from "../../utils/TimePickerNew";
import WebFont from "webfontloader";
import FontPicker from "../../utils/FontPicker";

import {
  input,
  section,
  submit,
  link,
  text,
} from "../../constants/elementConstant";
import {
  updateRouteState,
  updateTemplateType,
  updateCampaignId,
} from "../../actions/actionRoute";
import { getCampaignViaId } from "../../actions/actionCampaign";
import {
  updateTemplate,
  updateField,
  updateTemplateTitle,
  updateTemplateFont,
  updateSpecificForm,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import ViewTemplate from "./ViewTemplate";
import ComponentCircleLoader from "../../utils/Loader/ComponentCircleLoader";

import { CAMPAIGN_LIST } from "../../constants/componentName";

import {
  getDaysFunc,
  getHoursFunc,
  getMinutesFunc,
  getSecondsFunc,
} from "../../utils/timeFormations";

import {
  CommonTitle,
  CommonText,
  InputField,
  CommonColorButton,
  CommonWhButton,
  FormAlert,
  CommonItemGrid,
  IconTextButton,
} from "../../styledComponent/common";
import {
  FormContainer,
  DragButton,
  UploadImageSection,
  UploadImageButtonContainer,
  NestedFormContainer,
  InputRow,
  AlignSelectContainer,
  AlignSectionItem,
  TimeInputContainer,
  TextElementSection,
  FormText,
  ColorViewer,
  ColorViewerRound,
  PositionButton,
  InputIcon,
  SocialURLContainer,
  NoDiscountBox,
  CouponsTable,
  CouponsTableCell,
  CouponRadioButton,
} from "../../styledComponent/step1";
import {
  StaticFieldContainer,
  RoundSelectText,
  RoundSelect,
  CheckBox,
} from "../../styledComponent/step2";
import tick from "../../assets/icons/tick_2.svg";
import alignLeft from "../../assets/icons/align-left.png";
import alignLeftActive from "../../assets/icons/align-left-active.png";
import alignCenter from "../../assets/icons/align-center.png";
import alignCenterActive from "../../assets/icons/align-center-active.png";
import alignRight from "../../assets/icons/align-right.png";
import alignRightActive from "../../assets/icons/align-right-active.png";
import alignJustify from "../../assets/icons/align-justify.png";
import alignJustifyActive from "../../assets/icons/align-justify-active.png";
import positionTop from "../../assets/icons/position-top.png";
import positionTopActive from "../../assets/icons/position-top-active.png";
import positionBottom from "../../assets/icons/position-bottom.png";
import positionBottomActive from "../../assets/icons/position-bottom-active.png";
import uploadPhoto from "../../assets/icons/upload-photo.png";

const Step1 = (props) => {
  const [form, setForm] = useState({
    campaign_name: "",
    title: "",
    title_text_color: "",
    description: "",
    description_text_color: "",
    align: "left",
    fonts: "",
    image: "",
    icon: "",
    background_color: "",
    button_text: "",
    button_text_color: "",
    button_background_color: "",
    button_hover_color: "",
    discount_label: "",
    discount_label_background_color: "",
    discount_label_text_color: "",
    coupon_code_text: "",
    coupon_code_background_color: "",
    position: "",
    collect_user_email: false,
    collect_user_email_button_text: "",
    collect_user_email_button_text_color: "",
    collect_user_email_backgroud_color: "",
    collect_user_email_hover_color: "",
    add_countdown_time: "",
    promote_page_link: "",
    promote_page_button_text: "",
    promote_page_button_background_color: "",
    promote_page_button_text_color: "",
    promote_page_button_hover_color: "",
    social_buttons_facebook_url: "",
    social_buttons_twitter_url: "",
    social_buttons_youtube_url: "",
    social_buttons_linkedin_url: "",
    social_buttons_instagram_url: "",
    social_buttons_pinterest_url: "",
  });

  const [collect_user_email_enable, set_collect_user_email_enable] =
    useState(null);
  const [promote_page_enable, set_promote_page_enable] = useState(null);
  const [add_countdown_enable, set_add_countdown_enable] = useState(null);
  const [social_buttons_enable, set_social_buttons_enable] = useState(null);

  const [timeCountDate, setTimeCountDate] = useState(
    moment(new Date()).add(1, "days")
  );
  const [timeCountHour, setTimeCountHour] = useState(moment().format("HH:mm"));
  const [GMT, setGMT] = useState(0);
  const [lastTime, setLastTime] = useState(moment());
  const [timeDifference, setTimeDifference] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [filteredCouponList, setFilteredCouponList] = useState([]);
  const [activeCouponSearch, setActiveCouponSearch] = useState(false);

  const fontOptions = [
    { value: "Roboto", label: "Roboto" },
    { value: "Italianno", label: "Italianno" },
    { value: "Poppins", label: "Poppins" },
    { value: "Lato", label: "Lato" },
    { value: "Ephesis", label: "Ephesis" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Oswald", label: "Oswald" },
    { value: "Source Sans Pro", label: "Source Sans Pro" },
    { value: "Raleway", label: "Raleway" },
    { value: "PT Sans", label: "PT Sans" },
    { value: "Merriweather", label: "Open Sans" },
    { value: "Noto Sans / Serif", label: "Noto Sans / Serif" },
    { value: "Nunito Sans", label: "Nunito Sans" },
    { value: "Concert One", label: "Concert One" },
    { value: "Prompt", label: "Prompt" },
    { value: "Work Sans", label: "Work Sans" },
  ];
  const [activeFont, setActiveFont] = useState("Poppins");

  const discountPositionOptions = [
    { value: "topLeft", label: "top left" },
    { value: "topRight", label: "top right" },
    { value: "bottomLeft", label: "bottom left" },
    { value: "bottomRight", label: "bottom right" },
  ];

  const positionOptions = [
    { value: "start", label: "top" },
    { value: "start", label: "top always visible" },
    { value: "end", label: "bottom" },
    { value: "end", label: "bottom always visible" },
  ];

  useEffect(() => {
    if (
      props.specificForm &&
      props.specificForm.collect_user_email !== undefined &&
      collect_user_email_enable === null
    ) {
      set_collect_user_email_enable(props.specificForm.collect_user_email);
    }
    if (
      props.specificForm &&
      props.specificForm.promote_page_link !== undefined &&
      props.specificForm.promote_page_link !== "" &&
      promote_page_enable === null
    ) {
      set_promote_page_enable(true);
    }
    if (
      props.specificForm &&
      props.specificForm.add_countdown_time !== undefined &&
      props.specificForm.add_countdown_time !== "" &&
      add_countdown_enable === null
    ) {
      set_add_countdown_enable(true);
    }
    if (
      props.specificForm &&
      social_buttons_enable === null &&
      ((props.specificForm.social_buttons_facebook_url !== undefined &&
        props.specificForm.social_buttons_facebook_url !== "") ||
        (props.specificForm.social_buttons_instagram_url !== undefined &&
          props.specificForm.social_buttons_instagram_url !== "") ||
        (props.specificForm.social_buttons_linkedin_url !== undefined &&
          props.specificForm.social_buttons_linkedin_url !== "") ||
        (props.specificForm.social_buttons_pinterest_url !== undefined &&
          props.specificForm.social_buttons_pinterest_url !== "") ||
        (props.specificForm.social_buttons_twitter_url !== undefined &&
          props.specificForm.social_buttons_twitter_url !== "") ||
        (props.specificForm.social_buttons_youtube_url !== undefined &&
          props.specificForm.social_buttons_youtube_url !== ""))
    ) {
      set_social_buttons_enable(true);
    }
    // console.log("specForm", props.specificForm);
  }, [props.specificForm]);
  useEffect(() => {
    // console.log("selectedForm", props.selectedFields);
  }, [props.selectedFields]);
  useEffect(() => {
    if (social_buttons_enable === false) {
      onChangeSocialLinkURL("social_buttons_instagram_url", "");
      onChangeSocialLinkURL("social_buttons_linkedin_url", "");
      onChangeSocialLinkURL("social_buttons_youtube_url", "");
      onChangeSocialLinkURL("social_buttons_pinterest_url", "");
      onChangeSocialLinkURL("social_buttons_twitter_url", "");
      onChangeSocialLinkURL("social_buttons_facebook_url", "");
    }
  }, [social_buttons_enable]);

  const findAddress = (key, isStyle, field) => {
    if (props.selectedFields[key].isImage) {
      return [];
    } else if (props.selectedFields[key].id === "0") {
      return isStyle
        ? props.selectedTemplate.style[field]
        : props.selectedTemplate[field];
    } else if (Object.keys(props.selectedFields[key]).length === 1) {
      return "";
    } else if (
      props.selectedFields[key].id &&
      props.selectedFields[key].id !== ""
    ) {
      let locationArray = props.selectedFields[key].id
        .slice(2)
        .split("-")
        .map((item) => parseInt(item));

      let selectedElement = { ...props.selectedTemplate };

      locationArray.forEach((number) => {
        selectedElement = selectedElement.blocks[number];
      });
      return isStyle ? selectedElement.style[field] : selectedElement[field];
    }
  };

  const onChangeCampaignName = (value) => {
    props.updateSpecificForm({
      data: { ...props.specificForm, campaign_name: value },
    });
  };

  const onChangeFieldValue = (key, value) => {
    try {
      let formCopy = { ...props.specificForm };
      if (key === "collect_user_email_hover_color") {
        formCopy[key].backgroundColor = value;
      } else {
        formCopy[key] = value;
      }
      props.updateSpecificForm({
        data: formCopy,
      });

      let errors = { ...props.saveErrors };
      delete errors[key];
      errors[`${key}_not_valid`] && delete errors[`${key}_not_valid`];
      props.updateSaveErrors({ data: errors });

      let templateCopy = { ...props.selectedTemplate };
      let { id, isStyle, field, isImage } = props.selectedFields[key];

      if (id === "0") {
        if (isStyle && isImage) {
          if (value.length === 0) {
            templateCopy.style[field] =
              templateCopy.style.backgroundColor || `#fff`;
          } else {
            templateCopy.style[
              field
            ] = `url("${value[0].data_url}") no-repeat center`;
            templateCopy.style.backgroundSize = `cover`;
          }
        } else if (isStyle) {
          templateCopy.style[field] = value;
        } else if (isImage && field === "src") {
          templateCopy[field] = value[0].data_url;
        } else {
          templateCopy[field] = value;
        }
        props.updateTemplate({ data: templateCopy });
      } else {
        let locationArray = id
          .slice(2)
          .split("-")
          .map((item) => parseInt(item));

        let currentPath = templateCopy.blocks;

        locationArray.map((level) => {
          if (currentPath[level]) {
            if (currentPath[level].id && currentPath[level].id === id) {
              if (isStyle && isImage) {
                if (field === "src") {
                  currentPath[level][field] = `${value[0].data_url}`;
                } else {
                  currentPath[level].style[
                    field
                  ] = `url("${value[0].data_url}") no-repeat center`;
                  currentPath[level].style.backgroundSize = `cover`;
                }
              } else if (isStyle) {
                currentPath[level].style[field] = value;
              } else if (isImage && field === "src") {
                currentPath[level][field] = value[0].data_url;
              } else {
                if (key === "collect_user_email_hover_color") {
                  currentPath[level][field].backgroundColor = value;
                } else {
                  currentPath[level][field] = value;
                }
              }
              props.updateTemplate({ data: templateCopy });
            } else if (currentPath[level].blocks) {
              currentPath = currentPath[level].blocks;
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeFontFamily = (value) => {
    let formCopy = { ...props.specificForm };
    formCopy.fonts = value;
    props.updateSpecificForm({
      data: formCopy,
    });

    WebFont.load({
      google: {
        families: [`${value}:500,700`, "sans-serif"],
      },
    });

    let templateCopy = { ...props.selectedTemplate };
    templateCopy.style.fontFamily = value;
    props.updateTemplate({ data: templateCopy });
  };

  const onTimeCountDateChange = (date) => {
    setTimeCountDate(date);
    let formattedDate = date.format("DD-MM-YYYY");
    let time = moment(
      formattedDate + " " + timeCountHour,
      "DD-MM-YYYY HH:mm"
    ).utcOffset(GMT);
    setLastTime(time);
  };

  const onTimeCountHourChange = (value) => {
    setTimeCountHour(value);
    let time = moment(
      timeCountDate.format("DD-MM-YYYY") + " " + value,
      "DD-MM-YYYY HH:mm"
    ).utcOffset(GMT);
    setLastTime(time);
  };

  const onChangeGMT = (value) => {
    if (!isNaN(value) && value.indexOf(".") === -1) {
      if (parseInt(value) > -13 && parseInt(value) < 13) {
        setGMT(parseInt(value));
        let time = moment(
          timeCountDate.format("DD-MM-YYYY") + " " + timeCountHour,
          "DD-MM-YYYY HH:mm"
        ).utcOffset(parseInt(value));
        setLastTime(time);
      }
    }
  };

  const onChangeCollectUserEmail = () => {
    let templateCopy = { ...props.selectedTemplate };
    let { id } = props.selectedFields.collect_user_email;

    let locationArray = id
      .slice(2)
      .split("-")
      .map((item) => parseInt(item));

    let currentPath = templateCopy.blocks;
    locationArray.forEach((level) => {
      if (currentPath[level]) {
        if (currentPath[level].id && currentPath[level].id === id) {
          currentPath[level].visible = !currentPath[level].visible;
          props.updateTemplate({ data: templateCopy });
        } else if (currentPath[level].blocks) {
          currentPath = currentPath[level].blocks;
        }
      }
    });
    set_collect_user_email_enable(!collect_user_email_enable);
  };

  const onChangePromoteLink = () => {
    if (!promote_page_enable) {
      let fieldsCopy = { ...props.selectedFields };
      fieldsCopy.promote_page_link &&
        (fieldsCopy.promote_page_link = {
          ...fieldsCopy.promote_page_link,
          field: "url",
        });
      fieldsCopy.promote_page_button_text &&
        (fieldsCopy.promote_page_button_text = {
          ...fieldsCopy.promote_page_button_text,
          field: "text",
        });
      fieldsCopy.promote_page_button_background_color &&
        (fieldsCopy.promote_page_button_background_color = {
          ...fieldsCopy.promote_page_button_background_color,
          isStyle: true,
          field: "backgroundColor",
        });
      fieldsCopy.promote_page_button_text_color &&
        (fieldsCopy.promote_page_button_text_color = {
          ...fieldsCopy.promote_page_button_text_color,
          isStyle: true,
          field: "color",
        });
      fieldsCopy.promote_page_button_hover_color &&
        (fieldsCopy.promote_page_button_hover_color = {
          ...fieldsCopy.promote_page_button_hover_color,
          field: "hover",
        });
      props.updateField({ data: fieldsCopy });
    } else {
      let fieldsCopy = { ...props.selectedFields };
      fieldsCopy.promote_page_link && delete fieldsCopy.promote_page_link.field;
      fieldsCopy.promote_page_button_text &&
        delete fieldsCopy.promote_page_button_text.field;
      delete fieldsCopy.promote_page_button_background_color.isStyle;
      delete fieldsCopy.promote_page_button_background_color.field;
      delete fieldsCopy.promote_page_button_text_color.isStyle;
      delete fieldsCopy.promote_page_button_text_color.field;
      delete fieldsCopy.promote_page_button_hover_color.field;
      props.updateField({ data: fieldsCopy });
    }

    let buttonText = "Go",
      backgroundColor = "#FF6666",
      textColor = "#fff";
    let hoverColor = {backgroundColor: "#e05a5a"};
    let formCopy = { ...props.specificForm };
    formCopy.promote_page_button_text = buttonText;
    formCopy.promote_page_button_background_color = backgroundColor;
    formCopy.promote_page_button_text_color = textColor;
    formCopy.promote_page_button_hover_color = hoverColor;
    props.updateSpecificForm({
      data: formCopy,
    });

    let templateCopy = { ...props.selectedTemplate };
    let { id } = props.selectedFields.promote_page_link;

    let locationArray = id
      .slice(2)
      .split("-")
      .map((item) => parseInt(item));

    let currentPath = templateCopy.blocks;

    locationArray.forEach((level) => {
      if (currentPath[level]) {
        if (currentPath[level].id && currentPath[level].id === id) {
          if (!promote_page_enable) {
            currentPath[level].type = link;
            currentPath[level].text = buttonText;
            currentPath[level].url = "";
            currentPath[level].newTab = true;
            currentPath[level].hover = hoverColor;
            currentPath[level].style = {
              color: textColor,
              fontSize: "14px",
              backgroundColor: backgroundColor,
              padding: "12px 30px",
              borderRadius: "4px",
              justifyContent: "center",
              textDecoration: "none",
            };
          } else {
            delete currentPath[level].type;
            delete currentPath[level].text;
            delete currentPath[level].url;
            delete currentPath[level].newTab;
            delete currentPath[level].style;
          }
          props.updateTemplate({ data: templateCopy });
        } else if (currentPath[level].blocks) {
          currentPath = currentPath[level].blocks;
        }
      }
    });

    set_promote_page_enable(!promote_page_enable);
  };

  const onChangeCountDown = () => {
    let templateCopy = { ...props.selectedTemplate };
    let { id } = props.selectedFields.add_countdown_time;

    let locationArray = id
      .slice(2)
      .split("-")
      .map((item) => parseInt(item));

    let currentPath = templateCopy.blocks;

    locationArray.forEach((level) => {
      if (currentPath[level]) {
        if (currentPath[level].id && currentPath[level].id === id) {
          if (!add_countdown_enable) {
            currentPath[level].type = section;
            currentPath[level].style = {
              display: `grid`,
              gridTemplateColumns: `repeat(7, auto)`,
              justifyContent: "center",
              alignItems: "center",
              gridGap: "5px",
            };
            currentPath[level].blocks = [
              {
                id: `0-1-4-0`,
                type: text,
                text: getDaysFunc(timeDifference),
                style: {
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  padding: `13px 15px`,
                  width: `60px`,
                  fontSize: `22px`,
                  fontWeight: `700`,
                  boxShadow: `0px -16px 6px 2px #d3dded inset`,
                },
              },
              {
                id: `0-1-4-1`,
                type: text,
                text: `:`,
                style: {
                  color: `white`,
                  fontSize: `24px`,
                  fontWeight: `700`,
                },
              },
              {
                id: `0-1-4-2`,
                type: text,
                text: getHoursFunc(timeDifference),
                style: {
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  padding: `13px 15px`,
                  width: `60px`,
                  fontSize: `22px`,
                  fontWeight: `700`,
                  boxShadow: `0px -16px 6px 2px #d3dded inset`,
                },
              },
              {
                id: `0-1-4-3`,
                type: text,
                text: `:`,
                style: {
                  color: `white`,
                  fontSize: `24px`,
                  fontWeight: `700`,
                },
              },
              {
                id: `0-1-4-4`,
                type: text,
                text: getMinutesFunc(timeDifference),
                style: {
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  padding: `13px 15px`,
                  width: `60px`,
                  fontSize: `22px`,
                  fontWeight: `700`,
                  boxShadow: `0px -16px 6px 2px #d3dded inset`,
                },
              },
              {
                id: `0-1-4-5`,
                type: text,
                text: `:`,
                style: {
                  color: `white`,
                  fontSize: `24px`,
                  fontWeight: `700`,
                },
              },
              {
                id: `0-1-4-6`,
                type: text,
                text: getSecondsFunc(timeDifference),
                style: {
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  padding: `13px 15px`,
                  width: `60px`,
                  fontSize: `22px`,
                  fontWeight: `700`,
                  boxShadow: `0px -16px 6px 2px #d3dded inset`,
                },
              },
            ];
          } else {
            setTimeDifference(0);
            delete currentPath[level].type;
            delete currentPath[level].style;
            delete currentPath[level].blocks;
          }
          props.updateTemplate({ data: templateCopy });
        } else if (currentPath[level].blocks) {
          currentPath = currentPath[level].blocks;
        }
      }
    });
    set_add_countdown_enable(!add_countdown_enable);
  };

  const selectSocialIcon = (key) => {
    switch (key) {
      case "social_buttons_facebook_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/facebook.svg";
      case "social_buttons_twitter_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/twitter.svg";
      case "social_buttons_youtube_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/youtube.svg";
      case "social_buttons_linkedin_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/linkedin.svg";
      case "social_buttons_instagram_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/instagram.svg";
      case "social_buttons_pinterest_url":
        return "https://segmently-scripts.s3.amazonaws.com/frontend-assets/icons/pinterest.svg";
      default:
        console.error("key not matched for social icon");
    }
  };

  const onChangeSocialLinkURL = (key, value) => {
    if (value !== "" && props.selectedFields[key].field) {
      onChangeFieldValue(key, value);
    } else {
      let formCopy = { ...props.specificForm };
      formCopy[key] = value;
      props.updateSpecificForm({
        data: formCopy,
      });

      let errors = { ...props.saveErrors };
      delete errors[key];
      props.updateSaveErrors({ data: errors });

      if (value !== "" && props.selectedFields[key].field === undefined) {
        let fieldsCopy = { ...props.selectedFields };
        fieldsCopy[key] &&
          (fieldsCopy[key] = {
            ...fieldsCopy[key],
            field: "url",
          });
        props.updateField({ data: fieldsCopy });
      } else if (value === "" && props.selectedFields[key].field) {
        let fieldsCopy = { ...props.selectedFields };
        fieldsCopy[key] &&
          fieldsCopy[key].field &&
          delete fieldsCopy[key].field;
        props.updateField({ data: fieldsCopy });
      }

      let templateCopy = { ...props.selectedTemplate };
      let { id } = props.selectedFields[key];

      let locationArray = id
        .slice(2)
        .split("-")
        .map((item) => parseInt(item));

      let currentPath = templateCopy.blocks;

      locationArray.forEach((level) => {
        if (currentPath[level]) {
          if (currentPath[level].id && currentPath[level].id === id) {
            if (value !== "") {
              currentPath[level].type = link;
              currentPath[level].text = "";
              currentPath[level].url = value;
              currentPath[level].newTab = true;
              currentPath[level].style = {
                color: "#fff",
                fontSize: "14px",
                background: `url(${selectSocialIcon(
                  key
                )}) no-repeat center center`,
                backgroundSize: `contain`,
                padding: "18px",
                borderRadius: "4px",
                justifyContent: "center",
                textDecoration: "none",
              };
            } else {
              delete currentPath[level].type;
              delete currentPath[level].text;
              delete currentPath[level].url;
              delete currentPath[level].newTab;
              delete currentPath[level].style;
            }
            props.updateTemplate({ data: templateCopy });
          } else if (currentPath[level].blocks) {
            currentPath = currentPath[level].blocks;
          }
        }
      });
    }
  };

  const onChangeColorView = (value) => {
    if (showColorPicker === value) {
      setShowColorPicker(null);
    } else setShowColorPicker(value);
  };

  const searchCoupons = (value) => {
    let formCopy = { ...props.specificForm };
    formCopy.coupon_code_text = value;
    props.updateSpecificForm({
      data: formCopy,
    });
    setActiveCouponSearch(true);

    if (value !== "") {
      const filteredData = props.discountCoupons.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredCouponList(filteredData);
    } else {
      setFilteredCouponList(props.discountCoupons);
    }
  };

  const onSelectCoupon = (key, value) => {
    props.selectCoupon(value);
    onChangeFieldValue(key, value);
  };

  useEffect(() => {
    if (
      props.selectedFields &&
      props.selectedTemplate &&
      props.specificForm === null
    ) {
      let newForm = {};
      if (props.campaign_id) {
        newForm.campaign_name = props.campaignViaId.campaign_name;
      } else if (props.template_type_number) {
        newForm.campaign_name = props.template_type_number;
      }
      for (const key in props.selectedFields) {
        newForm[key] = findAddress(
          key,
          props.selectedFields[key].isStyle,
          props.selectedFields[key].field
        );
      }
      props.updateSpecificForm({
        data: {
          ...props.specificForm,
          ...newForm,
        },
      });
    }
  }, [props.selectedTemplate, props.selectedFields]);

  useEffect(() => {
    let tempTimeDifference = parseInt(
      (Date.parse(`${lastTime.format("MMMM DD, YYYY hh:mm A")}`) -
        Date.parse(new Date())) /
        1000
    );
    setTimeDifference(tempTimeDifference);
  }, [lastTime]);

  useEffect(() => {
    if (timeDifference && timeDifference > 0) {
      const intervalId = setInterval(() => {
        setTimeDifference(timeDifference - 1);
      }, 1000);

      let templateCopy = { ...props.selectedTemplate };
      let { id } = props.selectedFields.add_countdown_time;

      let locationArray = id
        .slice(2)
        .split("-")
        .map((item) => parseInt(item));

      let currentPath = templateCopy.blocks;

      locationArray.forEach((level) => {
        if (currentPath[level]) {
          if (
            currentPath[level].id &&
            currentPath[level].blocks &&
            currentPath[level].id === id
          ) {
            currentPath[level].blocks[0].text = getDaysFunc(timeDifference);
            currentPath[level].blocks[2].text = getHoursFunc(timeDifference);
            currentPath[level].blocks[4].text = getMinutesFunc(timeDifference);
            currentPath[level].blocks[6].text = getSecondsFunc(timeDifference);
            props.updateTemplate({ data: templateCopy });
          } else if (currentPath[level].blocks) {
            currentPath = currentPath[level].blocks;
          }
        }
      });
      return () => clearInterval(intervalId);
    }
  }, [timeDifference]);

  console.log(props.specificForm);
  console.log(props.selectedTemplate);

  return (
    <FormContainer>
      <FormText>Campaign Name</FormText>
      {props.specificForm && props.specificForm.campaign_name !== undefined && (
        <>
          <InputRow>
            <InputField
              type="text"
              value={props.specificForm.campaign_name}
              error={props.saveErrors && props.saveErrors.campaign_name}
              onChange={(e) => onChangeCampaignName(e.target.value)}
            />
          </InputRow>
          {props.saveErrors && props.saveErrors.campaign_name && (
            <FormAlert noGap>{props.saveErrors.campaign_name}</FormAlert>
          )}
        </>
      )}

      {props.selectedFields &&
        props.selectedFields.title &&
        props.specificForm &&
        props.specificForm.title !== undefined && (
          <>
            <FormText>Title</FormText>
            <InputRow>
              <InputField
                type="text"
                value={props.specificForm.title}
                error={props.saveErrors && props.saveErrors.title}
                onChange={(e) => onChangeFieldValue("title", e.target.value)}
                onBlur={(e) =>
                  props.updateTemplateTitle({ data: e.target.value })
                }
              />
              {props.selectedFields.title_text_color &&
                props.specificForm.title_text_color && (
                  <ColorViewer
                    onClick={() => onChangeColorView("title_text_color")}
                  >
                    <ColorViewerRound
                      color={props.specificForm.title_text_color}
                    />
                    <div>{props.specificForm.title_text_color}</div>
                    {showColorPicker === "title_text_color" && (
                      <ColorPallete
                        updateElementColor={onChangeFieldValue}
                        keyProp="title_text_color"
                        initialColor={props.specificForm.title_text_color}
                        closeColorView={onChangeColorView}
                      />
                    )}
                  </ColorViewer>
                )}
            </InputRow>
            {props.saveErrors && props.saveErrors.title && (
              <FormAlert noGap>{props.saveErrors.title}</FormAlert>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.description &&
        props.specificForm &&
        props.specificForm.description !== undefined && (
          <>
            <FormText>Description</FormText>
            <InputRow>
              <InputField
                type="text"
                value={props.specificForm.description}
                error={props.saveErrors && props.saveErrors.description}
                onChange={(e) =>
                  onChangeFieldValue("description", e.target.value)
                }
              />
              {props.selectedFields.description_text_color &&
                props.specificForm.description_text_color && (
                  <ColorViewer
                    onClick={() => onChangeColorView("description_text_color")}
                  >
                    <ColorViewerRound
                      color={props.specificForm.description_text_color}
                    />
                    <div>{props.specificForm.description_text_color}</div>
                    {showColorPicker === "description_text_color" && (
                      <ColorPallete
                        updateElementColor={onChangeFieldValue}
                        keyProp="description_text_color"
                        initialColor={props.specificForm.description_text_color}
                        closeColorView={onChangeColorView}
                      />
                    )}
                  </ColorViewer>
                )}
            </InputRow>
            {props.saveErrors && props.saveErrors.description && (
              <FormAlert noGap>{props.saveErrors.description}</FormAlert>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.align &&
        props.specificForm &&
        props.specificForm.align !== undefined && (
          <>
            <FormText>Align</FormText>
            <AlignSelectContainer>
              <AlignSectionItem
                selected={props.specificForm.align === "left"}
                onClick={() => onChangeFieldValue("align", "left")}
              >
                {props.specificForm.align === "left" ? (
                  <img src={alignLeftActive} alt="align-left-active" />
                ) : (
                  <img src={alignLeft} alt="align-left" />
                )}
              </AlignSectionItem>
              <AlignSectionItem
                selected={props.specificForm.align === "center"}
                onClick={() => onChangeFieldValue("align", "center")}
              >
                {props.specificForm.align === "center" ? (
                  <img src={alignCenterActive} alt="align-center-active" />
                ) : (
                  <img src={alignCenter} alt="align-center" />
                )}
              </AlignSectionItem>
              <AlignSectionItem
                selected={props.specificForm.align === "right"}
                onClick={() => onChangeFieldValue("align", "right")}
              >
                {props.specificForm.align === "right" ? (
                  <img src={alignRightActive} alt="align-right-active" />
                ) : (
                  <img src={alignRight} alt="align-right" />
                )}
              </AlignSectionItem>
              <AlignSectionItem
                selected={props.specificForm.align === "justify"}
                onClick={() => onChangeFieldValue("align", "justify")}
              >
                {props.specificForm.align === "justify" ? (
                  <img src={alignJustifyActive} alt="align-justify-active" />
                ) : (
                  <img src={alignJustify} alt="align-justify" />
                )}
              </AlignSectionItem>
            </AlignSelectContainer>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.fonts &&
        props.specificForm &&
        props.specificForm.fonts !== undefined && (
          <>
            <FormText>Fonts</FormText>
            <CommonItemGrid columns="minmax(320px, 380px)">
              <FontPicker
                apiKey="AIzaSyDSbnlCXIE6QEcB6AUgxFInMsGOXd7Y2eo"
                activeFontFamily={props.specificForm.fonts}
                onChange={(nextFont) => onChangeFontFamily(nextFont.family)}
              />
            </CommonItemGrid>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.image &&
        props.specificForm &&
        props.specificForm.image !== undefined && (
          <>
            <FormText>Background Image</FormText>
            <ImageUploading
              value={props.specificForm.image}
              onChange={(imageList) => onChangeFieldValue("image", imageList)}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div>
                  <DragButton
                    isDragging={isDragging}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img src={uploadPhoto} alt="backgroud" width="33px" />
                    <div>
                      Drop Image here or{" "}
                      <span style={{ color: `#4D7CFF` }}>Browse</span>
                    </div>
                  </DragButton>
                  {imageList &&
                    imageList.length > 0 &&
                    imageList.map((image, index) => (
                      <UploadImageSection key={index}>
                        <img
                          src={image["data_url"]}
                          alt=""
                          style={{ maxWidth: `110px`, borderRadius: `6px` }}
                        />
                        <UploadImageButtonContainer>
                          {props.selectedFields.image.field !== "src" && (
                            <CommonWhButton
                              color="#FE5969"
                              borderColor="#BFCFE0"
                              padding="8px 20px"
                              size="13px"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </CommonWhButton>
                          )}
                          <CommonWhButton
                            color="#4D7CFF"
                            borderColor="#BFCFE0"
                            padding="8px 20px"
                            size="13px"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </CommonWhButton>
                        </UploadImageButtonContainer>
                      </UploadImageSection>
                    ))}
                </div>
              )}
            </ImageUploading>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.icon &&
        props.specificForm &&
        props.specificForm.icon !== undefined && (
          <>
            <FormText>Icon Image</FormText>
            <ImageUploading
              value={props.specificForm.icon}
              onChange={(imageList) => onChangeFieldValue("icon", imageList)}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div>
                  <DragButton
                    isDragging={isDragging}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img src={uploadPhoto} alt="backgroud" width="33px" />
                    <div>
                      Drop Image here or{" "}
                      <span style={{ color: `#4D7CFF` }}>Browse</span>
                    </div>
                  </DragButton>
                  {imageList.map((image, index) => (
                    <UploadImageSection key={index}>
                      <img
                        src={image["data_url"]}
                        alt="icon"
                        style={{ maxWidth: `110px` }}
                      />
                      <UploadImageButtonContainer>
                        <CommonWhButton
                          color="#FE5969"
                          borderColor="#BFCFE0"
                          padding="8px 20px"
                          size="13px"
                          onClick={() => onImageRemove(index)}
                        >
                          Remove
                        </CommonWhButton>
                        <CommonWhButton
                          color="#4D7CFF"
                          borderColor="#BFCFE0"
                          padding="8px 20px"
                          size="13px"
                          onClick={() => onImageUpdate(index)}
                        >
                          Update
                        </CommonWhButton>
                      </UploadImageButtonContainer>
                    </UploadImageSection>
                  ))}
                </div>
              )}
            </ImageUploading>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.background_color &&
        props.specificForm &&
        props.specificForm.background_color !== undefined && (
          <>
            <FormText>Background color</FormText>
            <CommonItemGrid>
              <ColorViewer
                onClick={() => onChangeColorView("background_color")}
              >
                <ColorViewerRound color={props.specificForm.background_color} />
                <div>{props.specificForm.background_color}</div>
                {showColorPicker === "background_color" && (
                  <ColorPallete
                    updateElementColor={onChangeFieldValue}
                    keyProp="background_color"
                    initialColor={props.specificForm.background_color}
                    closeColorView={onChangeColorView}
                  />
                )}
              </ColorViewer>
            </CommonItemGrid>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.button_text &&
        props.specificForm &&
        props.specificForm.button_text !== undefined && (
          <>
            <CommonItemGrid columns="auto auto" gap="20px">
              {props.selectedFields.button_background_color &&
                props.specificForm.button_background_color !== undefined && (
                  <TextElementSection>
                    <FormText>Button Background Color</FormText>
                    <ColorViewer
                      onClick={() =>
                        onChangeColorView("button_background_color")
                      }
                    >
                      <ColorViewerRound
                        color={props.specificForm.button_background_color}
                      />
                      <div>{props.specificForm.button_background_color}</div>
                      {showColorPicker === "button_background_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="button_background_color"
                          initialColor={
                            props.specificForm.button_background_color
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                )}
              {props.selectedFields.button_hover_color &&
                props.specificForm.button_hover_color !== undefined && (
                  <TextElementSection>
                    <FormText>Button Hover Color</FormText>
                    <ColorViewer
                      onClick={() => onChangeColorView("button_hover_color")}
                    >
                      <ColorViewerRound
                        color={
                          props.specificForm.button_hover_color.backgroundColor
                        }
                      />
                      <div>
                        {props.specificForm.button_hover_color.backgroundColor}
                      </div>
                      {showColorPicker === "button_hover_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="button_hover_color"
                          initialColor={
                            props.specificForm.button_hover_color
                              .backgroundColor
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                )}
            </CommonItemGrid>
            <InputRow>
              <TextElementSection>
                <FormText>Button Text</FormText>
                <InputField
                  type="text"
                  value={props.specificForm.button_text}
                  error={props.saveErrors && props.saveErrors.button_text}
                  onChange={(e) =>
                    onChangeFieldValue("button_text", e.target.value)
                  }
                />
              </TextElementSection>
              {props.selectedFields.button_text_color &&
                props.specificForm.button_text_color !== undefined && (
                  <TextElementSection>
                    <FormText>Button Text Color</FormText>

                    <ColorViewer
                      onClick={() => onChangeColorView("button_text_color")}
                    >
                      <ColorViewerRound
                        color={props.specificForm.button_text_color}
                      />
                      <div>{props.specificForm.button_text_color}</div>
                      {showColorPicker === "button_text_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="button_text_color"
                          initialColor={props.specificForm.button_text_color}
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                )}
            </InputRow>
            {props.saveErrors && props.saveErrors.button_text && (
              <FormAlert noGap>{props.saveErrors.button_text}</FormAlert>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.discount_label &&
        props.specificForm &&
        props.specificForm.discount_label !== undefined && (
          <>
            <FormText>Discount label</FormText>
            <CommonItemGrid columns="minmax(320px, 380px)">
              <InputField
                type="text"
                value={props.specificForm.discount_label}
                error={props.saveErrors && props.saveErrors.discount_label}
                onChange={(e) =>
                  onChangeFieldValue("discount_label", e.target.value)
                }
              />
              {props.saveErrors && props.saveErrors.discount_label && (
                <FormAlert noGap>
                  {props.saveErrors.discount_label}
                </FormAlert>
              )}
            </CommonItemGrid>

            <CommonItemGrid columns="auto auto" gap="20px">
              {props.selectedFields.discount_label_text_color &&
                props.specificForm.discount_label_text_color !== undefined && (
                  <TextElementSection>
                    <FormText>Label Text Color</FormText>

                    <ColorViewer
                      onClick={() =>
                        onChangeColorView("discount_label_text_color")
                      }
                    >
                      <ColorViewerRound
                        color={props.specificForm.discount_label_text_color}
                      />
                      <div>{props.specificForm.discount_label_text_color}</div>
                      {showColorPicker === "discount_label_text_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="discount_label_text_color"
                          initialColor={
                            props.specificForm.discount_label_text_color
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                )}

              {props.selectedFields.discount_label_background_color &&
                props.specificForm.discount_label_background_color !==
                  undefined && (
                  <TextElementSection>
                    <FormText>Label Background Color</FormText>
                    <ColorViewer
                      onClick={() =>
                        onChangeColorView("discount_label_background_color")
                      }
                    >
                      <ColorViewerRound
                        color={
                          props.specificForm.discount_label_background_color
                        }
                      />
                      <div>
                        {props.specificForm.discount_label_background_color}
                      </div>
                      {showColorPicker ===
                        "discount_label_background_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="discount_label_background_color"
                          initialColor={
                            props.specificForm.discount_label_background_color
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                )}
            </CommonItemGrid>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.position !== undefined &&
        props.specificForm &&
        props.specificForm.position !== undefined && (
          <>
            <FormText>Position</FormText>
            <CommonItemGrid columns="auto auto" gap="15px">
              <PositionButton
                selected={props.specificForm.position === "start"}
                onClick={() => onChangeFieldValue("position", "start")}
              >
                {props.specificForm.position === "start" ? (
                  <img src={positionTopActive} alt="top icon active" />
                ) : (
                  <img src={positionTop} alt="top icon" />
                )}
                <div>Top</div>
              </PositionButton>
              <PositionButton
                selected={props.specificForm.position === "end"}
                onClick={() => onChangeFieldValue("position", "end")}
              >
                {props.specificForm.position === "end" ? (
                  <img src={positionBottomActive} alt="bottom icon active" />
                ) : (
                  <img src={positionBottom} alt="bottom icon" />
                )}
                <div>Bottom</div>
              </PositionButton>
            </CommonItemGrid>
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.coupon_code_text &&
        props.specificForm &&
        props.specificForm.coupon_code_text !== undefined && (
          <InputRow>
            <TextElementSection>
              <FormText>Coupon Code</FormText>
              <InputField
                type="search"
                icon="search"
                placeholder="Search Coupon"
                value={props.specificForm.coupon_code_text}
                error={props.saveErrors && props.saveErrors.coupon_code_text}
                onChange={(e) => searchCoupons(e.target.value)}
              />
            </TextElementSection>
            {props.selectedFields.coupon_code_background_color &&
              props.specificForm.coupon_code_background_color !== undefined && (
                <TextElementSection>
                  <FormText>Coupon code background color</FormText>
                  <ColorViewer
                    onClick={() =>
                      onChangeColorView("coupon_code_background_color")
                    }
                  >
                    <ColorViewerRound
                      color={props.specificForm.coupon_code_background_color}
                    />
                    <div>{props.specificForm.coupon_code_background_color}</div>
                    {showColorPicker === "coupon_code_background_color" && (
                      <ColorPallete
                        updateElementColor={onChangeFieldValue}
                        keyProp="coupon_code_background_color"
                        initialColor={
                          props.specificForm.coupon_code_background_color
                        }
                        closeColorView={onChangeColorView}
                      />
                    )}
                  </ColorViewer>
                </TextElementSection>
              )}

            {props.saveErrors && props.saveErrors.coupon_code_text && (
              <FormAlert noGap>{props.saveErrors.coupon_code_text}</FormAlert>
            )}
            {!props.isLoadingCoupon && props.discountCoupons.length === 0 ? (
              <NoDiscountBox>
                Generate your Shopify Discount code first. Go to the Discount
                Page to generate a new one.
              </NoDiscountBox>
            ) : (
              <CouponsTable>
                {props.isLoadingCoupon ? (
                  <ComponentCircleLoader padding="30px" />
                ) : (
                  <>
                    <div style={{ borderBottom: `1px solid #BFCFE0` }} />
                    <CouponsTableCell header>Coupon Code</CouponsTableCell>
                    <CouponsTableCell header>Coupon Type</CouponsTableCell>
                    <CouponsTableCell header>Coupon Value</CouponsTableCell>
                    {activeCouponSearch
                      ? filteredCouponList.map((coupon, index) => (
                          <React.Fragment key={index}>
                            <CouponRadioButton
                              selected={
                                props.selectedCoupon === coupon.coupon_title
                              }
                              onClick={() =>
                                onSelectCoupon(
                                  "coupon_code_text",
                                  coupon.coupon_title
                                )
                              }
                            />
                            <CouponsTableCell>
                              {" "}
                              {coupon.coupon_title}{" "}
                            </CouponsTableCell>
                            <CouponsTableCell>
                              {" "}
                              {coupon.coupon_type}{" "}
                            </CouponsTableCell>
                            <CouponsTableCell>
                              {" "}
                              {coupon.value}{" "}
                            </CouponsTableCell>
                          </React.Fragment>
                        ))
                      : props.discountCoupons.map((coupon, index) => (
                          <React.Fragment key={index}>
                            <CouponRadioButton
                              selected={
                                props.selectedCoupon === coupon.coupon_title
                              }
                              onClick={() =>
                                onSelectCoupon(
                                  "coupon_code_text",
                                  coupon.coupon_title
                                )
                              }
                            />
                            <CouponsTableCell>
                              {" "}
                              {coupon.coupon_title}{" "}
                            </CouponsTableCell>
                            <CouponsTableCell>
                              {" "}
                              {coupon.coupon_type}{" "}
                            </CouponsTableCell>
                            <CouponsTableCell>
                              {" "}
                              {coupon.value}{" "}
                            </CouponsTableCell>
                          </React.Fragment>
                        ))}
                  </>
                )}
              </CouponsTable>
            )}
          </InputRow>
        )}

      {props.selectedFields &&
        props.selectedFields.collect_user_email !== undefined &&
        props.specificForm &&
        props.specificForm.collect_user_email !== undefined && (
          <>
            <RoundSelectText paddingTop="10px">
              <CheckBox
                selected={collect_user_email_enable}
                onClick={() => onChangeCollectUserEmail()}
              >
                <img src={tick} alt="tick" />
              </CheckBox>
              <FormText margin="0">Collect user email</FormText>
            </RoundSelectText>

            {collect_user_email_enable && (
              <>
                <CommonItemGrid columns="auto auto" gap="20px">
                  {props.selectedFields.collect_user_email_backgroud_color &&
                    props.specificForm.collect_user_email_backgroud_color !==
                      undefined && (
                      <TextElementSection>
                        <FormText>Button Background Color</FormText>
                        <ColorViewer
                          onClick={() =>
                            onChangeColorView(
                              "collect_user_email_backgroud_color"
                            )
                          }
                        >
                          <ColorViewerRound
                            color={
                              props.specificForm
                                .collect_user_email_backgroud_color
                            }
                          />
                          <div>
                            {
                              props.specificForm
                                .collect_user_email_backgroud_color
                            }
                          </div>
                          {showColorPicker ===
                            "collect_user_email_backgroud_color" && (
                            <ColorPallete
                              updateElementColor={onChangeFieldValue}
                              keyProp="collect_user_email_backgroud_color"
                              initialColor={
                                props.specificForm
                                  .collect_user_email_backgroud_color
                              }
                              closeColorView={onChangeColorView}
                            />
                          )}
                        </ColorViewer>
                      </TextElementSection>
                    )}
                  {props.selectedFields.collect_user_email_hover_color &&
                    props.specificForm.collect_user_email_hover_color !==
                      undefined && (
                      <TextElementSection>
                        <FormText>Button Hover Color</FormText>
                        <ColorViewer
                          onClick={() =>
                            onChangeColorView("collect_user_email_hover_color")
                          }
                        >
                          <ColorViewerRound
                            color={
                              props.specificForm.collect_user_email_hover_color
                                .backgroundColor
                            }
                          />
                          <div>
                            {
                              props.specificForm.collect_user_email_hover_color
                                .backgroundColor
                            }
                          </div>
                          {showColorPicker ===
                            "collect_user_email_hover_color" &&
                            props.specificForm.collect_user_email_hover_color
                              .backgroundColor && (
                              <ColorPallete
                                updateElementColor={onChangeFieldValue}
                                keyProp="collect_user_email_hover_color"
                                initialColor={
                                  props.specificForm
                                    .collect_user_email_hover_color
                                    .backgroundColor
                                    ? props.specificForm
                                        .collect_user_email_hover_color
                                        .backgroundColor
                                    : props.specificForm
                                        .collect_user_email_hover_color
                                }
                                closeColorView={onChangeColorView}
                              />
                            )}
                        </ColorViewer>
                      </TextElementSection>
                    )}
                </CommonItemGrid>
                <InputRow>
                  <TextElementSection>
                    <FormText>Button Text</FormText>
                    <InputField
                      type="text"
                      value={props.specificForm.collect_user_email_button_text}
                      error={
                        props.saveErrors &&
                        props.saveErrors.collect_user_email_button_text
                      }
                      onChange={(e) =>
                        onChangeFieldValue(
                          "collect_user_email_button_text",
                          e.target.value
                        )
                      }
                    />
                  </TextElementSection>
                  {props.selectedFields.collect_user_email_button_text_color &&
                    props.specificForm.collect_user_email_button_text_color !==
                      undefined && (
                      <TextElementSection>
                        <FormText>Button Text Color</FormText>

                        <ColorViewer
                          onClick={() =>
                            onChangeColorView(
                              "collect_user_email_button_text_color"
                            )
                          }
                        >
                          <ColorViewerRound
                            color={
                              props.specificForm
                                .collect_user_email_button_text_color
                            }
                          />
                          <div>
                            {
                              props.specificForm
                                .collect_user_email_button_text_color
                            }
                          </div>
                          {showColorPicker ===
                            "collect_user_email_button_text_color" && (
                            <ColorPallete
                              updateElementColor={onChangeFieldValue}
                              keyProp="collect_user_email_button_text_color"
                              initialColor={
                                props.specificForm
                                  .collect_user_email_button_text_color
                              }
                              closeColorView={onChangeColorView}
                            />
                          )}
                        </ColorViewer>
                      </TextElementSection>
                    )}
                  {props.saveErrors &&
                    props.saveErrors.collect_user_email_button_text && (
                      <FormAlert noGap>
                        {props.saveErrors.collect_user_email_button_text}
                      </FormAlert>
                    )}
                </InputRow>
              </>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.promote_page_link !== undefined &&
        props.specificForm &&
        props.specificForm.promote_page_link !== undefined && (
          <>
            <RoundSelectText paddingTop="10px">
              <CheckBox
                selected={promote_page_enable}
                onClick={() => onChangePromoteLink()}
              >
                <img src={tick} alt="tick" />
              </CheckBox>
              <FormText margin="0">Promote Page</FormText>
            </RoundSelectText>

            {promote_page_enable && (
              <NestedFormContainer>
                <FormText>Link</FormText>
                <CommonItemGrid columns="minmax(320px, 380px)">
                  <InputField
                    type="text"
                    value={props.specificForm.promote_page_link}
                    placeholder="Enter link you want to promote"
                    error={
                      props.saveErrors &&
                      (props.saveErrors.promote_page_link ||
                        props.saveErrors.promote_page_link_not_valid)
                    }
                    onChange={(e) =>
                      onChangeFieldValue("promote_page_link", e.target.value)
                    }
                  />
                  {props.saveErrors &&
                    props.saveErrors.promote_page_link && (
                      <FormAlert noGap>
                        {props.saveErrors.promote_page_link}
                      </FormAlert>
                    )}
                  {props.saveErrors &&
                    props.saveErrors.promote_page_link_not_valid && (
                      <FormAlert noGap>
                        {props.saveErrors.promote_page_link_not_valid}
                      </FormAlert>
                    )}
                </CommonItemGrid>
                <CommonItemGrid columns="repeat(2, auto)" gap="20px">
                  <TextElementSection>
                    <FormText>Button Text</FormText>
                    <InputField
                      type="text"
                      value={props.specificForm.promote_page_button_text}
                      placeholder="enter button text"
                      error={
                        props.saveErrors &&
                        (props.saveErrors.promote_page_button_text ||
                          props.saveErrors.promote_page_button_text_not_valid)
                      }
                      onChange={(e) =>
                        onChangeFieldValue(
                          "promote_page_button_text",
                          e.target.value
                        )
                      }
                    />
                    {props.saveErrors &&
                      props.saveErrors.promote_page_button_text && (
                        <FormAlert noGap>
                          {props.saveErrors.promote_page_button_text}
                        </FormAlert>
                      )}
                    {props.saveErrors &&
                      props.saveErrors.promote_page_button_text_not_valid && (
                        <FormAlert noGap>
                          {props.saveErrors.promote_page_button_text_not_valid}
                        </FormAlert>
                      )}
                  </TextElementSection>
                  <TextElementSection>
                    <FormText>Button Text Color</FormText>

                    <ColorViewer
                      onClick={() =>
                        onChangeColorView("promote_page_button_text_color")
                      }
                    >
                      <ColorViewerRound
                        color={
                          props.specificForm.promote_page_button_text_color
                        }
                      />
                      <div>
                        {props.specificForm.promote_page_button_text_color}
                      </div>
                      {showColorPicker === "promote_page_button_text_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="promote_page_button_text_color"
                          initialColor={
                            props.specificForm.promote_page_button_text_color
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                  <TextElementSection>
                    <FormText>Button Background Color</FormText>

                    <ColorViewer
                      onClick={() =>
                        onChangeColorView(
                          "promote_page_button_background_color"
                        )
                      }
                    >
                      <ColorViewerRound
                        color={
                          props.specificForm
                            .promote_page_button_background_color
                        }
                      />
                      <div>
                        {
                          props.specificForm
                            .promote_page_button_background_color
                        }
                      </div>
                      {showColorPicker ===
                        "promote_page_button_background_color" && (
                        <ColorPallete
                          updateElementColor={onChangeFieldValue}
                          keyProp="promote_page_button_background_color"
                          initialColor={
                            props.specificForm
                              .promote_page_button_background_color
                          }
                          closeColorView={onChangeColorView}
                        />
                      )}
                    </ColorViewer>
                  </TextElementSection>
                  <TextElementSection>
                    <FormText>Button Hover Color</FormText>
                    <ColorViewer
                      onClick={() =>
                        onChangeColorView("promote_page_button_hover_color")
                      }
                    >
                      <ColorViewerRound
                        color={
                          props.specificForm.promote_page_button_hover_color
                            .backgroundColor
                        }
                      />
                      <div>
                        {
                          props.specificForm.promote_page_button_hover_color
                            .backgroundColor
                        }
                      </div>
                      {showColorPicker === "promote_page_button_hover_color" &&
                        props.specificForm.promote_page_button_hover_color
                          .backgroundColor && (
                          <ColorPallete
                            updateElementColor={onChangeFieldValue}
                            keyProp="promote_page_button_hover_color"
                            initialColor={
                              props.specificForm.promote_page_button_hover_color
                                .backgroundColor
                                ? props.specificForm
                                    .promote_page_button_hover_color
                                    .backgroundColor
                                : props.specificForm
                                    .promote_page_button_hover_color
                            }
                            closeColorView={onChangeColorView}
                          />
                        )}
                    </ColorViewer>
                  </TextElementSection>
                </CommonItemGrid>
              </NestedFormContainer>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.add_countdown_time !== undefined &&
        props.specificForm &&
        props.specificForm.add_countdown_time !== undefined && (
          <>
            <RoundSelectText paddingTop="10px">
              <CheckBox
                selected={add_countdown_enable}
                onClick={() => onChangeCountDown()}
              >
                <img src={tick} alt="tick" />
              </CheckBox>
              <FormText margin="0">Add Countdown</FormText>
            </RoundSelectText>
            {add_countdown_enable && (
              <NestedFormContainer>
                <FormText>Timer Countdown</FormText>
                <TimeInputContainer>
                  <TextElementSection>
                    <FormText>Date</FormText>
                    <DatePicker
                      id="CountDownDate"
                      date={timeCountDate}
                      onDateChange={onTimeCountDateChange}
                      dateDisplayFormat={"DD/MM/YYYY"}
                      placeholder={"Select date"}
                    />
                  </TextElementSection>
                  <TextElementSection>
                    <FormText>Time</FormText>
                    <TimePicker
                      hour12Format
                      value={timeCountHour}
                      onChange={(value) => onTimeCountHourChange(value)}
                    />
                  </TextElementSection>
                  <TextElementSection>
                    <FormText>GMT</FormText>
                    <InputField
                      type="number"
                      value={GMT}
                      onChange={(e) => onChangeGMT(e.target.value)}
                      style={{ width: `70px` }}
                    />
                  </TextElementSection>
                </TimeInputContainer>
              </NestedFormContainer>
            )}
          </>
        )}

      {props.selectedFields &&
        props.selectedFields.social_buttons_facebook_url !== undefined &&
        props.specificForm &&
        props.specificForm.social_buttons_facebook_url !== undefined && (
          <>
            <RoundSelectText paddingTop="10px">
              <CheckBox
                selected={social_buttons_enable}
                onClick={() =>
                  set_social_buttons_enable(!social_buttons_enable)
                }
              >
                <img src={tick} alt="tick" />
              </CheckBox>
              <FormText margin="0">Social Buttons</FormText>
            </RoundSelectText>

            {social_buttons_enable && (
              <NestedFormContainer>
                <FormText>Social buttons settings</FormText>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_facebook_url}
                    placeholder="enter facebook url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors.social_buttons_facebook_url
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_facebook_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_facebook_url")}
                    alt="social_buttons_facebook_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors.social_buttons_facebook_url && (
                      <FormAlert>
                        {props.saveErrors.social_buttons_facebook_url}
                      </FormAlert>
                    )}
                </SocialURLContainer>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_twitter_url}
                    placeholder="enter twitter url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors.social_buttons_twitter_url
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_twitter_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_twitter_url")}
                    alt="social_buttons_twitter_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors.social_buttons_twitter_url && (
                      <FormAlert>
                        {props.saveErrors.social_buttons_twitter_url}
                      </FormAlert>
                    )}
                </SocialURLContainer>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_youtube_url}
                    placeholder="enter youtube channel url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors["social_buttons_youtube_url"]
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_youtube_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_youtube_url")}
                    alt="social_buttons_youtube_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors["social_buttons_youtube_url"] && (
                      <FormAlert>
                        {props.saveErrors["social_buttons_youtube_url"]}
                      </FormAlert>
                    )}
                </SocialURLContainer>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_instagram_url}
                    placeholder="enter instagram url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors["social_buttons_instagram_url"]
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_instagram_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_instagram_url")}
                    alt="social_buttons_instagram_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors["social_buttons_instagram_url"] && (
                      <FormAlert>
                        {props.saveErrors["social_buttons_instagram_url"]}
                      </FormAlert>
                    )}
                </SocialURLContainer>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_linkedin_url}
                    placeholder="enter linkedin url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors["social_buttons_linkedin_url"]
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_linkedin_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_linkedin_url")}
                    alt="social_buttons_linkedin_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors["social_buttons_linkedin_url"] && (
                      <FormAlert>
                        {props.saveErrors["social_buttons_linkedin_url"]}
                      </FormAlert>
                    )}
                </SocialURLContainer>
                <SocialURLContainer>
                  <InputField
                    type="text"
                    value={props.specificForm.social_buttons_pinterest_url}
                    placeholder="enter pinterest url"
                    style={{ paddingLeft: `45px` }}
                    error={
                      props.saveErrors &&
                      props.saveErrors["social_buttons_pinterest_url"]
                    }
                    onChange={(e) =>
                      onChangeSocialLinkURL(
                        "social_buttons_pinterest_url",
                        e.target.value
                      )
                    }
                  />
                  <InputIcon
                    src={selectSocialIcon("social_buttons_pinterest_url")}
                    alt="social_buttons_pinterest_url"
                  />
                  {props.saveErrors &&
                    props.saveErrors["social_buttons_pinterest_url"] && (
                      <FormAlert>
                        {props.saveErrors["social_buttons_pinterest_url"]}
                      </FormAlert>
                    )}
                </SocialURLContainer>
              </NestedFormContainer>
            )}
          </>
        )}
    </FormContainer>
  );
};

const mapStateToProps = (state) => ({
  template_type: state.route.template_type,
  template_type_number: state.route.template_type_number,
  campaign_id: state.route.campaign_id,
  createdCampaign: state.campaign.createdCampaign,
  callback: state.campaign.callback,
  campaignViaId: state.campaign.campaignViaId,
  selectedTemplate: state.template.selectedTemplate,
  selectedFields: state.template.selectedFields,
  isLoadingCoupon: state.countries.isLoadingCoupon,
  templateTitle: state.template.templateTitle,
  templateFont: state.template.templateFont,
  specificForm: state.template.specificForm,
  saveErrors: state.template.saveErrors,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateTemplateType: (params) => dispatch(updateTemplateType(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  getCampaignViaId: (params) => dispatch(getCampaignViaId(params)),
  updateTemplate: (params) => dispatch(updateTemplate(params)),
  updateField: (params) => dispatch(updateField(params)),
  updateTemplateTitle: (params) => dispatch(updateTemplateTitle(params)),
  updateTemplateFont: (params) => dispatch(updateTemplateFont(params)),
  updateSpecificForm: (params) => dispatch(updateSpecificForm(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
