import {
    SUBSCRIPTION,
    WELCOME_MESSAGES,
    PROMO_CODES,
    SOCIAL_BOOSTS,
    SOCIAL_DISCOUNTS,
    SMART_DISCOUNTS,
  } from "../constants/templateConstants";

export const typeModifyForRoute = (type) => {
    let typeName = type.substr(0, type.indexOf("_"));
    const types = {
      SUBSCRIPTION: SUBSCRIPTION,
      WELCOMEMESSAGE: WELCOME_MESSAGES,
      PROMOCODE: PROMO_CODES,
      SMART_DISCOUNT: SMART_DISCOUNTS,
      SOCIALDISCOUNT: SOCIAL_DISCOUNTS,
      SOCIALBOOST: SOCIAL_BOOSTS,
    };
    return types[typeName];
  };