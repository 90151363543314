import styled from "styled-components";

export const CommonText = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `auto 0`)};
  color: ${(props) => (props.name ? `#2F394E` : `rgba(47, 57, 78, 0.7)`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `14px`)};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
`;

export const BackLinkSection = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 20px;
  justify-content: start;
  align-content: center;
  cursor: pointer;
`;

export const ArrowBox = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 6px;
  background-color: white;
  width: ${(props) => (props.size ? props.size : `40px`)};
  height: ${(props) => (props.size ? props.size : `40px`)};
  border: 1px solid #e4e7eb;
`;

