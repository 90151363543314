import React, { useState, useEffect } from "react";
import { ElementContainer,StyledButton } from "../../styledComponent/editor";

const Submit = ({ element }) => {

  return (
    <React.Fragment key={element.id}>
        <ElementContainer
          style={
            element.justifyContent &&
            element.justifyContent !== "" && {
              justifyContent: element.justifyContent,
            }
          }
        >
          <StyledButton hover={element.hover} style={element.style}>{element.text}</StyledButton>
        </ElementContainer>
    </React.Fragment>
  );
};

export default Submit;