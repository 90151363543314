import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateRouteState, updateCampaignId } from "../actions/actionRoute";
import axios from "axios";
import {
  updateTemplate,
  updateField,
  updateSpecificForm,
  updateSaveErrors,
  UpdateToInitialState,
  updateDisplayRules,
  isFormChange,
} from "../actions/actionTemplate";
import { setCampaignStateToInitial } from "../actions/actionCampaign";
import {
  BASE_LIST_VIEW,
  CAMPAIGN_LIST,
  LEADS_LIST,
  DASHBOARD,
} from "../constants/componentName";
import WarningModal from "./WarningModal";
import { CommonColorButton, CommonText } from "../styledComponent/common";
import {
  NavbarMainContainer,
  NavbarInnerContainer,
  NavbarItem,
  NavbarTitle,
  UpgradePlanBar,
} from "../styledComponent/navbar";
import segmentlyLogoWhite from "../assets/icons/segmently-logo-white.svg";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { CircleLoader } from "react-spinners";

const Navbar = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [routeTo, setRouteTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const pricingPlanCall = async () => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/yearly_starter/?shop=${props.shop}`.toString();
      setLoading(true);
      const response = await axios.get(URL);
      if (response && response.status && response.status === 200) {
        window.open(response.data.url, "_top");
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  const onChangeRoute = (route) => {
    if (props.isFormChanged) {
      setRouteTo(route);
      popupToggle();
    } else {
      async function sequence() {
        try {
          await props.updateCampaignId({ data: null });
          await props.UpdateToInitialState();
          await props.setCampaignStateToInitial();
          setTimeout(() => {
            props.handleUpdateRouteState({ data: route });
          }, 100);
        } catch (error) {
          console.error(error);
        }
      }
      sequence();
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onLeave = () => {
    popupToggle();
    async function sequence() {
      try {
        await props.updateCampaignId({ data: null });
        await props.UpdateToInitialState();
        await props.setCampaignStateToInitial();
        setTimeout(() => {
          props.handleUpdateRouteState({ data: routeTo });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };

  return (
    <>
    <NavbarMainContainer>
      {popupOpen && (
        <WarningModal
          text="Are you sure you want to leave this page?"
          description={`Hey there! It looks like you are in the middle of your template customisation and
               you haven’t saved your content. Save before you go!`}
          buttonText="Leave"
          toggle={popupToggle}
          onAccept={onLeave}
        />
      )}
      <img
        src={segmentlyLogoWhite}
        alt="segmently logo"
        width="100%"
        style={{ cursor: `pointer` }}
        onClick={() => onChangeRoute(DASHBOARD)}
      />
      <NavbarInnerContainer>
        <NavbarItem
          isActive={props.route === DASHBOARD}
          onClick={() => onChangeRoute(DASHBOARD)}
        >
          Dashboard
        </NavbarItem>
        <NavbarItem
          isActive={props.route === BASE_LIST_VIEW}
          onClick={() => onChangeRoute(BASE_LIST_VIEW)}
        >
          Templates
        </NavbarItem>
        <NavbarItem
          isActive={props.route === CAMPAIGN_LIST}
          onClick={() => onChangeRoute(CAMPAIGN_LIST)}
        >
          Campaign
        </NavbarItem>
        <NavbarItem
          isActive={props.route === LEADS_LIST}
          onClick={() => onChangeRoute(LEADS_LIST)}
        >
          Leads
        </NavbarItem>
      </NavbarInnerContainer>
    </NavbarMainContainer>
    {(props.route === DASHBOARD && !loading && props.plan !== "Yearly Starter") &&
      (<UpgradePlanBar>
        <CommonText name>Your current plan: MONTHLY STARTER.</CommonText>
        <CommonColorButton onClick={()=> pricingPlanCall()} >Upgrade to yearly</CommonColorButton>
      </UpgradePlanBar>
    )}
    {(props.route === DASHBOARD && loading ) && <ComponentCircleLoader />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  isFormChanged: state.template.isFormChanged,
  route: state.route.link,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  isFormChange: (params) => dispatch(isFormChange(params)),
  UpdateToInitialState: (params) => dispatch(UpdateToInitialState(params)),
  setCampaignStateToInitial: (params) =>
    dispatch(setCampaignStateToInitial(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
