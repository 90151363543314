import React from "react";
import { connect } from "react-redux";
import { updateRouteState } from "./actions/actionRoute";
import BaseListView from "./components/BaseListView";
import Template1 from "./components/subscription/template1/Template1";
import BaseDetailView from "./components/BaseDetailView";
import CampaignList from "./components/CampaignList";
import Leads from "./components/Leads";
import CampaignDetails from "./components/CampaignDetails";
import Dashboard from "./components/Dashboard";

const Routes = (props) => {

    const components = {
        BaseListView : BaseListView,
        BaseDetailView: BaseDetailView,
        CampaignList: CampaignList,
        Template1: Template1,
        Leads: Leads,
        CampaignDetails: CampaignDetails,
        Dashboard: Dashboard,
    }

    let RenderedComponent = components[props.route.link];

  return (
      <RenderedComponent />
  )
};

const mapStateToProps = (state) => ({
    route: state.route,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  });


  export default connect(mapStateToProps, mapDispatchToProps)(Routes);