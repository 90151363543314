import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import {
  CommonText,
  InputFieldNum,
  FormAlert,
} from "../../styledComponent/common";
import {
  ToggleButton,
  ToggleButtonRound,
  ChangableCompContainer,
} from "../../styledComponent/step2";

const ShowPopupAfter = (props) => {
  const onChangePopupAfter = () => {
    props.updateDisplayRules({
      data: { show_popup_after: !props.displayRules.show_popup_after },
    });
  };

  const onChangePopupAfterSeconds = (value) => {
    props.updateDisplayRules({ data: { popup_after_seconds: value } });

    let errors = { ...props.saveErrors };
    delete errors.popup_after_seconds;
    props.updateSaveErrors({ data: errors });
  };

  return (
    <ChangableCompContainer>
      <div>
        <CommonText>
          Show only to the visitors who are on site as long as
          <InputFieldNum
            type="number"
            min="0"
            value={props.displayRules.popup_after_seconds}
            placeholder="3"
            error={props.saveErrors && props.saveErrors.popup_after_seconds}
            onChange={(e) => onChangePopupAfterSeconds(e.target.value)}
          />
          Seconds
        </CommonText>
      </div>
      {props.saveErrors && props.saveErrors.popup_after_seconds && (
        <FormAlert>{props.saveErrors.popup_after_seconds}</FormAlert>
      )}
    </ChangableCompContainer>
  );
};

const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPopupAfter);
