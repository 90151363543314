import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateRouteState,
  updateTemplateType,
  updateCampaignId,
} from "../actions/actionRoute";
import { CAMPAIGN_LIST, BASE_LIST_VIEW } from "../constants/componentName";
import Chart from "react-apexcharts";
import {
  getCampaignList,
  setCampaignStateToInitial,
  updateCampaign,
  deleteCampaign,
  getDashboardFilter,
  getDateFilterData,
} from "../actions/actionCampaign";
import DateRangePicker from "../commonComponents/dateRange/ReactDateRangePicker";
import { BASE_DETAIL_VIEW, CAMPAIGN_Details } from "../constants/componentName";
import {
  SUBSCRIPTION,
  WELCOME_MESSAGES,
  PROMO_CODES,
  SOCIAL_BOOSTS,
  SOCIAL_DISCOUNTS,
  SMART_DISCOUNTS,
} from "../constants/templateConstants";
import DeletePopup from "./DeletePopup";
import {
  CampaignListContainer,
  Card,
  LabelNumber,
  LabelNameCard,
  Chartdiv,
  Button,
  MainDiv,
  Labeldate,
  WhiteDiv,
} from "../styledComponent/campaignDetails";
import {
  CommonTitle,
  CommonText,
  CommonColorButton,
  CommonItemGrid,
} from "../styledComponent/common";
import {
  UpperButtonContainer,
  DeviceCard,
  DashboardCampaignRow,
  UpperSection,
  CommonTextList,
} from "../styledComponent/campaignList";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { ToggleButton, ToggleButtonRound } from "../styledComponent/step2";
import SUBSCRIPTION_TYPE_2 from "../assets/template_images/template-1.svg";
import SUBSCRIPTION_TYPE_4 from "../assets/template_images/subscribe_4.png";
import SUBSCRIPTION_TYPE_1 from "../assets/template_images/subscribe_1.png";
import SUBSCRIPTION_TYPE_3 from "../assets/template_images/subscribe_3.png";
import SUBSCRIPTION_TYPE_5 from "../assets/template_images/subscribe_5.png";

import WELCOME_MESSAGES_TYPE_3 from "../assets/template_images/template-2.svg";
import WELCOME_MESSAGES_TYPE_1 from "../assets/template_images/wm_smart_topbar_1.png";
import WELCOME_MESSAGES_TYPE_2 from "../assets/template_images/wm_smart_topbar_2.png";
import WELCOME_MESSAGES_TYPE_4 from "../assets/template_images/wm_smart_topbar_4.png";
import WELCOME_MESSAGES_TYPE_5 from "../assets/template_images/wm_smart_topbar_5.png";

import PROMO_CODES_TYPE_4 from "../assets/template_images/template-3.svg";
import PROMO_CODES_TYPE_5 from "../assets/template_images/promo_codes_5.png";
import PROMO_CODES_TYPE_1 from "../assets/template_images/promo_codes_1.png";
import PROMO_CODES_TYPE_2 from "../assets/template_images/promo_codes_2.png";
import PROMO_CODES_TYPE_3 from "../assets/template_images/promo_codes_3.png";
import web from "../assets/icons/web.svg";
import noDataimg from "../assets/img/artwork.svg";
import mobile from "../assets/icons/mobile.svg";
import moment from "moment";

const Dashboard = ({
  isLoading,
  campaignList,
  filteredData,
  handleUpdateRouteState,
  updateTemplateType,
  updateCampaignId,
  getCampaignList,
  dashboardFilterInfo,
  getDashboardFilter,
  getDateFilterData,
  setCampaignStateToInitial,
  isDeleteLoading,
  route,
  template_type,
  campaign_id,
  updateCampaign,
  deleteCampaign,
  callback,
}) => {
  let dumy = {
    series: [
      {
        name: "north",
        data: [
          {
            x: 2 / 2 / 2012,
            y: 322,
          },
          {
            x: 3 / 2 / 2012,
            y: 324,
          },
          {
            x: 4 / 2 / 2012,
            y: 329,
          },
          {
            x: 5 / 2 / 2012,
            y: 342,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 250,
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },

      title: {
        text: "Impressions",
        align: "left",
        style: {
          fontSize: "14px",
          textAlign: "left",
          font: "normal normal medium 14px/21px Poppins",
          color: "#1F2939",
          opacity: 1,
        },
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 4,
        floating: false,

        labels: {
          style: {
            colors: "#8e8da4",
          },
          offsetY: -7,
          offsetX: 0,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 0.5,
      },
      tooltip: {
        x: {
          format: "yyyy",
        },
        fixed: {
          enabled: false,
          position: "topRight",
        },
      },
      grid: {
        yaxis: {
          lines: {
            offsetX: -30,
          },
        },
        padding: {
          left: 20,
        },
      },
    },
  };
  const [state, setState] = useState(dumy);
  const [selectItems, setSelectedItems] = useState([]);
  const [conversion, setConversion] = useState(0);
  const [startDate, setstartDate] = useState(moment().add(-6, "days"));
  const [endDate, setendDate] = useState(moment());
  const [isUpdated, setIsUpdated] = useState(false);
  useEffect(() => {
    getCampaignList();
    getDashboardFilter();
    callDateFilter();
  }, []);

  useEffect(() => {
    // console.log("campaignList :", campaignList);
    updateList();
  }, [campaignList.results]);

  useEffect(() => {
    if (
      campaignList.results &&
      campaignList.results.length > 0 &&
      dashboardFilterInfo &&
      dashboardFilterInfo.length > 0
    ) {
      let graphList = [];
      dashboardFilterInfo.map((element, index) => {
        graphList.push({
          x: moment(element.date).format("MM / DD / YYYY"),
          y: element.impression,
        });
      });
      dumy.series[0].data = graphList;
      setState(dumy);
      // console.log(graphList);
    }
  }, [dashboardFilterInfo, campaignList.results]);
  const updateList = () => {
    if (campaignList.results && campaignList.results.length > 0) {
      let tempArray = [];
      if (campaignList.results.length > 4) {
        for (
          let i = campaignList.results.length - 1;
          i >= campaignList.results.length - 4;
          i--
        ) {
          tempArray.push(campaignList.results[i]);
        }
      } else {
        for (let i = campaignList.results.length - 1; i >= 0; i--) {
          tempArray.push(campaignList.results[i]);
        }
      }
      let totalImpressions = 0;
      let totalConversions = 0;
      campaignList.results.forEach((element) => {
        totalImpressions += parseInt(element.impression);
        totalConversions += parseInt(element.conversion);
      });
      let convertPercent;
      if (totalImpressions) {
        convertPercent = (
          parseFloat(totalConversions / totalImpressions) * 100
        ).toFixed(2);
      } else {
        convertPercent = 0;
      }
      setConversion(convertPercent);
      setSelectedItems(tempArray);
    }
  };
  const typeModifyForRoute = (type) => {
    let typeName = type.substr(0, type.indexOf("_"));
    const types = {
      SUBSCRIPTION: SUBSCRIPTION,
      WELCOMEMESSAGE: WELCOME_MESSAGES,
      PROMOCODE: PROMO_CODES,
      SMART_DISCOUNT: SMART_DISCOUNTS,
      SOCIALDISCOUNT: SOCIAL_DISCOUNTS,
      SOCIALBOOST: SOCIAL_BOOSTS,
    };
    return types[typeName];
  };
  const changeStatus = async (id, type, status) => {
    const payload = {
      campaign_id: id,
      template_type: typeModifyForRoute(type),
      status: status === `active` ? `inactive` : `active`,
    };
    try {
      await updateCampaign(payload);
      //setIsUpdated(true);
      let tempArray = [];
      let obj = { ...selectItems };
      var size = Object.keys(obj).length;
      for (let i = 0; i < size; i++) {
        if (obj[i].id === id) {
          obj[i].status = obj[i].status === `active` ? `inactive` : `active`;
        }
        tempArray.push(obj[i]);
      }
      setSelectedItems([]);
      setTimeout(() => {
        setSelectedItems(tempArray);
      }, 100);

      //getCampaignList();

      // updateList()
    } catch (error) {
      console.error(error);
    }
  };
  const callDateFilter = () => {
    let payload = {
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    // payload.fromDate = startDate.format("YYYY-MM-DD");
    // payload.toDate = endDate.format("YYYY-MM-DD");
    getDateFilterData(payload);

    // console.log("filter Responce",filteredData);
  };
  const onDetailClick = (id, type) => {
    let conventionalType = typeModifyForRoute(type);
    async function sequence() {
      try {
        await updateTemplateType({ data: conventionalType });
        await updateCampaignId({ data: id });
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: CAMPAIGN_Details });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };
  const onChangeRoute = (route) => {
    async function sequence() {
      try {
        await updateCampaignId({ data: null });
        //await UpdateToInitialState();
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: route });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };

  const changeDate = (val) => {
    setstartDate(val.startDate);
    setendDate(val.endDate);
  };
  const selectLastWeek = () => {
    setstartDate(moment().add(-6, "days"));
    setendDate(moment());
  };
  const selectLastMonth = () => {
    setstartDate(moment().add(-1, "months"));
    setendDate(moment());
  };
  const getImage = (val) => {
    switch (val) {
      case "SUBSCRIPTION_TYPE_1":
        return SUBSCRIPTION_TYPE_1;
      case "SUBSCRIPTION_TYPE_2":
        return SUBSCRIPTION_TYPE_2;
      case "SUBSCRIPTION_TYPE_3":
        return SUBSCRIPTION_TYPE_3;
      case "SUBSCRIPTION_TYPE_4":
        return SUBSCRIPTION_TYPE_4;
      case "SUBSCRIPTION_TYPE_5":
        return SUBSCRIPTION_TYPE_5;
      case "WELCOMEMESSAGE_TYPE_1":
        return WELCOME_MESSAGES_TYPE_1;
      case "WELCOMEMESSAGE_TYPE_2":
        return WELCOME_MESSAGES_TYPE_2;
      case "WELCOMEMESSAGE_TYPE_3":
        return WELCOME_MESSAGES_TYPE_3;
      case "WELCOMEMESSAGE_TYPE_4":
        return WELCOME_MESSAGES_TYPE_4;
      case "WELCOMEMESSAGE_TYPE_5":
        return WELCOME_MESSAGES_TYPE_5;
      case "PROMOCODE_TYPE_1":
        return PROMO_CODES_TYPE_1;
      case "PROMOCODE_TYPE_2":
        return PROMO_CODES_TYPE_2;
      case "PROMOCODE_TYPE_3":
        return PROMO_CODES_TYPE_3;
      case "PROMOCODE_TYPE_4":
        return PROMO_CODES_TYPE_4;
      case "PROMOCODE_TYPE_5":
        return PROMO_CODES_TYPE_5;
      default:
        return SUBSCRIPTION_TYPE_1;
    }
    //return val;
  };
  return (
    <div>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MainDiv colum={"58% 40%"} gap={"2%"} marginB={"20px 20px 0px 20px"}>
            <div>
              <LabelNumber size={"24px"}>Welcome</LabelNumber>
            </div>
            <MainDiv
              colum={"40% 58%"}
              gap={"2%"}
              justify={"end"}
              marginB={"10px 0px"}
            >
              <Labeldate>Select Date Range</Labeldate>
              <DateRangePicker
                style={{
                  width: "125%",
                  zindex: 12,
                }}
                startDate={startDate ? startDate : null}
                startDateId="custom-date-range-start-date"
                endDate={endDate ? endDate : null}
                endDateId="custom-date-range-start-date"
                onDatesChange={changeDate}
                dateDisplayFormat="DD/MM/YYYY"
                //onDateSelect={props.onDateSelect}
                selectLastWeek={selectLastWeek}
                selectLastMonth={selectLastMonth}
                dateApply={callDateFilter}
              />
            </MainDiv>
          </MainDiv>
          {campaignList.results &&
          campaignList &&
          campaignList.results.length > 0 ? (
            <MainDiv marginB={"0px"}>
              <CampaignListContainer>
                <Card color={"#FAF8F3"}>
                  <div>
                    <LabelNumber>
                      {filteredData && filteredData.impression
                        ? filteredData.impression
                        : 0}
                    </LabelNumber>
                    <LabelNameCard>Impressions</LabelNameCard>
                  </div>
                </Card>
                <Card color={"#E5F5FA"}>
                  <div>
                    <LabelNumber>
                      {filteredData && filteredData.conversion
                        ? filteredData.conversion
                        : 0}
                    </LabelNumber>
                    <LabelNameCard>Conversions</LabelNameCard>
                  </div>
                </Card>
                <Card color={"#EEFCEF"}>
                  <div>
                    <LabelNumber>
                      {filteredData && filteredData.rate
                        ? filteredData.rate
                        : 0}
                      %
                    </LabelNumber>
                    <LabelNameCard>Conversion Rate</LabelNameCard>
                  </div>
                </Card>
                <Card color={"#F0F4FF"}>
                  <div>
                    <LabelNumber>
                      {filteredData && filteredData.web_device_count
                        ? filteredData.web_device_count
                        : 0}
                    </LabelNumber>
                    <LabelNameCard>Impression from web</LabelNameCard>
                  </div>
                  <img src={web} alt="web" width="90%" />
                </Card>
                <Card color={"#FAEFE5"}>
                  <div>
                    <LabelNumber>
                      {filteredData && filteredData.mobile_device_count
                        ? filteredData.mobile_device_count
                        : 0}
                    </LabelNumber>
                    <LabelNameCard>Impression from mobile</LabelNameCard>
                  </div>
                  <img src={mobile} alt="web" width="90%" />
                </Card>
              </CampaignListContainer>

              <Chartdiv>
                <div>
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={250}
                  />
                </div>
              </Chartdiv>

              <MainDiv
                colum={"auto auto"}
                justifyContent="space-between"
                responsiveValue={"730px"}
                responsive={"100%"}
              >
                <CommonTitle>Recent Campaigns</CommonTitle>
                <UpperButtonContainer>
                  <Button
                    fColor={"#1F2939"}
                    hColor={"#4D7CFF"}
                    font={"normal normal medium 10px/21px Poppins"}
                    onClick={() => onChangeRoute(CAMPAIGN_LIST)}
                  >
                    <label>All Campaigns</label>
                  </Button>
                  <Button
                    background={"#4D7CFF"}
                    fColor={"#FFFFFF"}
                    font={"normal normal medium 10px/21px Poppins"}
                    onClick={() => onChangeRoute(BASE_LIST_VIEW)}
                  >
                    <label>New Campaign</label>
                  </Button>
                </UpperButtonContainer>
              </MainDiv>
              {selectItems && selectItems.length > 0 && (
                <MainDiv gap="15px" marginB="10px 20px 40px" >
                  <DashboardCampaignRow>
                    <CommonTextList fontWeight="500">Campaigns</CommonTextList>
                    <div />
                    <CommonTextList fontWeight="500">Status</CommonTextList>
                    <CommonTextList fontWeight="500">Devices</CommonTextList>
                    <CommonTextList fontWeight="500">
                      Impressions
                    </CommonTextList>
                    <CommonTextList fontWeight="500">Conversion</CommonTextList>
                    <CommonTextList fontWeight="500">
                      Conversion Rate
                    </CommonTextList>
                  </DashboardCampaignRow>
                  {selectItems &&
                    selectItems.length > 0 &&
                    selectItems.map((item, index) => (
                      <DashboardCampaignRow key={index}>
                        <div>
                          <img
                            key={item.id}
                            src={getImage(item.type)}
                            alt="template-view"
                            width="100px"
                          />
                        </div>
                        <CommonItemGrid style={{marginRight: `auto`}}>
                          <CommonTextList
                            onClick={() => onDetailClick(item.id, item.type)}
                            fontSize="18px"
                            color="#4D7CFF"
                            fontWeight="500"
                            style={{
                              wordWrap: `break-word`,
                              minWidth: `50px`,
                              cursor: "pointer",
                            }}
                          >
                            {item.campaign_name}
                          </CommonTextList>
                          <CommonTextList>
                            {" "}
                            Created on{" "}
                            {moment(item.created_at).format(
                              "MMMM D, YYYY"
                            )}{" "}
                          </CommonTextList>
                        </CommonItemGrid>
                        <ToggleButton
                          active={item.status === `active`}
                          onClick={() =>
                            changeStatus(item.id, item.type, item.status)
                          }
                        >
                          <ToggleButtonRound
                            active={item.status === `active`}
                          />
                        </ToggleButton>
                        <UpperButtonContainer gap="10px" device={true}>
                          <DeviceCard>
                            <img src={web} alt="web" width="100%" />
                            <div>{item.web_device_count}</div>
                          </DeviceCard>
                          <DeviceCard>
                            <img src={mobile} alt="mobile" width="100%" />
                            <div>{item.mobile_device_count}</div>
                          </DeviceCard>
                        </UpperButtonContainer>
                        <CommonText> {item.impression} </CommonText>
                        <CommonText>{item.conversion}</CommonText>
                        <CommonText>{item.rate}%</CommonText>
                      </DashboardCampaignRow>
                    ))}
                </MainDiv>
              )}
            </MainDiv>
          ) : (
            <div>
              <CampaignListContainer>
                <Card color={"#FAF8F3"}>
                  <div>
                    <WhiteDiv wi={"48px"} hei={"13px"} margin={"13px"} />
                    <WhiteDiv wi={"86px"} hei={"10px"} margin={"4px"} />
                  </div>
                </Card>
                <Card color={"#E5F5FA"}>
                  <div>
                    <WhiteDiv wi={"48px"} hei={"13px"} margin={"13px"} />
                    <WhiteDiv wi={"86px"} hei={"10px"} margin={"4px"} />
                  </div>
                </Card>
                <Card color={"#EEFCEF"}>
                  <div>
                    <WhiteDiv wi={"48px"} hei={"13px"} margin={"13px"} />
                    <WhiteDiv wi={"86px"} hei={"10px"} margin={"4px"} />
                  </div>
                </Card>
                <Card color={"#F0F4FF"}>
                  <div>
                    <WhiteDiv wi={"48px"} hei={"13px"} margin={"13px"} />
                    <WhiteDiv wi={"86px"} hei={"10px"} margin={"4px"} />
                  </div>
                </Card>
                <Card color={"#FAEFE5"}>
                  <div>
                    <WhiteDiv wi={"48px"} hei={"13px"} margin={"13px"} />
                    <WhiteDiv wi={"86px"} hei={"10px"} margin={"4px"} />
                  </div>
                </Card>
              </CampaignListContainer>
              <MainDiv justify={"center"} mar={"60px"}>
                <img src={noDataimg} alt="" />
              </MainDiv>
              <MainDiv justify={"center"} mar={"20px"}>
                <LabelNameCard>
                  You do not have any campaign running now. Start campaign!
                </LabelNameCard>
              </MainDiv>
              <MainDiv justify={"center"} mar={"10px"}>
                <CommonColorButton
                  onClick={() => onChangeRoute(BASE_LIST_VIEW)}
                >
                  Start Campaign
                </CommonColorButton>
              </MainDiv>
              <MainDiv justify={"center"} mar={"200px"}></MainDiv>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaign.isLoading,
  isDeleteLoading: state.campaign.isDeleteLoading,
  route: state.route.link,
  template_type: state.route.template_type,
  campaign_id: state.route.campaign_id,
  campaignList: state.campaign.campaignList,
  dashboardFilterInfo: state.campaign.dashboardFilterInfo,
  filteredData: state.campaign.filteredData,
  deletedCampaignCallback: state.campaign.deletedCampaignCallback,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateTemplateType: (params) => dispatch(updateTemplateType(params)),
  getCampaignList: (params) => dispatch(getCampaignList(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  updateCampaign: (params) => dispatch(updateCampaign(params)),
  getDashboardFilter: (params) => dispatch(getDashboardFilter(params)),
  getDateFilterData: (params) => dispatch(getDateFilterData(params)),
  setCampaignStateToInitial: (params) =>
    dispatch(setCampaignStateToInitial(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
