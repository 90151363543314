import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { updateRouteState, updateCampaignId } from "../actions/actionRoute";
import {
  updateTemplate,
  updateField,
  updateSpecificForm,
  updateSaveErrors,
  UpdateToInitialState,
  updateDisplayRules,
  isFormChange,
} from "../actions/actionTemplate";
import {
  createCampaign,
  updateCampaign,
  getCampaignViaId,
} from "../actions/actionCampaign";
import { getDiscountCoupons } from "../actions/actionCountries";
import { BASE_LIST_VIEW, CAMPAIGN_LIST } from "../constants/componentName";
import { PROMO_CODES } from "../constants/templateConstants";
import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";
import deepCopy from "../utils/deepCopy";
import allTemplates from "./allTemplates";
import ViewTemplate from "./step1/ViewTemplate";

import WarningModal from "./WarningModal";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import BackButtonComponent from "./commonComponents/BackButtonComponent";
import changeRoute from "./commonComponents/changeRoute";
import deepEquality from "../utils/deepEquality";

import {
  BaseDetailContainer,
  TabContainer,
  FormTemplateContainer,
  LeftContainer,
  RightContainer,
  TemplateContainer,
  DomainContainer,
  TabButtonSection,
  TabIconBox,
} from "../styledComponent/baseDetailView";
import {
  CommonColorButton,
  CommonItemGrid,
  CommonWhButton,
  CommonText,
} from "../styledComponent/common";
import {
  PreviewContainer,
  PreviewPopup,
  CrossIcon,
} from "../styledComponent/baseListVIew";

import styleLayoutIcon from "../assets/icons/style-layout.png";
import styleLayoutIconActive from "../assets/icons/style-layout-active.png";
import displayRulesIcon from "../assets/icons/display-rules.png";
import displayRulesIconActive from "../assets/icons/display-rules-active.png";
import crossBlack from "../assets/icons/cross_black.svg";

const BaseDetailView = (props) => {
  const [isStep1, setIsStep1] = useState(true);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [specificFormCopy, setSpecificFormCopy] = useState(null);
  const displayRulesInitial = {
    campaign_platforms: "both",
    show_up_option: "EVERY_VISIT",
    exit_intent_popup: "ENABLE",
    show_location: false,
    country_ids: [],
    show_popup_after: false,
    popup_after_seconds: null,
    show_use_behaviour: false,
    show_to_returning_visitor: "NEW_VISITOR",
    show_to_logged_in_user: "LOGGED_IN",
    show_when_user_adds_prod_to_cart: false,
    show_popup_when_scroll: false,
  };
  const [displayRulesCopy, setDisplayRulesCopy] = useState({
    ...displayRulesInitial,
  });
  const [selectedCoupon, setSelectedCoupon] = useState("");

  const [popupOpen, setPopupOpen] = useState(false);
  let allTemplatesCopy = deepCopy(allTemplates);

  const onChangeRouteToBack = () => {
    if (props.isFormChanged) {
      popupToggle();
    } else {
      props.updateCampaignId({ data: null });
      props.UpdateToInitialState();
      setTimeout(() => {
        changeRoute(
          props.campaign_id ? CAMPAIGN_LIST : BASE_LIST_VIEW,
          props.handleUpdateRouteState
        );
      }, 100);
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onLeave = () => {
    popupToggle();
    props.updateCampaignId({ data: null });
    props.UpdateToInitialState();
    setTimeout(() => {
      changeRoute(
        props.campaign_id ? CAMPAIGN_LIST : BASE_LIST_VIEW,
        props.handleUpdateRouteState
      );
    }, 100);
  };

  const isValidHttpUrl = (string, maxLength) => {
    let url;
    if (string.length < maxLength) {
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    } else {
      return false;
    }
  };

  const modifyType = () => {
    let typeName = props.template_type_number.substr(
      0,
      props.template_type_number.indexOf("_")
    );
    let typeNumber = props.template_type_number.substr(
      props.template_type_number.indexOf("_") + 1
    );
    const types = {
      subscription: "SUBSCRIPTION",
      welcomemessages: "WELCOMEMESSAGE",
      promocodes: "PROMOCODE",
      smartdiscounts: "SMART_DISCOUNT",
      socialdiscounts: "SOCIALDISCOUNT",
      socialboosts: "SOCIALBOOST",
    };
    let modifiedtype = `${types[typeName]}_TYPE_${typeNumber}`;
    return modifiedtype;
  };

  const isValidDiscountCoupon = (coupon) => {
    props.discountCoupons.map((discountCoupon) => {
      if (`${discountCoupon.coupon_title}` === `${coupon}`) {
        return true;
      }
    });
    return false;
  };

  const checkError = () => {
    let formIsValid = true;
    let saveErrors = {};
    if (props.specificForm && Object.keys(props.specificForm).length > 0) {
      if (
        props.specificForm.campaign_name &&
        props.specificForm.campaign_name.trim().length > 80
      ) {
        saveErrors.campaign_name = "maximum 80 characters";
        formIsValid = false;
      }
      if (
        props.specificForm.title &&
        props.specificForm.title.trim().length > 80
      ) {
        saveErrors.title = "maximum 80 characters";
        formIsValid = false;
      }
      if (
        props.specificForm.description &&
        props.specificForm.description.trim().length > 360
      ) {
        saveErrors.description = "maximum 360 characters";
        formIsValid = false;
      }
      if (
        props.specificForm.button_text &&
        props.specificForm.button_text.trim().length > 40
      ) {
        saveErrors.button_text = "maximum 40 characters";
        formIsValid = false;
      }
      if (
        props.specificForm.discount_label &&
        props.specificForm.discount_label.trim() === ""
      ) {
        saveErrors.discount_label = "Discount label can not be empty";
        formIsValid = false;
      }

      if (
        props.selectedFields.collect_user_email_button_text &&
        Object.keys(props.selectedFields.collect_user_email_button_text)
          .length > 1 &&
        props.specificForm.collect_user_email_button_text.trim().length > 40
      ) {
        saveErrors.collect_user_email_button_text = "maximum 40 characters";
        formIsValid = false;
      }

      if (
        props.selectedFields.promote_page_link &&
        Object.keys(props.selectedFields.promote_page_link).length > 1 &&
        props.specificForm.promote_page_link.trim() === ""
      ) {
        saveErrors.promote_page_link = "Promote page link can not be empty";
        formIsValid = false;
      }
      if (
        props.specificForm.promote_page_link &&
        props.specificForm.promote_page_link.trim() !== "" &&
        !isValidHttpUrl(props.specificForm.promote_page_link.trim(), 200)
      ) {
        saveErrors.promote_page_link_not_valid = "Promote page link not valid";
        formIsValid = false;
      }
      if (
        props.selectedFields.promote_page_button_text &&
        Object.keys(props.selectedFields.promote_page_button_text).length > 1 &&
        props.specificForm.promote_page_button_text.trim() === ""
      ) {
        saveErrors.promote_page_button_text =
          "Promote page button text can not be empty";
        formIsValid = false;
      }
      if (
        props.selectedFields.promote_page_button_text &&
        Object.keys(props.selectedFields.promote_page_button_text).length > 1 &&
        props.specificForm.promote_page_button_text.trim().length > 40
      ) {
        saveErrors.promote_page_button_text_not_valid = "maximum 40 characters";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_facebook_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_facebook_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_facebook_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_twitter_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_twitter_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_twitter_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_youtube_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_youtube_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_youtube_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_linkedin_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_linkedin_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_linkedin_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_instagram_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_instagram_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_instagram_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.social_buttons_pinterest_url &&
        !isValidHttpUrl(
          props.specificForm.social_buttons_pinterest_url.trim(),
          1000
        )
      ) {
        saveErrors.social_buttons_pinterest_url = "link not valid";
        formIsValid = false;
      }
      if (
        props.specificForm.coupon_code_text !== undefined &&
        selectedCoupon.trim() === ""
      ) {
        saveErrors.coupon_code_text = "coupon can not be empty";
        formIsValid = false;
      }
      if (
        props.displayRules &&
        parseInt(props.displayRules.popup_after_seconds) < 0
      ) {
        saveErrors.popup_after_seconds = "invalid time";
        formIsValid = false;
      }
      if (
        props.displayRules &&
        (parseInt(props.displayRules.scroll_percentage) < 0 ||
          parseInt(props.displayRules.scroll_percentage) > 100)
      ) {
        saveErrors.scroll_percentage = "invalid percentage input";
        formIsValid = false;
      }
      props.updateSaveErrors({ data: saveErrors });
      return formIsValid;
    }
  };

  const handleCampaign = (param) => {
    if (checkError()) {
      let tempDisplayRules = { ...props.displayRules };
      tempDisplayRules.id && delete tempDisplayRules.id;
      const payload = {
        template_type: props.template_type,
        segmently_settings: tempDisplayRules,
        form: props.selectedFields,
        template: props.selectedTemplate,
        font: null,
        campaign_name: props.specificForm.campaign_name,
      };

      async function sequence() {
        try {
          if (props.campaign_id) {
            await props.updateCampaign({
              ...payload,
              campaign_id: props.campaign_id,
              status: param,
            });
          } else {
            if (param && param === "draft") {
              payload.status = "draft";
            }
            await props.createCampaign({
              ...payload,
              type: modifyType(),
            });
          }
          setIsAPICalled(true);
          await props.updateCampaignId({ data: null });
          await props.UpdateToInitialState();
        } catch (error) {
          console.error(error);
        }
      }
      sequence();
    }
  };

  const selectPreview = (value) => {
    setIsPreviewActive(value);
  };

  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  const selectCoupon = (value) => {
    setSelectedCoupon(value);
  };

  useEffect(() => {
    if (props.campaign_id && props.template_type) {
      const payload = {
        template_type: props.template_type,
        campaign_id: props.campaign_id,
      };
      props.getCampaignViaId(payload);
    } else if (props.template_type_number) {
      props.updateField({
        data: { ...allTemplatesCopy[props.template_type_number].form },
      });
      props.updateTemplate({
        data: {
          ...allTemplatesCopy[props.template_type_number].templateObject,
        },
      });
    }
    if (props.template_type && props.template_type === PROMO_CODES) {
      props.getDiscountCoupons();
    }
  }, []);

  // useEffect(() => {
  //   console.log(props.discountCoupons);
  // }, [props.discountCoupons]);

  const setDomain = () => {
    let domain = "";
    if (typeof window !== "undefined") {
      const url = window.location.href;
      domain = url.substring(
        url.indexOf("shop=") + "shop=".length,
        url.lastIndexOf("&")
      );
    }
    return domain;
  };

  const useUnload = (fn) => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
      cb.current = fn;
    }, [fn]);

    useEffect(() => {
      const onUnload = (...args) => cb.current?.(...args);
      window.addEventListener("beforeunload", onUnload);

      return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
  };

  useUnload((e) => {
    e.preventDefault();
    if (!props.isFormChanged) {
      return undefined;
    } else {
      e.returnValue = "";
    }
  });

  // (e || window.event).returnValue = "";

  useEffect(() => {
    if (props.campaignViaId) {
      props.updateField({ data: props.campaignViaId.form });
      props.updateTemplate({ data: props.campaignViaId.template });
      setDisplayRulesCopy({ ...props.campaignViaId.segmently_settings });
      props.updateDisplayRules({
        data: props.campaignViaId.segmently_settings,
      });
    }
  }, [props.campaignViaId]);

  useEffect(() => {
    if (!props.isFormChanged) {
      if (props.specificForm !== null && specificFormCopy === null) {
        setSpecificFormCopy({ ...props.specificForm });
      }
      if (
        props.specificForm !== null &&
        specificFormCopy !== null &&
        JSON.stringify(props.specificForm) !== JSON.stringify(specificFormCopy)
      ) {
        props.isFormChange({ data: true });
      }
      if (props.specificForm && props.specificForm.coupon_code_text) {
        selectCoupon(props.specificForm.coupon_code_text);
      }
    }
  }, [props.specificForm]);

  useEffect(() => {
    if (
      !props.isFormChanged &&
      !deepEquality(props.displayRules, displayRulesInitial) &&
      !deepEquality(props.displayRules, displayRulesCopy)
    ) {
      // console.log(props.displayRules);
      // console.log(displayRulesCopy);
      props.isFormChange({ data: true });
    }
  }, [props.displayRules]);

  useEffect(() => {
    if (isAPICalled && (props.createdCampaign || props.callback)) {
      props.handleUpdateRouteState({ data: CAMPAIGN_LIST });
    }
  }, [props.createdCampaign, props.callback]);

  return (
    <div>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <BaseDetailContainer>
          {popupOpen && (
            <WarningModal
              text="Are you sure you want to leave this page?"
              description={`Hey there! It looks like you are in the middle of your template customisation and
               you haven’t saved your content. Save before you go!`}
              buttonText="Leave"
              toggle={popupToggle}
              onAccept={onLeave}
            />
          )}
          {isPreviewActive && (
            <PreviewContainer
              justifyContent={
                props.selectedTemplate &&
                props.selectedTemplate.isFullWidth &&
                "initial"
              }
              alignContent={
                props.selectedTemplate &&
                props.selectedTemplate.position !== undefined &&
                props.selectedTemplate.position
              }
              onClick={() => selectPreview(false)}
            >
              <PreviewPopup
                marginBottom={
                  props.selectedTemplate &&
                  props.selectedTemplate.position === "end" &&
                  `-20px`
                }
                minWidth="auto"
              >
                <div onClick={(e) => onPopupClick(e)}>
                  {props.selectedTemplate && (
                    <ViewTemplate
                      justifyContent={
                        props.selectedTemplate &&
                        props.selectedTemplate.isFullWidth &&
                        "initial"
                      }
                      template={props.selectedTemplate}
                    />
                  )}
                </div>
                <CrossIcon
                  position={
                    props.selectedTemplate &&
                    props.selectedTemplate.position &&
                    props.selectedTemplate.position
                  }
                >
                  <img src={crossBlack} alt="cross-icon" width="100%" />
                </CrossIcon>
              </PreviewPopup>
            </PreviewContainer>
          )}

          <LeftContainer>
            <BackButtonComponent
              onClick={() => onChangeRouteToBack()}
              subTitle="Go back to templates"
            />

            <TabContainer>
              <TabButtonSection onClick={() => setIsStep1(true)}>
                <TabIconBox isStep1={isStep1}>
                  {isStep1 ? (
                    <img
                      src={styleLayoutIconActive}
                      alt="style-layout-active"
                      width="100%"
                    />
                  ) : (
                    <img
                      src={styleLayoutIcon}
                      alt="style-layout"
                      width="100%"
                    />
                  )}
                </TabIconBox>
                <CommonText color={isStep1 ? "#4D7CFF" : "rgba(31,41,57,0.6)"}>
                  Style & Layout
                </CommonText>
                <CommonText
                  color={isStep1 ? "#4D7CFF" : "rgba(31,41,57,0.6)"}
                  fontWeight="500"
                  fontSize="18px"
                >
                  {">"}
                </CommonText>
              </TabButtonSection>

              <TabButtonSection onClick={() => setIsStep1(false)}>
                <TabIconBox isStep1={!isStep1}>
                  {isStep1 ? (
                    <img
                      src={displayRulesIcon}
                      alt="display-rules"
                      width="100%"
                    />
                  ) : (
                    <img
                      src={displayRulesIconActive}
                      alt="display-rules-active"
                      width="100%"
                    />
                  )}
                </TabIconBox>
                <CommonText color={!isStep1 ? "#4D7CFF" : "rgba(31,41,57,0.6)"}>
                  Display Rules
                </CommonText>
                <CommonText
                  color={!isStep1 ? "#4D7CFF" : "rgba(31,41,57,0.6)"}
                  fontWeight="500"
                  fontSize="18px"
                >
                  {">"}
                </CommonText>
              </TabButtonSection>
            </TabContainer>
            <FormTemplateContainer>
              {isStep1 ? (
                <Step1
                  selectCoupon={selectCoupon}
                  selectedCoupon={selectedCoupon}
                  discountCoupons={props.discountCoupons}
                />
              ) : (
                <Step2 />
              )}
            </FormTemplateContainer>
          </LeftContainer>
          <div style={{ background: `#F0F4F8` }}>
            <RightContainer>
              <CommonItemGrid
                columns="auto auto"
                justifyContent="space-between"
                alignContent="start"
              >
                <div />
                <CommonItemGrid columns="1fr 1fr" gap="20px">
                  <CommonWhButton
                    borderColor="#4D7CFF"
                    color="#4D7CFF"
                    onClick={() => handleCampaign("draft")}
                  >
                    Save
                  </CommonWhButton>
                  <CommonColorButton
                    onClick={() => handleCampaign("active")}
                    maxwidth="180px"
                  >
                    Publish
                  </CommonColorButton>
                </CommonItemGrid>
              </CommonItemGrid>
              <TemplateContainer>
                <DomainContainer>
                  <CommonText>
                    {props.selectedTemplate && `https://${setDomain()}`}
                  </CommonText>
                </DomainContainer>
                {props.selectedTemplate && (
                  <ViewTemplate
                    justifyContent={
                      props.selectedTemplate &&
                      props.selectedTemplate.isFullWidth &&
                      "initial"
                    }
                    template={props.selectedTemplate}
                  />
                )}
                <CommonWhButton
                  borderColor="#4D7CFF"
                  color="#4D7CFF"
                  style={{ margin: `0 auto` }}
                  onClick={() => selectPreview(true)}
                >
                  Preview
                </CommonWhButton>
              </TemplateContainer>
            </RightContainer>
          </div>
        </BaseDetailContainer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaign.isLoading,
  template_type: state.route.template_type,
  template_type_number: state.route.template_type_number,
  campaign_id: state.route.campaign_id,
  campaignViaId: state.campaign.campaignViaId,
  createdCampaign: state.campaign.createdCampaign,
  callback: state.campaign.callback,
  discountCoupons: state.countries.discountCoupons,
  selectedTemplate: state.template.selectedTemplate,
  selectedFields: state.template.selectedFields,
  templateTitle: state.template.templateTitle,
  templateFont: state.template.templateFont,
  displayRules: state.template.displayRules,
  isFormChanged: state.template.isFormChanged,
  specificForm: state.template.specificForm,
  saveErrors: state.template.saveErrors,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  getCampaignViaId: (params) => dispatch(getCampaignViaId(params)),
  updateTemplate: (params) => dispatch(updateTemplate(params)),
  updateField: (params) => dispatch(updateField(params)),
  updateSpecificForm: (params) => dispatch(updateSpecificForm(params)),
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  createCampaign: (params) => dispatch(createCampaign(params)),
  updateCampaign: (params) => dispatch(updateCampaign(params)),
  getDiscountCoupons: (params) => dispatch(getDiscountCoupons(params)),
  isFormChange: (params) => dispatch(isFormChange(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
  UpdateToInitialState: (params) => dispatch(UpdateToInitialState(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseDetailView);
