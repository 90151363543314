import styled from "styled-components";

export const CardContainer = styled.div`
    display : grid;
    grid-template-columns: repeat(2, minmax(180px, 220px));
    padding: 4em 2em;
    gap: 30px;
    justify-content: center;
    background-color: white;
    text-align: center;
`
export const PricingCard = styled.div`
    display: grid;
    grid-template-columns: auto;
    gap: 40px;
    justify-items: center;
    padding: 30px;
    border: 1px solid #bfcfe0;
    border-radius: 10px;
    text-align: center;
`
export const CommonAnchor = styled.a`
    text-decoration: none;
`