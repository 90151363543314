import styled from "styled-components";

export const TimePickerWrapper = styled.div`
  height: 40px;
  padding: 4px 2px;
  border: ${({ error }) => `1px solid ${error ? "#f15762" : "#e4e7eb"}`};
  border-radius: 4px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const TimePickerContainer = styled.div`
  color: #2f394e;
  width: max-content;
  padding: 0;
  display: flex;
  margin: 0;
  align-items: center;
  height: 100%;
  width: auto;
  justify-content: center;
`;

export const MobileInputContainer = styled.div``;
export const MobileInput = styled.input`
  background: transparent;
`;

export const TimeInputContainer = styled.div`
  position: relative;
  align-items: center;
  height: 100%;
  max-width: 28px;
  display: flex;
  :nth-child(2) {
    max-width: 24px;
  }
  :nth-child(1)::after {
    content: ":";
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    top: -1px;
    margin: 0;
  }
`;

export const TimeInput = styled.input`
  position: relative;
  margin: 0;
  padding: 0 1px;
  border-radius: 2px;
  color: #2f394e;
  background: transparent;
  width: max-content;
  min-width: 24px;
  text-align: center;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  height: 85%;
  align-items: center;
  &::placeholder {
    font-weight: inherit;
    font-size: 16px;
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    caret-color: transparent;
    background-color: #def2ef;
  }
  &::selection {
    background: transparent;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const TimePickerAmPmInput = styled.input`
  min-width: unset;
  width: 26px;
  border-radius: 2px;
  padding: 0 1px;
  height: 85%;
  border: none;
  text-align: center;
  &:focus {
    caret-color: transparent;
    background-color: #def2ef;
    outline: none;
  }
`;
