import React from "react";
import { DateRangePicker } from "react-dates";
import datePickerIcon from "../../assets/icons/calendar.svg";

const CustomInputIcon = () => {
  return <img style={{ marginTop: "-5px" }} src={datePickerIcon} alt=""/>;
};

const ArrowIcon = () => (
  <div style={{ paddingTop: "8px", color: "#808080" }}>to</div>
);

class ReactDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }
  
  getCalendarInfo = () => {
    const onApply = () => {
      this.props.onDateSelect();
      this.setState({ focusedInput: null });
    };

    return (
      <div className="date-range-footer">
        <div>
          <div
            className="date-range-picker-button"
            onClick={this.props.selectLastWeek}
          >
            Last 7 days
          </div>
          <div
            className="date-range-picker-button"
            onClick={this.props.selectLastMonth}
          >
            Last 30 days
          </div>
        </div>
        <div>
          <div
            className="date-range-picker-button"
            style={{
              padding: "2px 15px",
              background: `#4D7CFF`,
              color: `white`,
              border: `none`,
            }}
            onClick={this.props.dateApply}
          >
            Apply
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      startDate,
      startDateId,
      endDate,
      endDateId,
      onDatesChange,
      dateDisplayFormat,
    } = this.props;
    return (
      <DateRangePicker
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={({ startDate, endDate }) =>
          onDatesChange({ startDate, endDate })
        }
        focusedInput={this.state.focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        showDefaultInputIcon={false}
        customInputIcon={<CustomInputIcon />}
        inputIconPosition="after"
        isOutsideRange={() => false}
        displayFormat={dateDisplayFormat}
        renderCalendarInfo={() => this.getCalendarInfo()}
        calendarInfoPosition="bottom"
        keepOpenOnDateSelect
        customArrowIcon={<ArrowIcon />}
        minimumNights={0}
        hideKeyboardShortcutsPanel
      />
    );
  }
}

export default ReactDateRangePicker;
