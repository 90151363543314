import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateRouteState,
  updateTemplateType,
  updateCampaignId,
} from "../actions/actionRoute";
import {
  getCampaignList,
  setCampaignStateToInitial,
  updateCampaign,
  deleteCampaign,
} from "../actions/actionCampaign";
import {
  BASE_DETAIL_VIEW,
  CAMPAIGN_Details,
  BASE_LIST_VIEW,
} from "../constants/componentName";
import { UpdateToInitialState } from "../actions/actionTemplate";
import DeletePopup from "./DeletePopup";
import { typeModifyForRoute } from "../utils/typeModify";

import {
  CommonTitle,
  CommonText,
  InputField,
  CommonColorButton,
  CommonWhButton,
  IconTextButton,
  CommonCheckBox,
  CommonItemGrid,
  PaginationContainer,
  CountBox,
  EmptyBox,
  PaginationBlock,
  NoDataContainer,
} from "../styledComponent/common";
import {
  CampaignListContainer,
  CampaignRow,
  UpperSection,
  UpperButtonContainer,
  DeviceCard,
  CommonTextList,
  DraftSpan,
  DraftEditContainer,
} from "../styledComponent/campaignList";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { ToggleButton, ToggleButtonRound } from "../styledComponent/step2";
import Filter from "../assets/icons/filter.svg";
import tick from "../assets/icons/tick_2.svg";
import templateView from "../assets/template_images/subscription/hover/subscribe-template-1.png";
import web from "../assets/icons/web.svg";
import mobile from "../assets/icons/mobile.svg";
import noDataArtWork from "../assets/img/artwork.svg";
import moment from "moment";
import SUBSCRIPTION_TYPE_2 from "../assets/template_images/template-1.svg";
import SUBSCRIPTION_TYPE_4 from "../assets/template_images/subscribe_4.png";
import SUBSCRIPTION_TYPE_1 from "../assets/template_images/subscribe_1.png";
import SUBSCRIPTION_TYPE_3 from "../assets/template_images/subscribe_3.png";
import SUBSCRIPTION_TYPE_5 from "../assets/template_images/subscribe_5.png";

import WELCOME_MESSAGES_TYPE_3 from "../assets/template_images/template-2.svg";
import WELCOME_MESSAGES_TYPE_1 from "../assets/template_images/wm_smart_topbar_1.png";
import WELCOME_MESSAGES_TYPE_2 from "../assets/template_images/wm_smart_topbar_2.png";
import WELCOME_MESSAGES_TYPE_4 from "../assets/template_images/wm_smart_topbar_4.png";
import WELCOME_MESSAGES_TYPE_5 from "../assets/template_images/wm_smart_topbar_5.png";

import PROMO_CODES_TYPE_4 from "../assets/template_images/template-3.svg";
import PROMO_CODES_TYPE_5 from "../assets/template_images/promo_codes_5.png";
import PROMO_CODES_TYPE_1 from "../assets/template_images/promo_codes_1.png";
import PROMO_CODES_TYPE_2 from "../assets/template_images/promo_codes_2.png";
import PROMO_CODES_TYPE_3 from "../assets/template_images/promo_codes_3.png";

const CampaignList = ({
  isLoading,
  isDeleteLoading,
  route,
  template_type,
  campaign_id,
  campaignList,
  handleUpdateRouteState,
  updateTemplateType,
  updateCampaignId,
  deletedCampaignCallback,
  getCampaignList,
  UpdateToInitialState,
  setCampaignStateToInitial,
  updateCampaign,
  deleteCampaign,
  callback,
}) => {
  const [selectItems, setSelectedItems] = useState([]);
  const [allSelect, setAllSelect] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pagesArray, setPagesArray] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [deleteCounter, setDeleteCounter] = useState(0);
  const numberPerPage = 30;

  useEffect(() => {
    getCampaignList();
  }, []);

  useEffect(() => {
    // console.log(campaignList);
    if (
      campaignList &&
      campaignList.results &&
      campaignList.results.length > 0
    ) {
      let tempArray = [];
      campaignList.results.forEach((element) => {
        tempArray.push({ id: "" });
      });
      setSelectedItems(tempArray);
    }
    if (campaignList && campaignList.count) {
      let pages = Math.ceil(campaignList.count / numberPerPage);
      let tempArr = [];
      for (let i = 1; i <= pages; i++) {
        tempArr.push(i);
      }
      setPagesArray(tempArr);
    }
  }, [campaignList]);

  useEffect(() => {
    if (
      campaignList &&
      campaignList.results &&
      selectItems.length > 0 &&
      selectItems.length === campaignList.results.length
    ) {
      let haveAnyId = false;
      selectItems.forEach((element) => {
        if (element.id !== "") {
          haveAnyId = true;
        }
      });
      setShowDelete(haveAnyId);
    }
  }, [selectItems]);

  useEffect(() => {
    if (deletedCampaignCallback && isDeleted) {
      popupToggle();
      if (selectedPage === 1) {
        getCampaignList();
      } else if (
        campaignList &&
        campaignList.results.length - deleteCounter >= 1
      ) {
        getCampaignList(selectedPage);
      } else if (
        campaignList &&
        campaignList.results.length - deleteCounter < 1
      ) {
        getCampaignList(selectedPage - 1);
        setSelectedPage(selectedPage - 1);
      }
      setAllSelect(false);
    }
  }, [deletedCampaignCallback]);

  useEffect(() => {
    if (isUpdated && callback) {
      if (selectedPage === 1) {
        getCampaignList();
      } else {
        getCampaignList(selectedPage);
      }
      setIsUpdated(false);
    }
  }, [callback]);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onEditClick = (id, type) => {
    let conventionalType = typeModifyForRoute(type);
    async function sequence() {
      try {
        await updateTemplateType({ data: conventionalType });
        await updateCampaignId({ data: id });
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: BASE_DETAIL_VIEW });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };
  const onDetailClick = (id, type) => {
    let conventionalType = typeModifyForRoute(type);
    async function sequence() {
      try {
        await updateTemplateType({ data: conventionalType });
        await updateCampaignId({ data: id });
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: CAMPAIGN_Details });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };

  const onChangeToTemplates = () => {
    async function sequence() {
      try {
        await updateCampaignId({ data: null });
        await UpdateToInitialState();
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: BASE_LIST_VIEW });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };

  const ToogleSelectAllCampaign = () => {
    setAllSelect(!allSelect);
    let tempArray = [...selectItems];
    if (allSelect) {
      tempArray.forEach((item) => {
        item.id = "";
      });
      setSelectedItems(tempArray);
    } else {
      campaignList.results.forEach((item, index) => {
        tempArray[index].id = item.id;
      });
      setSelectedItems(tempArray);
    }
  };

  const toggleSelectCampaign = (id, index) => {
    let tempArray = [...selectItems];
    tempArray[index].id === ""
      ? (tempArray[index].id = id)
      : (tempArray[index].id = "");
    setSelectedItems(tempArray);
  };

  const onDeleteCampaign = () => {
    selectItems.forEach((item, index) => {
      if (item && item.id) {
        let modifiedType = typeModifyForRoute(campaignList.results[index].type);
        deleteCampaign({
          template_type: modifiedType,
          campaign_id: item.id,
        });
        setIsDeleted(true);
        setShowDelete(false);
        setDeleteCounter((deleteCounter) => deleteCounter + 1);
      }
    });
  };

  const onChangePage = (value) => {
    setSelectedPage(value);
    if (value === 1) {
      getCampaignList();
    } else {
      getCampaignList(value);
    }
  };

  const changeStatus = async (id, type, status) => {
    const payload = {
      campaign_id: id,
      template_type: typeModifyForRoute(type),
      status: status === `active` ? `inactive` : `active`,
    };
    try {
      await updateCampaign(payload);
      setIsUpdated(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getImage = (val) => {
    switch (val) {
      case "SUBSCRIPTION_TYPE_1":
        return SUBSCRIPTION_TYPE_1;
      case "SUBSCRIPTION_TYPE_2":
        return SUBSCRIPTION_TYPE_2;
      case "SUBSCRIPTION_TYPE_3":
        return SUBSCRIPTION_TYPE_3;
      case "SUBSCRIPTION_TYPE_4":
        return SUBSCRIPTION_TYPE_4;
      case "SUBSCRIPTION_TYPE_5":
        return SUBSCRIPTION_TYPE_5;
      case "WELCOMEMESSAGE_TYPE_1":
        return WELCOME_MESSAGES_TYPE_1;
      case "WELCOMEMESSAGE_TYPE_2":
        return WELCOME_MESSAGES_TYPE_2;
      case "WELCOMEMESSAGE_TYPE_3":
        return WELCOME_MESSAGES_TYPE_3;
      case "WELCOMEMESSAGE_TYPE_4":
        return WELCOME_MESSAGES_TYPE_4;
      case "WELCOMEMESSAGE_TYPE_5":
        return WELCOME_MESSAGES_TYPE_5;
      case "PROMOCODE_TYPE_1":
        return PROMO_CODES_TYPE_1;
      case "PROMOCODE_TYPE_2":
        return PROMO_CODES_TYPE_2;
      case "PROMOCODE_TYPE_3":
        return PROMO_CODES_TYPE_3;
      case "PROMOCODE_TYPE_4":
        return PROMO_CODES_TYPE_4;
      case "PROMOCODE_TYPE_5":
        return PROMO_CODES_TYPE_5;
      default:
        return SUBSCRIPTION_TYPE_1;
    }
    //return val;
  };
  return (
    <div>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <UpperSection
            responsive={"100%"}
            responsiveValue={true}
            style={{ minHeight: `102px` }}
          >
            {selectItems &&
              campaignList &&
              campaignList.results &&
              selectItems.length === campaignList.results.length &&
              popupOpen && (
                <DeletePopup
                  type="campaign"
                  isOpen={popupOpen}
                  toggle={popupToggle}
                  isDeleteLoading={isDeleteLoading}
                  onDelete={onDeleteCampaign}
                />
              )}
            <CommonTitle>Your Created Campaigns</CommonTitle>
            <UpperButtonContainer
              showDelete={showDelete}
              responsiveValue={true}
            >
              {showDelete && (
                <CommonColorButton
                  bgColor="#FE5969"
                  onClick={() => popupToggle()}
                >
                  Delete
                </CommonColorButton>
              )}
              {/* <IconTextButton>
                <img src={Filter} alt="filter icon" /> <div>Filter</div>
              </IconTextButton> */}
              <CommonColorButton onClick={() => onChangeToTemplates()}>
                Start Campaign
              </CommonColorButton>
            </UpperButtonContainer>
          </UpperSection>
          <CampaignListContainer>
            {selectItems &&
              selectItems.length > 0 &&
              campaignList &&
              campaignList.results &&
              campaignList.results.length > 0 && (
                <CampaignRow>
                  <CommonCheckBox
                    selected={allSelect}
                    onClick={() => ToogleSelectAllCampaign()}
                  >
                    <img src={tick} alt="tick" />
                  </CommonCheckBox>
                  <CommonTextList fontWeight="500">Campaigns</CommonTextList>
                  <div />
                  <CommonTextList fontWeight="500">Status</CommonTextList>
                  <CommonTextList fontWeight="500">Devices</CommonTextList>
                  <CommonTextList fontWeight="500">Impressions</CommonTextList>
                  <CommonTextList fontWeight="500">Conversion</CommonTextList>
                  <CommonTextList fontWeight="500">
                    Conversion Rate
                  </CommonTextList>
                  <div />
                </CampaignRow>
              )}

            {campaignList &&
            campaignList.results &&
            campaignList.results.length > 0 &&
            selectItems.length === campaignList.results.length ? (
              campaignList.results.map((item, index) => (
                <CampaignRow key={index}>
                  <CommonCheckBox
                    selected={selectItems[index].id === item.id}
                    onClick={() => toggleSelectCampaign(item.id, index)}
                  >
                    <img src={tick} alt="tick" />
                  </CommonCheckBox>
                  <div>
                    <img
                      key={item.id}
                      src={getImage(item.type)}
                      alt="template-view"
                      width="100%"
                    />
                  </div>
                  <CommonItemGrid style={{ marginRight: `auto` }}>
                    <CommonText
                      onClick={() => onDetailClick(item.id, item.type)}
                      fontSize="18px"
                      color="#4D7CFF"
                      fontWeight="500"
                      style={{
                        wordWrap: `break-word`,
                        minWidth: `200px`,
                        cursor: "pointer",
                      }}
                    >
                      {item.campaign_name}
                      {item.status && item.status === `draft` && (
                        <DraftSpan>Draft</DraftSpan>
                      )}
                    </CommonText>
                    <CommonText>
                      {" "}
                      Created on{" "}
                      {moment(item.created_at).format("MMMM D, YYYY")}{" "}
                    </CommonText>
                  </CommonItemGrid>
                  {item.status !== "draft" ? (
                    <>
                      <ToggleButton
                        active={item.status === `active`}
                        onClick={() =>
                          changeStatus(item.id, item.type, item.status)
                        }
                      >
                        <ToggleButtonRound active={item.status === `active`} />
                      </ToggleButton>
                      <UpperButtonContainer gap="10px">
                        <DeviceCard>
                          <img src={web} alt="web" width="100%" />
                          <div>{item.web_device_count}</div>
                        </DeviceCard>
                        <DeviceCard>
                          <img src={mobile} alt="mobile" width="100%" />
                          <div>{item.mobile_device_count}</div>
                        </DeviceCard>
                      </UpperButtonContainer>
                      <CommonText> {item.impression} </CommonText>
                      <CommonText>{item.conversion}</CommonText>
                      <CommonText>
                        {item.rate === null ? `0` : item.rate}%
                      </CommonText>
                      <CommonColorButton
                        onClick={() => onEditClick(item.id, item.type)}
                      >
                        Edit
                      </CommonColorButton>
                    </>
                  ) : (
                    <DraftEditContainer>
                      <CommonColorButton
                        onClick={() => onEditClick(item.id, item.type)}
                      >
                        Edit
                      </CommonColorButton>
                    </DraftEditContainer>
                  )}
                </CampaignRow>
              ))
            ) : (
              <NoDataContainer>
                <img
                  src={noDataArtWork}
                  alt="no campaigns"
                  width="100%"
                  style={{ maxWidth: `300px` }}
                />
                <CommonText style={{ textAlign: `center` }}>
                  You do not have any campaign created, create one and get the
                  best out of Segmently.
                </CommonText>
                <CommonColorButton onClick={() => onChangeToTemplates()}>
                  Start Campaign
                </CommonColorButton>
              </NoDataContainer>
            )}
            {pagesArray.length > 1 && (
              <PaginationContainer>
                <CommonText fontSize="13px" fontWeight="500">
                  View leads per page
                </CommonText>
                <CountBox perPage={true}> {numberPerPage} </CountBox>
                <PaginationBlock>
                  {campaignList && campaignList.previous === null ? (
                    <EmptyBox />
                  ) : (
                    <CountBox
                      arrow={true}
                      onClick={() => onChangePage(selectedPage - 1)}
                    >
                      {"<"}
                    </CountBox>
                  )}
                  {pagesArray.map((element) => (
                    <CountBox
                      selected={selectedPage === element}
                      key={element}
                      onClick={() => onChangePage(element)}
                    >
                      {element}
                    </CountBox>
                  ))}
                  {campaignList && campaignList.next === null ? (
                    <EmptyBox />
                  ) : (
                    <CountBox
                      arrow={true}
                      onClick={() => onChangePage(selectedPage + 1)}
                    >
                      {">"}
                    </CountBox>
                  )}
                </PaginationBlock>
              </PaginationContainer>
            )}
          </CampaignListContainer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaign.isLoading,
  isDeleteLoading: state.campaign.isDeleteLoading,
  route: state.route.link,
  template_type: state.route.template_type,
  campaign_id: state.route.campaign_id,
  campaignList: state.campaign.campaignList,
  deletedCampaignCallback: state.campaign.deletedCampaignCallback,
  callback: state.campaign.callback,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateTemplateType: (params) => dispatch(updateTemplateType(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  getCampaignList: (params) => dispatch(getCampaignList(params)),
  updateCampaign: (params) => dispatch(updateCampaign(params)),
  deleteCampaign: (params) => dispatch(deleteCampaign(params)),
  UpdateToInitialState: (params) => dispatch(UpdateToInitialState(params)),
  setCampaignStateToInitial: (params) =>
    dispatch(setCampaignStateToInitial(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
