import {
  UPDATE_TEMPLATE,
  UPDATE_FIELD,
  UPDATE_TEMPLATE_TITLE,
  UPDATE_TEMPLATE_FONT,
  UPDATE_DISPLAY_RULES,
  UPDATE_SPECIFIC_FORM,
  UPDATE_SAVE_ERRORS,
  INITIAL_STATE,
  IS_FORM_CHANGED,
} from "../constants";

export const updateTemplate = (value) => {
  return {
    type: UPDATE_TEMPLATE,
    payload: value,
  };
};

export const updateField = (value) => {
  return {
    type: UPDATE_FIELD,
    payload: value,
  };
};

export const updateTemplateTitle = (value) => {
  return {
    type: UPDATE_TEMPLATE_TITLE,
    payload: value,
  };
};

export const updateTemplateFont = (value) => {
  return {
    type: UPDATE_TEMPLATE_FONT,
    payload: value,
  };
};

export const updateDisplayRules = (value) => {
  return {
    type: UPDATE_DISPLAY_RULES,
    payload: value,
  };
};

export const updateSpecificForm = (value) => {
  return {
    type: UPDATE_SPECIFIC_FORM,
    payload: value,
  };
};

export const updateSaveErrors = (value) => {
  return {
    type: UPDATE_SAVE_ERRORS,
    payload: value,
  };
};

export const UpdateToInitialState = () => {
  return {
    type: INITIAL_STATE,
  };
};

export function isFormChange(value) {
  return {
    type: IS_FORM_CHANGED,
    payload: value,
  };
} 