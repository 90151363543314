import styled from "styled-components";
import { keyframes } from "styled-components";
import backgroundLayout from "../assets/icons/template-background.svg";

export const BaseContainer = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  align-content: start;
  grid-gap: 40px;
  padding: 30px;
  background: #f0f4f8;
  min-height: 93vh;
`;
export const Title = styled.p`
  color: #000;
  font-weight: 500;
  font-size: 21px;
  border-bottom: 1px solid #e5e5e5;
  line-height: 35px;
  margin: 0;
`;
export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 25px;
  padding: 40px 0;
`;
export const TemplateCardText = styled.div`
  color: #686868;
  padding: 10px 15px;
  font-size: 14px;
`;
export const TemplateTypeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 25px;
  justify-content: start;
`;
export const TemplateTypeCapsule = styled.div`
  display: flex;
  align-self: center;
  padding: 12px 20px;
  border-radius: 23px;
  background-color: ${(props) => (props.isActive ? `#4D7CFF` : `#FFF`)};
  color: ${(props) => (props.isActive ? `#FFFFFF` : `rgba(31, 41, 57, 0.8)`)};
  font-size: 14px;
  justify-content: center;
  cursor: pointer;
`;
export const TemplateCardHoverContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  grid-template-columns: 150px;
  grid-gap: 12px;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: none;
  z-index: 2;
`;
export const TemplateImageCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 75%;
  justify-content: center;
  align-items: center;
  padding: 28px 30px 26px 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(121, 136, 184, 0.16);
  background-color: white;
  background-image: url(${backgroundLayout});
  background-size: contain;
  cursor: pointer;
  &:hover ${TemplateCardHoverContainer} {
    display: grid;
  }
`;
export const PremiumButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 13px 26px rgba(60, 76, 119, 0.2);
`;
export const ContainerAnim = keyframes`
  from {
    opacity: 0.7;
    transform: translate(0px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -20px);
  }
`;
export const PreviewContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: grid;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `center`};
  align-content:  ${(props) =>
    props.alignContent ? props.alignContent : `center`};;
`;
export const PreviewPopup = styled.div`
  position: relative;
  display: grid;
  min-width: ${(props) => (props.minWidth ? props.minWidth : `670px`)};
  margin-top: 20px;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  animation: ${ContainerAnim} 0.8s ease forwards;
`;
export const CrossIcon = styled.div`
  position: absolute;
  top: ${props => props.position === "start" ? `initial` : props.position === "end" ? "-50px" : `-37px`};
  right: ${props => props.position ? `20px` : `-45px`};
  bottom: ${props => props.position === "start" && `-50px`};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
`;
export const WhiteCross = styled.div`
  position: absolute;
  top: 28%;
  right: 25px;
  width: 11px;
  height: 11px;
  cursor: pointer;
`;
export const PremiumBar = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  background-color: #1f2939;
`;
