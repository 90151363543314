import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_DISCOUNT_COUPONS,
  GET_DISCOUNT_COUPONS_SUCCESS,
  GET_DISCOUNT_COUPONS_FAILURE,
} from "../constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleGetCountriesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    countriesList: [],
  };
};

export const handleGetCountriesRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    countriesList: data,
  };
};

export const handleGetCountriesRequestFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
  };
};

// GET DISCOUNT COUPONS

export const handleGetDiscountCouponsRequest = (state, action) => {
  return {
    ...state,
    isLoadingCoupon: true,
    discountCoupons: [],
  };
};

export const handleGetDiscountCouponsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoadingCoupon: false,
    discountCoupons: data,
  };
};

export const handleGetDiscountCouponsRequestFailure = (state, action) => {
  //   createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoadingCoupon: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_COUNTRIES]: handleGetCountriesRequest,
  [GET_COUNTRIES_SUCCESS]: handleGetCountriesRequestSuccess,
  [GET_COUNTRIES_FAILURE]: handleGetCountriesRequestFailure,

  [GET_DISCOUNT_COUPONS]: handleGetDiscountCouponsRequest,
  [GET_DISCOUNT_COUPONS_SUCCESS]: handleGetDiscountCouponsRequestSuccess,
  [GET_DISCOUNT_COUPONS_FAILURE]: handleGetDiscountCouponsRequestFailure,
};

// default initial state
const initialState = {
  isLoading: false,
  countriesList: [],
  isLoadingCoupon: false,
  discountCoupons: [],
};

export default function countriesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
