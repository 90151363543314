import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BASE_DETAIL_VIEW } from "../constants/componentName";
import {
  updateRouteState,
  updateTemplateType,
  updateCampaignId,
} from "../actions/actionRoute";
import {
  getCampaignList,
  setCampaignStateToInitial,
  updateCampaign,
  deleteCampaign,
  getCDDateFilterData,
} from "../actions/actionCampaign";
import { CAMPAIGN_LIST } from "../constants/componentName";
import {
  CampaignListContainer,
  Card,
  LabelNumber,
  LabelNameCard,
  Chartdiv,
  Button,
  MainDiv,
  Labeldate,
} from "../styledComponent/campaignDetails";
import DeletePopup from "./DeletePopup";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { ToggleButton, ToggleButtonRound } from "../styledComponent/step2";
import templateView from "../assets/template_images/subscription/hover/subscribe-template-1.png";
import web from "../assets/icons/web.svg";
import mobile from "../assets/icons/mobile.svg";
import Chart from "react-apexcharts";
import DateRangePicker from "../commonComponents/dateRange/ReactDateRangePicker";
import "rsuite/styles/index.less";
import BackButtonComponent from "../commonComponents/BackButton/BackButtonComponent";
import { CommonText, CommonItemGrid } from "../styledComponent/common";
import { CampaignRow } from "../styledComponent/campaignList";
import moment from "moment";
import { typeModifyForRoute } from "../utils/typeModify";

const CampaignDetails = ({
  isLoading,
  isDeleteLoading,
  route,
  template_type,
  campaign_id,
  campaignList,
  filteredData,
  handleUpdateRouteState,
  updateTemplateType,
  updateCampaignId,
  deletedCampaignCallback,
  getCampaignList,
  getCDDateFilterData,
  setCampaignStateToInitial,
  updateCampaign,
  deleteCampaign,
  callback,
}) => {
  let dumy = {
    series: [
      {
        name: "north",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 250,
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },

      title: {
        text: "Impressions",
        align: "left",
        style: {
          fontSize: "14px",
          textAlign: "left",
          font: "normal normal medium 14px/21px Poppins",
          color: "#1F2939",
          opacity: 1,
        },
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 4,
        floating: false,

        labels: {
          style: {
            colors: "#8e8da4",
          },
          offsetY: -7,
          offsetX: 0,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 0.5,
      },
      tooltip: {
        x: {
          format: "yyyy",
        },
        fixed: {
          enabled: false,
          position: "topRight",
        },
      },
      grid: {
        yaxis: {
          lines: {
            offsetX: -30,
          },
        },
        padding: {
          left: 20,
        },
      },
    },
  };
  const [state, setState] = useState(dumy); //its sets dummy data for the graph
  const [dataState, setdataState] = useState(
    campaignList && campaignList.results && campaignList.results[0]
      ? campaignList.results[0]
      : null
  );
  const [deleteInfoState, setDeleteInfoState] = useState(null);
  const [startDate, setstartDate] = useState(moment().add(-6, "days"));
  const [endDate, setendDate] = useState(moment());
  const [isUpdated, setIsUpdated] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    for (let i = 0; i < campaignList.results.length; i++) {
      if (campaignList.results[i].id === campaign_id) {
        setdataState(campaignList.results[i]);
        break;
      }
    }
    callDateFilter();
    setTimeout(() => {
      callDateFilter();
    }, 1000);
  }, []);

  const callDateFilter = () => {
    let payload = {
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
      uid: campaign_id,
      type: template_type,
    };
    // payload.fromDate = startDate.format("YYYY-MM-DD");
    // payload.toDate = endDate.format("YYYY-MM-DD");
    getCDDateFilterData(payload);
    // console.log("filter Responce CD:",filteredData);
    let graphList = [];
    if (filteredData && filteredData.graph && filteredData.graph.length > 0) {
      filteredData.graph.map((element, index) => {
        graphList.push({
          x: moment(element.date).format("MM / DD / YYYY"),
          y: element.impression,
        });
      });

      // console.log(graphList);
    }
    dumy.series[0].data = graphList;
    setState(dumy);
  };
  const onChangeRoute = (route) => {
    async function sequence() {
      try {
        await updateCampaignId({ data: null });
        //await UpdateToInitialState();
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: route });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };
  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };
  const confirmDelete = () => {
    if (deleteInfoState.id !== "") {
      let modifiedType = typeModifyForRoute(deleteInfoState.type);
      deleteCampaign({
        template_type: modifiedType,
        campaign_id: deleteInfoState.id,
      });
      setTimeout(() => {
        setDeleteInfoState(null);
        onChangeRoute(CAMPAIGN_LIST);
      }, 1000);
    }
  };

  const onEditClick = (id, type) => {
    async function sequence() {
      try {
        await updateTemplateType({ data: type });
        await updateCampaignId({ data: id });
        await setCampaignStateToInitial();
        setTimeout(() => {
          handleUpdateRouteState({ data: BASE_DETAIL_VIEW });
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
    sequence();
  };
  const changeStatus = async (id, type, status) => {
    const payload = {
      campaign_id: id,
      template_type: typeModifyForRoute(type),
      status: status === `active` ? `inactive` : `active`,
    };
    try {
      await updateCampaign(payload);
      setIsUpdated(true);
      let obj = { ...dataState };

      obj.status = obj.status === `active` ? `inactive` : `active`;
      setdataState(obj);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleStatus = (value) => {};
  const changeDate = (val) => {
    setstartDate(val.startDate);
    setendDate(val.endDate);
  };
  const selectLastWeek = () => {
    setstartDate(moment().add(-6, "days"));
    setendDate(moment());
  };
  const selectLastMonth = () => {
    setstartDate(moment().add(-1, "months"));
    setendDate(moment());
  };
  return (
    <div>
      {popupOpen && (
        <DeletePopup
          type="campaign"
          isOpen={popupOpen}
          toggle={popupToggle}
          isDeleteLoading={isDeleteLoading}
          onDelete={confirmDelete}
        />
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MainDiv
            colum={"auto 1fr"}
            gap={"2%"}
            responsiveValue="900px"
            responsive="100%"
          >
            <div>
              {" "}
              <BackButtonComponent
                subTitle={dataState.campaign_name + " Statistics"}
                font="24px"
                onClick={() => onChangeRoute(CAMPAIGN_LIST)}
              />
            </div>

            <MainDiv
              colum={"auto auto auto"}
              gap={"2%"}
              justifyContent={"end"}
              marginB={"20px 0px"}
              responsiveValue="860px"
              responsive="100%"
            >
              <Labeldate>Select Date Range</Labeldate>
              <DateRangePicker
                style={{ width: "125%" }}
                startDate={startDate ? startDate : null}
                startDateId="custom-date-range-start-date"
                endDate={endDate ? endDate : null}
                endDateId="custom-date-range-start-date"
                onDatesChange={changeDate}
                dateDisplayFormat="DD/MM/YYYY"
                //  onDateSelect={props.onDateSelect}
                selectLastWeek={selectLastWeek}
                selectLastMonth={selectLastMonth}
                dateApply={callDateFilter}
              />
              <Button
                fColor={"#1F2939"}
                hColor={"#4D7CFF"}
                font={"normal normal medium 10px/21px Poppins"}
                onClick={() => onEditClick(campaign_id, template_type)}
              >
                <label>Edit Settings</label>
              </Button>
              {/* <Button
                background={"#4D7CFF"}
                fColor={"#FFFFFF"}
                font={"normal normal medium 10px/21px Poppins"}
              >
                <label>Export</label>
              </Button> */}
            </MainDiv>
          </MainDiv>
          <MainDiv
            colum={"83% 15%"}
            gap={"2%"}
            responsiveValue="860px"
            responsive="100%"
          >
            <MainDiv colum={"100px 78%"} gap={"2%"} marginB={"20px 0px"}>
              <div>
                <img src={templateView} alt="template-view" width="88px" />
              </div>
              <div>
                <MainDiv colum={"100%"} gap={"5px"}>
                  <MainDiv colum={"25% 78%"} gap={"2%"} marginB={"0px"}>
                    <CommonText
                      fontSize="18px"
                      color="#4D7CFF"
                      fontWeight="500"
                    >
                      {dataState.campaign_name}
                    </CommonText>
                    <ToggleButton
                      active={dataState.status === `active`}
                      style={{ marginLeft: "-10px" }}
                      onClick={() =>
                        changeStatus(
                          dataState.id,
                          dataState.type,
                          dataState.status
                        )
                      }
                    >
                      <ToggleButtonRound
                        active={dataState.status === `active`}
                      />
                    </ToggleButton>
                  </MainDiv>
                  <CommonText>
                    {" "}
                    Created on{" "}
                    {moment(dataState.created_at).format("MMMM D, YYYY")}{" "}
                  </CommonText>
                </MainDiv>
              </div>
            </MainDiv>
            <MainDiv
              justify={"end"}
              marginB={"20px 0px"}
              responsiveValue="860px"
              responsivePosition="start"
            >
              <Button
                hColor={"#FE5969"}
                fColor={"#FE5969"}
                font={"normal normal medium 10px/21px Poppins"}
                onClick={() => popupToggle()}
              >
                <label>Delete</label>
              </Button>
            </MainDiv>
          </MainDiv>
          <CampaignListContainer>
            <Card color={"#FAF8F3"}>
              <div>
                <LabelNumber>
                  {filteredData && filteredData.impression
                    ? filteredData.impression
                    : 0}
                </LabelNumber>
                <LabelNameCard>Impressions</LabelNameCard>
              </div>
            </Card>
            <Card color={"#E5F5FA"}>
              <div>
                <LabelNumber>
                  {filteredData && filteredData.conversion
                    ? filteredData.conversion
                    : 0}
                </LabelNumber>
                <LabelNameCard>Coversions</LabelNameCard>
              </div>
            </Card>
            <Card color={"#EEFCEF"}>
              <div>
                <LabelNumber>
                  {filteredData && filteredData.rate ? filteredData.rate : 0}%
                </LabelNumber>
                <LabelNameCard>Coversion Rate</LabelNameCard>
              </div>
            </Card>
            <Card color={"#F0F4FF"}>
              <div>
                <LabelNumber>
                  {filteredData && filteredData.web_device_count
                    ? filteredData.web_device_count
                    : 0}
                </LabelNumber>
                <LabelNameCard>Impression from web</LabelNameCard>
              </div>
              <img src={web} alt="web" width="90%" />
            </Card>
            <Card color={"#FAEFE5"}>
              <div>
                <LabelNumber>
                  {filteredData && filteredData.mobile_device_count
                    ? filteredData.mobile_device_count
                    : 0}
                </LabelNumber>
                <LabelNameCard>Impression from mobile</LabelNameCard>
              </div>
              <img src={mobile} alt="web" width="90%" />
            </Card>
          </CampaignListContainer>

          <Chartdiv>
            <div>
              {/* <Chart
                options={state.options}
                series={state.series}
                type="area"
                height={250}
              /> */}
            </div>
          </Chartdiv>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaign.isLoading,
  isDeleteLoading: state.campaign.isDeleteLoading,
  route: state.route.link,
  template_type: state.route.template_type,
  campaign_id: state.route.campaign_id,
  campaignList: state.campaign.campaignList,
  filteredData: state.campaign.filteredData,
  deletedCampaignCallback: state.campaign.deletedCampaignCallback,
  callback: state.campaign.callback,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateTemplateType: (params) => dispatch(updateTemplateType(params)),
  updateCampaignId: (params) => dispatch(updateCampaignId(params)),
  getCampaignList: (params) => dispatch(getCampaignList(params)),
  updateCampaign: (params) => dispatch(updateCampaign(params)),
  deleteCampaign: (params) => dispatch(deleteCampaign(params)),
  getCDDateFilterData: (params) => dispatch(getCDDateFilterData(params)),
  setCampaignStateToInitial: (params) =>
    dispatch(setCampaignStateToInitial(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
