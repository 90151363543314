import React, { useState, useEffect } from "react";
import { StyledText } from "../../styledComponent/editor";

const Text = ({ element }) => {

  return (
    <React.Fragment key={element.id}>
            <StyledText style={element.style}>{element.text}</StyledText>
    </React.Fragment>
  );
};

export default Text;
