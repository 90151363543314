import React, { useState, useEffect } from "react";
import { ElementContainer ,LinkButton } from "../../styledComponent/editor";

const LinkComp = ({ element }) => {
  useEffect(() =>{
    // debugger;
  var link = element;
  },[])
  return (
    <React.Fragment key={element.id}>
      <ElementContainer
        style={
          element.justifyContent &&
          element.justifyContent !== "" && {
            justifyContent: element.justifyContent,
          }
        }
      >
        <LinkButton href={element.url} target="_blank" rel="noreferrer" hover={element.hover} style={{ ...element.style }}>
          {element.text}
        </LinkButton>
      </ElementContainer>
    </React.Fragment>
  );
};

export default LinkComp;
