import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers/indexReducer";

import sagas from "./middlewares/sagas";
import { persistStore } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    reducer,
  compose(applyMiddleware(sagaMiddleware)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
